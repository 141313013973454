import { Col, Row, Spin, Typography } from 'antd';
import React from 'react';

import { useLastCommunicationEmail } from '../../hooks/useCustomerCommunication';
import { CustomCard } from '../CustomCard';
import { EmptyState } from '../EmptyState';
import { ErrorState } from '../ErrorState';
import { EmailStatus } from './EmailStatus';

interface CommunicationLastEmailProps {
  customerId: string;
}

const { Text } = Typography;

export const CommunicationLastEmail: React.FC<CommunicationLastEmailProps> = ({
  customerId,
}) => {
  const { lastEmail, isError, isLoading, refetchOnError } =
    useLastCommunicationEmail(customerId);

  if (isLoading) {
    return (
      <Col span={12} flex={1}>
        <CustomCard title='Último email recebido'>
          <Row
            justify='center'
            align='middle'
            style={{ padding: '12px 0', minHeight: '140px' }}
          >
            <Spin />
          </Row>
        </CustomCard>
      </Col>
    );
  }

  if (isError) {
    return (
      <Col span={12} flex={1}>
        <CustomCard style={{ height: '100%' }}>
          <ErrorState
            title=''
            subtitle='Infelizmente tivemos um erro ao carregar o ultimo email enviado ao cliente'
            buttonLabel='Recarregar'
            onClickButton={() => refetchOnError()}
          />
        </CustomCard>
      </Col>
    );
  }

  return (
    <Col span={12} flex={1}>
      <CustomCard title='Último email recebido' style={{ minHeight: '216px' }}>
        {lastEmail ? (
          <Row align={'middle'} justify={'center'}>
            <Col flex={1}>
              <Row justify={'center'} style={{ marginBottom: '16px' }}>
                <Text type='secondary'>{lastEmail.subject}</Text>
              </Row>
              <EmailStatus currentStatus={lastEmail.status} />
            </Col>
          </Row>
        ) : (
          <EmptyState
            style={{ marginTop: '18px' }}
            title='Sem emails recebidos'
            subtitle='Parece que ainda não há nenhum email recebido para este cliente.'
          />
        )}
      </CustomCard>
    </Col>
  );
};
