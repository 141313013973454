import { Col, Radio, Row, Space, Typography } from 'antd';
import { FC } from 'react';
import { useFormContext } from 'react-hook-form';

import { DatePicker, TimePicker } from 'src/app/components/antd';
import { PublishStepForm } from 'src/features/programs/schemas/publishStepSchema';

import { ProgramFormItem } from '../../ProgramFormItem';
import { ProgramSection } from '../../ui/ProgramSection';
import { ProgramSectionGroup } from '../../ui/ProgramSectionGroup';

const { Text } = Typography;

interface ProgramPublishContentProps {
  isPublished: boolean;
}

export const ProgramPublishContent: FC<ProgramPublishContentProps> = ({
  isPublished,
}) => {
  const { control } = useFormContext<PublishStepForm>();

  return (
    <ProgramSection title='Publicação'>
      <ProgramSectionGroup isHeader>
        Escolha quando deseja publicar
      </ProgramSectionGroup>

      <ProgramSectionGroup>
        <ProgramFormItem
          name='publish'
          control={control}
          renderItem={({ field: { value, ...field } }) => (
            <Space direction='vertical' size={16} style={{ width: '100%' }}>
              <Radio
                {...field}
                checked={value === 'scheduled'}
                value={'scheduled'}
                disabled={isPublished}
              >
                Agendar para
              </Radio>

              <Row style={{ width: '100%' }} gutter={[32, 0]}>
                <Col span={12}>
                  <ProgramFormItem
                    control={control}
                    name='date'
                    label='Data'
                    renderItem={({ field }) => (
                      <DatePicker
                        {...field}
                        format='DD/MM/YYYY'
                        placeholder='Selecione uma data'
                        disabled={isPublished}
                      />
                    )}
                  />
                </Col>
                <Col span={12}>
                  <ProgramFormItem
                    control={control}
                    name='time'
                    label='Hora'
                    renderItem={({ field }) => (
                      <TimePicker
                        {...field}
                        showSecond={false}
                        placeholder='Selecione um horário'
                        disabled={isPublished}
                      />
                    )}
                  />
                </Col>
              </Row>

              <Radio
                {...field}
                checked={value === 'now'}
                value={'now'}
                disabled={isPublished}
              >
                Publicar agora
              </Radio>
            </Space>
          )}
        />

        {isPublished && (
          <Text type='danger' style={{ marginTop: 16 }}>
            Este programa já foi publicado
          </Text>
        )}
      </ProgramSectionGroup>
    </ProgramSection>
  );
};
