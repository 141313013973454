import {
  KeyOutlined,
  PlusCircleOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import { Button, Card, Col, Divider, Row, Space, Typography } from 'antd';
import { FC } from 'react';
import styled from 'styled-components';

import { useGenerateLinkToResetPassword } from '../../hooks/useGenerateLinkToResetPassword';
import { useGetLinkToResetPassword } from '../../hooks/useGetLinkToResetPassword';
import { MenuContentType } from '../../types/customer';
import { GenerateLinkTag } from './components/GenerateLinkTag';

const { Text, Title } = Typography;

export const GenerateLink: FC<MenuContentType> = ({ customerId }) => {
  const { data } = useGetLinkToResetPassword(customerId);
  const { generateLink } = useGenerateLinkToResetPassword();

  const handleGenerateLink = () => {
    generateLink({ customerId });
  };

  if (!data) {
    return (
      <Row
        justify='center'
        align='middle'
        style={{
          width: '100%',
          height: '100%',
        }}
      >
        <Col style={{ maxWidth: '486px' }}>
          <Space direction='vertical' align='center' size='middle'>
            <CustomTitle level={5}>Redefinição de senha</CustomTitle>
            <CenterText type='secondary'>
              Compartilhe o link de redefinição de senha. Assim que o link é
              gerado, um e-mail automático também enviado ao usuário.
            </CenterText>
            <Button type='primary' onClick={() => handleGenerateLink()}>
              <PlusOutlined />
              Novo link
            </Button>
          </Space>
        </Col>
      </Row>
    );
  }

  return (
    <Row justify='center' align='middle'>
      <Col style={{ maxWidth: '486px' }}>
        <Title level={5}>Gerar link</Title>
        <Text type='secondary'>
          Compartilhe o link de redefinição de senha. Assim que o link é gerado,
          um e-mail automático também enviado ao usuário.
        </Text>
        <Divider style={{ margin: '16px 0' }} />
        <CustomCard style={{ width: '486px' }}>
          <Row align='middle' justify='space-between'>
            <Row align='middle'>
              <Col
                style={{
                  border: '1px solid  #424242',
                  padding: '14px',
                  borderRadius: '8px',
                }}
              >
                <KeyOutlined style={{ fontSize: '20px', color: ' #424242' }} />
              </Col>
              <Col style={{ marginLeft: '16px' }}>
                <Text>Redefinir senha</Text>
              </Col>
            </Row>
            <GenerateLinkTag status={data.status} url={data.url} />
          </Row>
        </CustomCard>

        <CustomButtom
          type='primary'
          style={{ marginTop: '16px', borderRadius: '6px', width: '100%' }}
          onClick={() => handleGenerateLink()}
        >
          <PlusCircleOutlined />
          Novo link
        </CustomButtom>
      </Col>
    </Row>
  );
};

const CustomCard = styled(Card)`
  .ant-card-body {
    padding: 16px;
  }
`;

const CenterText = styled(Text)`
  display: flex;
  text-align: center;
`;

const CustomTitle = styled(Title)`
  line-height: 1 !important;
  margin-bottom: 0 !important;
`;

const CustomButtom = styled(Button)`
  margin-top: '16px';
  border-radius: '6px';
  width: '100%';
`;
