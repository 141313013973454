import { useQuery } from 'react-query';

import { programBonusApi } from '../api/programBonusApi';
import { useProgram } from './useProgram';

export const useStructureStepData = (programId: number | undefined) => {
  const { program, isLoadingProgram } = useProgram(programId);

  const { data: allBonuses, isLoading: isLoadingAllBonuses } = useQuery(
    ['all-program-bonuses', programId],
    () => programBonusApi.fetchAllBonuses(programId as number),
    { enabled: !!programId, refetchOnWindowFocus: false }
  );

  return {
    program,
    allBonuses,
    isLoading: isLoadingProgram || isLoadingAllBonuses,
  };
};
