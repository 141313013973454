import { MutateBonusParam } from '../types/programBonuses';

export const getBonusFromForm = ({
  bonusId,
  ...rest
}: {
  bonusId: number;
  name: string;
  bonusProgramId: number | null;
  [key: string]: any;
}): MutateBonusParam => ({
  ...rest,
  id: bonusId,
});
