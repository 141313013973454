import { StructureStepForm } from '../schemas/structureStepSchema';

export const findDuplicatedModules = (
  modules: StructureStepForm['modules'] = []
) => {
  return modules
    .map(module => module.name.trim())
    .sort((a, b) => a.localeCompare(b))
    .filter((item, i, array) => item === array[i + 1]);
};

export const findDuplicatedContentNames = (
  modules: StructureStepForm['modules'] = []
) => {
  const sortedContents = modules.flatMap(module =>
    module.contents
      ?.sort((a, b) => a.name.trim().localeCompare(b.name.trim()))
      ?.map(content => ({
        contentName: content.name.trim(),
        moduleName: module.name.trim(),
      }))
  );

  return sortedContents.filter((item, i, array) => {
    const nextItem = array[i + 1];
    return (
      item?.contentName === nextItem?.contentName &&
      item?.moduleName === nextItem?.moduleName
    );
  });
};
