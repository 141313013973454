import { CheckOutlined } from '@ant-design/icons';
import { Steps } from 'antd';
import { FC, useMemo } from 'react';
import styled from 'styled-components';

import { usePlaylist } from '../hooks/usePlaylist';
import { useProgram } from '../hooks/useProgram';
import { checkCompletedSteps } from '../utils/checkCompletedSteps';
import { showInfoModal } from '../utils/modalHelpers';

interface ProgramStepperProps {
  programId?: number;
  currentStep: number;
  onChangeStep: (value: number) => void;
}

export const ProgramStepper: FC<ProgramStepperProps> = ({
  programId,
  currentStep,
  onChangeStep,
}) => {
  const { program } = useProgram(programId);
  const { playlist } = usePlaylist(programId);

  const handleChange = (value: number) => {
    if (value > 0 && !programId) {
      return showInfoModal({
        title: 'Verifique alguns dados',
        content:
          'Preencha inicialmente as INFORMAÇÕES e salve para poder preencher as outras partes do programa.',
      });
    }

    const {
      isInformationCompleted,
      isStructureCompleted,
      isDesignCompleted,
      isOrderCompleted,
    } = checkCompletedSteps(program, playlist);

    const canAccessPublishStep =
      isInformationCompleted &&
      isStructureCompleted &&
      isDesignCompleted &&
      isOrderCompleted;

    if (value === 4 && !canAccessPublishStep) {
      return showInfoModal({
        title: 'Atenção',
        content: 'Conclua todas as etapas para publicar o programa.',
      });
    }

    onChangeStep(value);
  };

  const stepsCompleted: Record<number, boolean> = useMemo(() => {
    const {
      isInformationCompleted,
      isStructureCompleted,
      isDesignCompleted,
      isOrderCompleted,
      isPublicationCompleted,
    } = checkCompletedSteps(program, playlist);

    return {
      0: isInformationCompleted,
      1: isStructureCompleted,
      2: isDesignCompleted,
      3: isOrderCompleted,
      4: isPublicationCompleted,
    };
  }, [program, playlist]);

  return (
    <div style={{ marginBottom: 32, marginInline: -16 }}>
      <Steps
        current={currentStep}
        onChange={handleChange}
        labelPlacement='vertical'
        size='small'
        items={steps.map((title, index) => {
          const isCompleted = stepsCompleted[index];
          return {
            title,
            icon: (
              <IconWrapper isCompleted={isCompleted}>
                {isCompleted && (
                  <CheckOutlined style={{ color: 'white', fontSize: 14 }} />
                )}
              </IconWrapper>
            ),
          };
        })}
      />
    </div>
  );
};

const steps = ['Informações', 'Estrutura', 'Design', 'Ordenação', 'Publicação'];

const IconWrapper = styled.div<{ isCompleted: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: ${({ isCompleted }) =>
    isCompleted ? '#49AA19' : '#303030'};
  color: white;
  font-size: 14px;
`;
