import { useAssetsOrdering } from '@hooks';
import { Assets } from '@models';
import { AssetsOrderList } from '@organisms';
import { Modal } from 'antd';
import React, { useEffect, useState } from 'react';

import OpenNotification from 'src/app/components/antd/OpenNotification';

export interface ModalAssetsProps {
  title: string;
  showModal: boolean;
  onCloseModal?: () => void;
  onFilter?: (handle: { field: string; value?: string }[]) => void;
}

export const ModalOrderList: React.FC<ModalAssetsProps> = ({
  title,
  showModal,
  onCloseModal,
}) => {
  const {
    getAssetsOrdering,
    postNewOrdering,
    clearOrderingAssetsList,
    orderingAssetsList,
  } = useAssetsOrdering();

  const [choosedAssetType, setChoosedAssetType] =
    useState<Assets.AssetContext>();
  const [choosedAssetSegment, setChoosedAssetSegment] = useState<number>();
  const [choosedDeviceType, setChoosedDeviceType] =
    useState<Assets.DeviceTypeEnum>();

  const [bannerNewOrder, setBannerNewOrder] = useState<Assets.IAsset[]>();

  useEffect(() => {
    if (choosedAssetType && choosedDeviceType && choosedAssetSegment) {
      setBannerNewOrder(undefined);
      getAssetsOrdering({
        filter: {
          context: choosedAssetType,
          deviceName: choosedDeviceType,
          segmentId: choosedAssetSegment,
        },
      });
    }
  }, [choosedAssetType, choosedAssetSegment, choosedDeviceType]);

  const onCancelModal = () => {
    setChoosedAssetType(undefined);
    setChoosedAssetSegment(undefined);
    setChoosedDeviceType(undefined);
    setBannerNewOrder(undefined);
    clearOrderingAssetsList();
    onCloseModal?.();
  };

  const onConfirmModal = () => {
    if (!bannerNewOrder) {
      OpenNotification(true, 'Não existem modificações a serem salvas');
      return;
    }

    if (!choosedAssetSegment) {
      OpenNotification(true, 'Escolha o segmento para salvar a ordenação.');
      return;
    }

    if (!choosedDeviceType) {
      OpenNotification(
        true,
        'Escolha o tipo de dispositivo para salvar a ordenação.'
      );
      return;
    }

    try {
      postNewOrdering(bannerNewOrder, choosedAssetSegment, choosedDeviceType);
      setBannerNewOrder(undefined);
    } catch (err) {
      OpenNotification(true, 'Erro ao salvar nova ordem');
    }
  };

  return (
    <Modal
      style={{ top: '20px' }}
      width={640}
      title={title}
      open={showModal}
      okText={'Confirmar'}
      onCancel={onCancelModal}
      onOk={onConfirmModal}
      okButtonProps={{ disabled: !bannerNewOrder }}
      destroyOnClose
    >
      <AssetsOrderList
        filteredAssetsList={orderingAssetsList?.rows || []}
        choosedAssetSegment={choosedAssetSegment}
        setChoosedAssetType={setChoosedAssetType}
        setChoosedAssetSegment={setChoosedAssetSegment}
        setChoosedDeviceType={setChoosedDeviceType}
        onChangeOrder={value => setBannerNewOrder(value)}
      />
    </Modal>
  );
};
export default ModalOrderList;
