import { FC, ReactNode, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

import { useBreadcrumbs } from 'src/features/layout/hooks/useBreadcrumbs';

import { ProgramStepper } from '../components/ProgramStepper';
import { DesignStep } from '../components/steps/DesignStep/DesignStep';
import { InformationStep } from '../components/steps/InformationStep/InformationStep';
import { OrderStep } from '../components/steps/OrderStep/OrderStep';
import { PublishStep } from '../components/steps/PublishStep/PublishStep';
import { StructureStep } from '../components/steps/StructureStep/StructureStep';

export const CreateOrUpdateProgramsScreen: FC = () => {
  const params = useParams<{ id?: string }>();
  const programId = params.id ? Number(params.id) : undefined;

  useBreadcrumbs([
    {
      label: 'Dashboard',
      route: '/dashboard',
    },
    {
      label: 'Programas',
      route: '/programs',
    },
    {
      label: programId ? 'Editar' : 'Criar',
    },
  ]);

  const [step, setStep] = useState(0);

  const currentStep = useMemo(() => {
    const steps: Record<number, ReactNode> = {
      0: <InformationStep programId={programId} />,
      1: <StructureStep programId={programId} />,
      2: <DesignStep programId={programId} />,
      3: <OrderStep programId={programId} />,
      4: <PublishStep programId={programId} />,
    };
    return steps[step] || null;
  }, [step, programId]);

  return (
    <>
      <ProgramStepper
        programId={programId}
        currentStep={step}
        onChangeStep={setStep}
      />

      {currentStep}
    </>
  );
};
