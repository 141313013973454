import { useCallback } from 'react';
import { useMutation, useQueryClient } from 'react-query';

import { customerApi } from '../api/customerApi';
import { ToastMessage } from '../components/ToastMessage';
import { CustomerUpdateParams } from '../types/customer';

export const useCustomerProfileForm = () => {
  const queryClient = useQueryClient();

  const onSuccess = useCallback(() => {
    queryClient.invalidateQueries('customer');
    queryClient.invalidateQueries('users');
    ToastMessage({
      content: `Dado atualizado com sucesso`,
      type: 'success',
    });
  }, [queryClient]);

  const { mutateAsync: updateCustomerData } = useMutation(
    customerApi.updateCustomer,
    { onSuccess }
  );

  const handleUpdateCustomer = async (
    params: CustomerUpdateParams,
    changedField: string
  ) => {
    try {
      ToastMessage({
        content: `Atualizando ${changedField.toLocaleLowerCase()} do cliente`,
        type: 'loading',
      });
      updateCustomerData(params);
    } catch (error) {
      ToastMessage({
        content: `Houve um erro ao atualizar o ${changedField.toLocaleLowerCase()}`,
        type: 'error',
      });
    }
  };

  return {
    handleUpdateCustomer,
  };
};
