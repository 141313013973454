import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Button, Col, Divider, Input, Modal, Row, Typography } from 'antd';
import { FC } from 'react';
import { Controller, useForm } from 'react-hook-form';

import { useChangePassword } from '../../hooks/useChangePassword';
import { MenuContentType } from '../../types/customer';
import { ToastMessage } from '../ToastMessage';

const { Title, Text } = Typography;
const { confirm } = Modal;

interface FormValues {
  password: string;
  reason: string;
}

export const ChangePassword: FC<MenuContentType> = ({ customerId }) => {
  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<FormValues>();
  const { changePassword, isLoading } = useChangePassword();

  const handleConfirm = async (data: FormValues) => {
    try {
      await changePassword({
        customerId,
        password: data.password,
        reason: data.reason,
      });
      ToastMessage({
        content: 'Senha alterada com sucesso.',
        type: 'success',
      });
      reset();
    } catch {
      ToastMessage({
        content: 'Falha ao alterar a senha. Tente novamente!',
        type: 'error',
      });
    }
  };

  const showConfirm = (data: FormValues) => {
    confirm({
      title: 'Alterar senha do usuário',
      icon: <ExclamationCircleOutlined />,
      centered: true,
      okText: 'Alterar senha',
      width: '50%',
      content: (
        <Text type='secondary' style={{ marginTop: '16px' }}>
          Você está prestes a alterar a senha deste usuário. Tem certeza que
          deseja prosseguir com a alteração?
        </Text>
      ),
      onOk() {
        handleConfirm(data);
      },
      onCancel() {
        reset();
      },
    });
  };

  const onSubmit = (data: FormValues) => {
    showConfirm(data);
  };

  return (
    <Row justify='center' align='middle'>
      <Col>
        <Title level={5}>Alterar Senha</Title>
        <Text type='secondary'>Redefinir a senha do usuário.</Text>
        <Divider style={{ margin: '16px 0' }} />

        <form onSubmit={handleSubmit(onSubmit)}>
          <div style={{ marginBottom: '16px' }}>
            <Text style={{ color: '#DC4446', marginRight: '4px' }}>*</Text>
            <Text>Nova senha do usuário</Text>
            <Controller
              name='password'
              control={control}
              rules={{ required: 'A nova senha é obrigatória' }}
              render={({ field }) => (
                <Input.Password
                  {...field}
                  placeholder='Nova senha'
                  status={errors.password && 'error'}
                  style={{ marginTop: '8px' }}
                />
              )}
            />
            {errors.password && (
              <Text type='danger'>{errors.password.message}</Text>
            )}
          </div>

          <div style={{ marginBottom: '16px' }}>
            <Row style={{ marginBottom: '8px' }}>
              <Text style={{ color: '#DC4446', marginRight: '4px' }}>*</Text>
              <Text>Motivo</Text>
            </Row>
            <Controller
              name='reason'
              control={control}
              rules={{ required: 'O motivo é obrigatório' }}
              render={({ field }) => (
                <Input.TextArea
                  {...field}
                  placeholder='Escreva o motivo da alteração'
                  rows={4}
                  status={errors.reason && 'error'}
                />
              )}
            />
            {errors.reason && (
              <Text type='danger'>{errors.reason.message}</Text>
            )}
          </div>

          <Button
            type='primary'
            htmlType='submit'
            style={{ width: '100%' }}
            loading={isLoading}
          >
            Alterar Senha
          </Button>
        </form>
      </Col>
    </Row>
  );
};
