import { slices } from '@core/redux';
import { useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';

import { programBonusApi } from '../api/programBonusApi';
import { ProgramBonusesForm } from '../schemas/programBonusesSchema';
import { MutateBonusParam } from '../types/programBonuses';
import {
  filterByLocalFormId,
  filterByRemoteFormId,
} from '../utils/formHelpers';
import { getBonusFromForm } from '../utils/getBonusFromForm';
import { showInfoModal } from '../utils/modalHelpers';

export const useMutateProgramBonuses = (programId: number | undefined) => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const updateBonuses = async (
    bonusesToUpdate: MutateBonusParam[],
    initialBonuses: MutateBonusParam[]
  ) => {
    await Promise.all(
      bonusesToUpdate.map(async bonus => {
        const isPdf = bonus.metadata?.type === 'pdf';
        const initialBonus = initialBonuses.find(item => item.id === bonus.id);
        if (!initialBonus) return;

        if (isPdf) {
          const haveChangedName = bonus.name !== initialBonus.name;
          const haveChangedFile =
            bonus.metadata?.url !== initialBonus.metadata?.url;

          if (haveChangedName) {
            await programBonusApi.patchBonusName(bonus.id, bonus.name);
          }

          if (haveChangedFile && bonus.metadata?.file) {
            const formData = new FormData();
            formData.append('file', bonus.metadata.file);

            await programBonusApi.uploadBonusPdfFile(
              bonus.id,
              programId as number,
              formData
            );
          }
        } else {
          const haveChangeInActive = bonus.active !== initialBonus.active;
          if (!haveChangeInActive) return;

          if (bonus.active) {
            const now = new Date();
            await programBonusApi.createBonus({
              bonusId: bonus.id,
              programId: programId as number,
              createdAt: now,
              updatedAt: now,
            });
          } else {
            await programBonusApi.deleteBonus(bonus.bonusProgramId as number);
          }
        }
      })
    );
  };

  const createBonuses = async (bonusesToCreate: MutateBonusParam[]) => {
    await Promise.all(
      bonusesToCreate.map(async bonusPdf => {
        const createdBonus = await programBonusApi.createCustomBonus({
          name: bonusPdf.name,
          metadata: bonusPdf.metadata,
          programId: programId as number,
        });

        if (!bonusPdf.metadata?.file) return;

        const formData = new FormData();
        formData.append('file', bonusPdf.metadata.file);

        await programBonusApi.uploadBonusPdfFile(
          createdBonus.bonusId,
          createdBonus.programId,
          formData
        );
      })
    );
  };

  const deleteCustomPdfs = async (
    paramsBonuses: MutateBonusParam[],
    initialBonuses: MutateBonusParam[]
  ) => {
    const paramsPdfs = paramsBonuses.filter(
      bonus => bonus.metadata?.type === 'pdf'
    );

    const initialPdfs = initialBonuses.filter(
      bonus => bonus.metadata?.type === 'pdf'
    );

    await Promise.all(
      initialPdfs.map(async initialBonus => {
        const haveCurrentBonus = paramsPdfs.some(
          bonus => bonus.id === initialBonus.id
        );

        if (!haveCurrentBonus) {
          await programBonusApi.deleteBonus(
            initialBonus.bonusProgramId as number
          );
        }
      })
    );
  };

  const mutateProgramBonuses = async (
    params: ProgramBonusesForm,
    initialState: ProgramBonusesForm
  ) => {
    dispatch(slices.layout.increaseLoading());

    const initialBonuses = [
      ...(initialState.defaultBonuses || []).map(getBonusFromForm),
      ...(initialState.customBonuses || []).map(getBonusFromForm),
    ];
    const paramsBonuses = [
      ...(params.defaultBonuses || []).map(getBonusFromForm),
      ...(params.customBonuses || []).map(getBonusFromForm),
    ];

    const bonusesToUpdate = paramsBonuses.filter(filterByRemoteFormId);
    const bonusesToCreate = paramsBonuses.filter(filterByLocalFormId);

    try {
      await updateBonuses(bonusesToUpdate, initialBonuses);
      await createBonuses(bonusesToCreate);
      await deleteCustomPdfs(paramsBonuses, initialBonuses);

      showInfoModal({
        title: 'Sucesso',
        content: 'Dados do programa atualizados com sucesso!',
        afterClose: () => {
          queryClient.resetQueries(['default-program-bonuses', programId]);
          queryClient.resetQueries(['custom-program-bonuses', programId]);
        },
      });
    } catch (error) {
      showInfoModal({
        title: 'Tivemos um problema...',
        content:
          'Não foi possível atualizar os dados do programa, tente novamente.',
      });
    } finally {
      dispatch(slices.layout.decreaseLoading());
    }
  };

  return { mutateProgramBonuses };
};
