export const userTypes = {
  NEW_USER: 'new-user',
  ACTIVE_USER: 'active-user',
  INACTIVE_USER: 'inactive-user',
} as const;

export type ModalRedirectUserType = typeof userTypes[keyof typeof userTypes];

export interface ModalRedirect {
  id: number;
  referrerUrl: string;
  userType: ModalRedirectUserType;
  redirect: string;
  category: null;
  active: boolean | null;
  createdAt: string;
}
