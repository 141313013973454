import { slices } from '@core/redux';
import { yupResolver } from '@hookform/resolvers/yup';
import { Form, Space } from 'antd';
import { FC, useEffect, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';

import { GeneralError } from 'src/app/components/Error';
import { useCreateOrUpdateModulesAndContents } from 'src/features/programs/hooks/useCreateOrUpdateModulesAndContents';
import { useStructureStepData } from 'src/features/programs/hooks/useStructureStepData';
import {
  StructureStepForm,
  structureStepSchema,
} from 'src/features/programs/schemas/structureStepSchema';
import {
  findDuplicatedContentNames,
  findDuplicatedModules,
} from 'src/features/programs/utils/findDuplicated';
import { getProgramModulesFromForm } from 'src/features/programs/utils/getProgramModulesFromForm';
import { showInfoModal } from 'src/features/programs/utils/modalHelpers';

import { ProgramName } from '../../ProgramName';
import { ProgramStepFooter } from '../../ProgramStepFooter';
import { BonusCard } from './BonusCard';
import { ProgramBonuses } from './ProgramBonuses';
import { ProgramModules } from './ProgramModules';

interface StructureStepProps {
  programId?: number;
}

export const StructureStep: FC<StructureStepProps> = ({ programId }) => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const [isEditingBonuses, setIsEditingBonuses] = useState(false);

  const { program, allBonuses, isLoading } = useStructureStepData(programId);
  const { createOrUpdateModulesAndContents } =
    useCreateOrUpdateModulesAndContents(programId);

  const formValues = useMemo(
    (): StructureStepForm => ({
      name: program?.name,
      modules: program?.pages?.[0]?.modules?.map(module => ({
        moduleId: module.id,
        name: module.name,
        description: module.description,
        contents: module.contents
          .sort((a, b) => a.order - b.order)
          .map(content => ({
            contentId: content.id,
            name: content.name,
            theoPlayerId: content.theoPlayerId,
            order: content.order,
            status: content.status,
          })),
      })),
    }),
    [program]
  );

  const form = useForm<StructureStepForm>({
    resolver: yupResolver(structureStepSchema),
    values: formValues,
  });

  useEffect(() => {
    if (isLoading) {
      dispatch(slices.layout.increaseLoading());
    } else {
      dispatch(slices.layout.decreaseLoading());
    }
  }, [isLoading]);

  const onSubmit = (fields: StructureStepForm) => {
    const duplicatedModuleNames = findDuplicatedModules(fields?.modules);
    const duplicatedContentNames = findDuplicatedContentNames(fields?.modules);

    let description = '';

    if (duplicatedModuleNames.length > 0) {
      description = 'Alguns nomes de seções estão duplicados:';
      description += duplicatedModuleNames.map(item => ` '${item}'`).join(',');
      description += '. Corrija os para continuar.';
    } else if (duplicatedContentNames.length > 0) {
      description = 'Alguns nomes de aulas estão duplicados na mesma seção:';
      description += duplicatedContentNames
        .map(item => {
          return ` 'Seção: ${item?.moduleName}, Nome: ${item?.contentName}'`;
        })
        .join(',');
      description += '. Corrija os para continuar.';
    }

    if (description) {
      return showInfoModal({
        title: 'Verifique os dados',
        content: description,
      });
    }

    const payload = getProgramModulesFromForm(
      fields,
      program?.pages?.[0]?.id as number
    );

    createOrUpdateModulesAndContents(payload, formValues);
  };

  const resetForm = () => {
    queryClient.resetQueries(['program-contents', programId]);
  };

  if (!programId || program?.pages.length === 0) {
    return <GeneralError action={resetForm} />;
  }

  if (isEditingBonuses) {
    return (
      <ProgramBonuses
        programId={programId}
        goBack={() => setIsEditingBonuses(false)}
      />
    );
  }

  return (
    <FormProvider {...form}>
      <Form layout='vertical' onFinish={form.handleSubmit(onSubmit)}>
        <Space direction='vertical' size={24} style={{ width: '100%' }}>
          <ProgramName isDisabled />
          <ProgramModules />
          {!isLoading && (
            <BonusCard
              bonusesCount={allBonuses?.length ?? 0}
              openEditBonuses={() => setIsEditingBonuses(true)}
            />
          )}
        </Space>
        <ProgramStepFooter
          programId={programId}
          onCancelConfirmation={resetForm}
        />
      </Form>
    </FormProvider>
  );
};
