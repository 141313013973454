import { useCallback } from 'react';
import { useMutation, useQueryClient } from 'react-query';

import { customerApi } from '../api/customerApi';

interface ChangePasswordParams {
  password: string;
  customerId: string;
  reason: string;
}

export const useChangePassword = () => {
  const queryClient = useQueryClient();

  const onSuccess = useCallback(
    (_, variables: { customerId: string }) => {
      queryClient.invalidateQueries(['reset-password', variables.customerId]);
    },
    [queryClient]
  );

  const { mutateAsync: changePassword, isLoading } = useMutation(
    ({ password, customerId, reason }: ChangePasswordParams) =>
      customerApi.changeUserPassword(customerId, password, reason),
    { onSuccess }
  );

  return { changePassword, isLoading };
};
