import { Typography } from 'antd';
import { CSSProperties, FC, ReactNode } from 'react';
import styled from 'styled-components';

const { Title } = Typography;

interface ProgramSectionProps {
  title?: string;
  spacing?: CSSProperties['margin'];
  children?: ReactNode;
}

export const ProgramSection: FC<ProgramSectionProps> = ({
  title,
  spacing,
  children,
}) => {
  return (
    <div style={{ margin: spacing }}>
      {Boolean(title) && <Title level={4}>{title}</Title>}
      <Section>{children}</Section>
    </div>
  );
};

const Section = styled.div`
  background-color: #1d1d1d;
  border: 1px solid #303030;

  .ant-radio-group {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
  }

  .ant-checkbox-group {
    display: flex;
    flex-wrap: wrap;
    gap: 7px;
  }

  .ant-checkbox-wrapper {
    height: 32px;
    border: 1px solid #434343;
    cursor: pointer;
    transition: color 0.3s, background 0.3s, border-color 0.3s, box-shadow 0.3s;
    margin: 0;

    &:hover {
      color: #177ddc;
    }

    .ant-checkbox {
      display: none;
    }

    span {
      padding: 0 15px;
      line-height: 30px;
    }
  }

  .ant-checkbox-wrapper-checked:not(.ant-checkbox-wrapper-disabled) {
    color: #177ddc;
    border-color: #177ddc;

    &:hover {
      color: #165996;
      border-color: #165996;
    }
  }

  .ant-select,
  .ant-input-number,
  .ant-input,
  .ant-radio-group,
  .ant-checkbox-group,
  .ant-picker {
    width: 100%;
  }

  .ant-select,
  .ant-input-number,
  .ant-input,
  .ant-radio-button-wrapper,
  .ant-checkbox-wrapper,
  .ant-picker {
    background-color: #141414;
  }

  /* Fix for Google Chrome Autofill colors */
  .ant-input-number,
  .ant-input {
    transition: color calc(infinity * 1s) step-end,
      background-color calc(infinity * 1s) step-end;
  }
`;
