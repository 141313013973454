import { useDebounce } from '@hooks';
import { message } from 'antd';
import { useEffect, useRef, useState } from 'react';

import { linkShortnerApi } from '../api/linkShortnerApi';
import { ShortenedLink } from '../typings/linkShortner';

export const useShortenedLinks = (searchParam: string) => {
  const [data, setData] = useState<ShortenedLink[]>([]);
  const [page, setPage] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const debouncedSearch = useDebounce(searchParam, 900);
  const lastSearchParam = useRef('');

  useEffect(() => {
    const getLinks = async () => {
      const normalizedSearch = debouncedSearch.trim().replace(/\s+/g, '%');

      if (page !== 0 && lastSearchParam.current !== normalizedSearch) {
        setPage(0);
        return;
      }

      lastSearchParam.current = normalizedSearch;
      setIsLoading(true);
      try {
        const data = await linkShortnerApi.fetchLinks({
          page,
          search: normalizedSearch || undefined,
        });
        setData(data);
      } catch (err) {
        message.error('Não foi possivel buscar os links. Tente novamente');
      } finally {
        setIsLoading(false);
      }
    };

    getLinks();
  }, [page, debouncedSearch]);

  const handleUpdatePage = (newPage: number) => {
    setPage(newPage - 1);
  };

  return {
    data,
    isLoading,
    currentPage: page + 1,
    handleUpdatePage,
  };
};
