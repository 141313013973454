import { Col, Input, Space, Typography } from 'antd';
import { useFormContext } from 'react-hook-form';

import { DesignStepForm } from 'src/features/programs/schemas/designStepSchema';

import { ProgramFormItem } from '../../ProgramFormItem';
import { ProgramSection } from '../../ui/ProgramSection';
import { ProgramSectionGroup } from '../../ui/ProgramSectionGroup';
import { ImageUpload } from './ImageUpload';

const { Text } = Typography;

export const ProgramDesignContent = () => {
  const { control } = useFormContext<DesignStepForm>();

  return (
    <Space direction='vertical' size={24} style={{ width: '100%' }}>
      <ProgramSection>
        <ProgramSectionGroup isHeader>
          <Col flex={1}>Cor Primária</Col>
          <Text style={{ fontWeight: 'initial' }}>
            Exibida apenas na versão mobile
          </Text>
        </ProgramSectionGroup>
        <ProgramSectionGroup>
          <ProgramFormItem
            name='primaryColor'
            control={control}
            renderItem={({ field }) => (
              <Input {...field} placeholder='Ex. #FFFFFF' />
            )}
          />
        </ProgramSectionGroup>
      </ProgramSection>

      <ProgramSection>
        <ProgramSectionGroup isHeader>
          <Col flex={1}>Cards</Col>
          <UploadHelperText />
        </ProgramSectionGroup>
        <ProgramSectionGroup>
          <ImageUpload
            title='TV (com marca)'
            helperText='Deve ter exatamente 880x496.'
            fieldName='imageCard'
            templateHref='/program-templates/card_web_and_tv.psd'
          />
        </ProgramSectionGroup>
        <ProgramSectionGroup>
          <ImageUpload
            title='Desktop'
            helperText='Deve ter exatamente 1125x634.'
            fieldName='imageCardWeb'
            templateHref='/program-templates/card_web_and_tv.psd'
          />
        </ProgramSectionGroup>
        <ProgramSectionGroup>
          <ImageUpload
            title='Mobile'
            helperText='Deve ter exatamente 591x840.'
            fieldName='imageVertical'
            templateHref='/program-templates/card_vertical.psd'
          />
        </ProgramSectionGroup>
      </ProgramSection>

      <ProgramSection>
        <ProgramSectionGroup isHeader>
          <Col flex={1}>Logotipo</Col>
          <UploadHelperText />
        </ProgramSectionGroup>
        <ProgramSectionGroup>
          <ImageUpload
            title='Logotipo'
            helperText='Não deixar pixels vazios na imagem. Pois em cada device, a logo estará posicionada de forma diferente dentro do container, evite logo muito quadradas. A largura da imagem não poderá passar de 550 pixels.'
            fieldName='imageLogo'
          />
        </ProgramSectionGroup>
      </ProgramSection>

      <ProgramSection>
        <ProgramSectionGroup isHeader>
          <Col flex={1}>Banner Super</Col>
          <UploadHelperText />
        </ProgramSectionGroup>
        <ProgramSectionGroup>
          <ImageUpload
            title='Desktop/TV'
            helperText='Deve ter exatamente 1920x512.'
            fieldName='bannerSuper.tv'
            templateHref='/program-templates/banner_super_tv.psd'
          />
        </ProgramSectionGroup>
        <ProgramSectionGroup>
          <ImageUpload
            title='Tablet'
            helperText='Deve ter exatamente 834x298.'
            fieldName='bannerSuper.tablet'
            templateHref='/program-templates/banner_super_tablet.psd'
          />
        </ProgramSectionGroup>
        <ProgramSectionGroup>
          <ImageUpload
            title='Mobile'
            helperText='Deve ter exatamente 375x200.'
            fieldName='bannerSuper.mobile'
            templateHref='/program-templates/banner_super_mobile.psd'
          />
        </ProgramSectionGroup>
      </ProgramSection>

      <ProgramSection>
        <ProgramSectionGroup isHeader>
          <Col flex={1}>Banner Quiz</Col>
          <UploadHelperText />
        </ProgramSectionGroup>
        <ProgramSectionGroup>
          <ImageUpload
            title='Desktop/TV'
            helperText='Deve ter exatamente 2520x768.'
            fieldName='bannerOnboarding.tv'
          />
        </ProgramSectionGroup>
        <ProgramSectionGroup>
          <ImageUpload
            title='Mobile'
            helperText='Deve ter exatamente 1968x1320.'
            fieldName='bannerOnboarding.mobile'
          />
        </ProgramSectionGroup>
      </ProgramSection>

      <ProgramSection>
        <ProgramSectionGroup isHeader>
          <Col flex={1}>Banner Highlight</Col>
          <UploadHelperText />
        </ProgramSectionGroup>
        <ProgramSectionGroup>
          <ImageUpload
            title='Tablet'
            helperText='Deve ter exatamente 834x596.'
            fieldName='bannerHighlight.tablet'
          />
        </ProgramSectionGroup>
        <ProgramSectionGroup>
          <ImageUpload
            title='Mobile'
            helperText='Deve ter exatamente 375x334.'
            fieldName='bannerHighlight.mobile'
          />
        </ProgramSectionGroup>
      </ProgramSection>

      <ProgramSection>
        <ProgramSectionGroup isHeader>
          <Col flex={1}>Preview do PDF (se houver)</Col>
          <UploadHelperText />
        </ProgramSectionGroup>
        <ProgramSectionGroup>
          <ImageUpload title='Imagem do card do PDF' fieldName='thumbPdf' />
        </ProgramSectionGroup>
      </ProgramSection>
    </Space>
  );
};

const UploadHelperText = () => (
  <Text style={{ fontWeight: 'initial' }}>Use apenas arquivos .png</Text>
);
