import { CopyOutlined, DeleteOutlined, MoreOutlined } from '@ant-design/icons';
import { Button, Dropdown, MenuProps, message } from 'antd';
import React from 'react';
import styled from 'styled-components';

import { linkShortnerApi } from '../api/linkShortnerApi';

interface LinkShortnerTableActionsProps {
  linkId: string;
  link: string;
}

export const LinkShortnerTableActions: React.FC<LinkShortnerTableActionsProps> =
  ({ linkId, link }) => {
    const handleCopyLink = async () => {
      try {
        await navigator.clipboard.writeText(link);
        message.success('Link copiado para a área de transferência!');
      } catch (error) {
        message.error('Falha ao copiar o link.');
      }
    };

    const handleDeleteLink = async () => {
      try {
        await linkShortnerApi.deleteLink(linkId);
        message.success('Link deletado com sucesso!');
        setTimeout(() => {
          window.location.reload();
        }, 500);
      } catch (error) {
        message.error('Falha ao deletar o link.');
      }
    };

    const handleClick: MenuProps['onClick'] = e => {
      switch (e.key) {
        case 'COPY':
          handleCopyLink();
          break;
        case 'DELETE':
          handleDeleteLink();
          break;
        default:
          break;
      }
    };

    const items: MenuProps['items'] = [
      {
        key: 'COPY',
        label: 'Copiar link',
        icon: <CopyOutlined />,
      },
      {
        key: 'DELETE',
        label: `Excluir link`,
        icon: <DeleteOutlined />,
      },
    ];

    return (
      <Dropdown
        placement='bottomRight'
        menu={{ items, onClick: handleClick }}
        trigger={['click']}
      >
        <CustomButton
          data-testid='link-menu-actions'
          style={{ border: 'none' }}
        >
          <MoreOutlined />
        </CustomButton>
      </Dropdown>
    );
  };

const CustomButton = styled(Button)`
  &:hover {
    background-color: rgba(62, 62, 62, 0.7);
    color: white;
  }
`;
