import { ArrowRightOutlined } from '@ant-design/icons';
import { Button, Col, Divider, Input, message, Row, Typography } from 'antd';
import { Dispatch, memo, SetStateAction, useState } from 'react';
import styled from 'styled-components';

import { env } from 'src/app/env';

import { modalRedirectApi } from '../api/modalRedirectApi';
import { ModalRedirect } from '../typings/modalRedirect';
import {
  modalRedirectValidations,
  RedirectValidationsKey,
} from '../utils/modalRedirectValidations';

const { Title, Text } = Typography;

const initialState = {
  error: '',
  referrerUrl: '',
  checkoutNumber: '',
};

interface ModalRedirectHeaderProps {
  redirects: ModalRedirect[];
  setRedirects: Dispatch<SetStateAction<ModalRedirect[]>>;
}

export const ModalRedirectHeader = memo<ModalRedirectHeaderProps>(
  ({ redirects, setRedirects }) => {
    const [state, setState] = useState(initialState);
    const [isLoading, setIsLoading] = useState(false);

    const hasEmptyFields = !state.referrerUrl || !state.checkoutNumber;

    const onChange = (name: RedirectValidationsKey, value: string) => {
      const validate = modalRedirectValidations[name];

      setState(prev => ({
        ...prev,
        [name]: value,
        error: validate(value, redirects),
      }));
    };

    const handleAddRedirect = async () => {
      setIsLoading(true);
      try {
        const data = await modalRedirectApi.addRedirect(
          state.referrerUrl,
          `${env.CHECKOUT}/${state.checkoutNumber}`
        );
        setRedirects(prev => [data, ...prev]);
        setState(initialState);
      } catch (err) {
        message.error('Erro ao adicionar redirecionamento.');
      } finally {
        setIsLoading(false);
      }
    };

    return (
      <>
        <Title level={3}>Modal Redirect</Title>

        <Divider style={{ marginBlock: '40px' }} />

        <Row gutter={[16, 0]} style={{ alignItems: 'flex-end' }}>
          <Col span={6}>
            <InputHelper>Página de origem</InputHelper>
            <Input
              placeholder='ex. queima.com ou queima.com/real'
              value={state.referrerUrl}
              onChange={event => onChange('referrerUrl', event.target.value)}
            />
          </Col>
          <Col style={{ paddingBottom: '6px' }}>
            <ArrowRightOutlined />
          </Col>
          <Col span={6}>
            <InputHelper>Checkout destino</InputHelper>
            <Input
              placeholder='ex. 131 ou 92'
              value={state.checkoutNumber}
              onChange={event => onChange('checkoutNumber', event.target.value)}
            />
          </Col>
          <Col>
            <Button
              type='primary'
              onClick={handleAddRedirect}
              loading={isLoading}
              disabled={hasEmptyFields || Boolean(state.error)}
            >
              Adicionar
            </Button>
          </Col>
        </Row>

        {state.error && (
          <Row style={{ marginTop: '8px' }}>
            <Text type='danger'>{state.error}</Text>
          </Row>
        )}
      </>
    );
  }
);

const InputHelper = styled(Text)`
  display: inline-block;
  margin-bottom: 4px;
  font-size: 16px;
  font-weight: 500;
`;
