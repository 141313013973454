import { Col, InputNumber, Select, Space, Typography } from 'antd';
import { useFieldArray, useFormContext } from 'react-hook-form';
import styled from 'styled-components';

import { OrderStepForm } from 'src/features/programs/schemas/orderStepSchema';

import { ProgramFormItem } from '../../ProgramFormItem';
import { ProgramSection } from '../../ui/ProgramSection';
import { ProgramSectionGroup } from '../../ui/ProgramSectionGroup';

const { Paragraph } = Typography;

export const ProgramOrderSections = () => {
  const { control } = useFormContext<OrderStepForm>();

  const { fields } = useFieldArray({
    control: control,
    name: 'sections',
  });

  return (
    <Space direction='vertical' size={24} style={{ width: '100%' }}>
      {fields.map((section, index) => (
        <ProgramSection key={section.id}>
          <ProgramSectionGroup isHeader>{section.name}</ProgramSectionGroup>
          <ProgramSectionGroup gutter={[16, 16]}>
            <Col span={4}>
              <ProgramFormItem
                control={control}
                name={`sections.${index}.numberRepetitions`}
                label='Número de repetições'
                renderItem={({ field }) => <InputNumber {...field} min={0} />}
              />
            </Col>
            <Col span={20} />

            {section.playlistContents?.map((item, itemIndex) => (
              <Col span={4} key={item.contentId} style={{ minWidth: '197px' }}>
                <SelectBox>
                  <Paragraph>Aula {itemIndex + 1}</Paragraph>
                  <ProgramFormItem
                    control={control}
                    name={`sections.${index}.playlistContents.${itemIndex}.contentId`}
                    renderItem={({ field }) => (
                      <Select
                        {...field}
                        options={section.selectOptions}
                        placeholder='Selecione a aula'
                      />
                    )}
                  />
                </SelectBox>
              </Col>
            ))}
          </ProgramSectionGroup>
        </ProgramSection>
      ))}
    </Space>
  );
};

const SelectBox = styled.div`
  padding: 16px;
  background-color: #262626;
  border: 1px solid #303030;
  border-radius: 4px;
`;
