import { useQuery } from 'react-query';

import { customerApi } from '../api/customerApi';

export const useHistory = (customerId: string) => {
  return useQuery({
    queryKey: ['history', customerId],
    queryFn: () => customerApi.fetchHistory(customerId),
  });
};
