import { baseClient } from 'src/app/baseClient';

import {
  CreateOrUpdateProgramBaseInfo,
  Program,
  ProgramType,
  PublishProgramPayload,
} from '../types/program';
import { SimpleProgram } from '../types/simpleProgram';

async function fetchPublishedPrograms() {
  const { data } = await baseClient.get<SimpleProgram[]>(
    '/programs?published=true'
  );
  return data;
}

async function fetchUnpublishedPrograms() {
  const { data } = await baseClient.get<SimpleProgram[]>(
    '/programs?published=false'
  );
  return data;
}

async function unpublishProgram(programId: number) {
  await baseClient.patch(`/programs/${programId}/unpublish`);
}

async function fetchProgramsTypes() {
  const { data } = await baseClient.get<ProgramType[]>('/programs/types');
  return data;
}

async function fetchProgramsCategories() {
  const { data } = await baseClient.get<Program['categories']>(
    '/programs/categories'
  );
  return data;
}

async function createInstructor(name: string, description: string) {
  const now = new Date();
  const { data } = await baseClient.post<{ id: number }>('/instructors', {
    name,
    description,
    createdAt: now,
    updatedAt: now,
  });
  return data;
}

async function patchInstructorAvatar(
  file: FormData,
  instructorId: number,
  programId: number
) {
  await baseClient.patch(
    `/programs/${programId}/instructors/${instructorId}/upload`,
    file,
    { headers: { 'Content-Type': 'multipart/form-data' } }
  );
}

async function fetchProgram(programId: number) {
  const { data } = await baseClient.get<Program>(
    `/programs/${programId}/contents`
  );
  return data;
}

async function createProgram(payload: CreateOrUpdateProgramBaseInfo) {
  const { data } = await baseClient.post<{ id: number }>('/programs', payload);
  return data;
}

async function updateProgram(
  programId: number,
  payload: CreateOrUpdateProgramBaseInfo
) {
  await baseClient.patch(`/programs/${programId}`, payload);
}

async function updateProgramPrimaryColor(
  programId: number,
  primaryColor: string
) {
  await baseClient.patch(`/programs/${programId}`, { primaryColor });
}

async function uploadProgramImages(programId: number, formData: FormData) {
  await baseClient.patch(`/programs/${programId}/upload`, formData, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });
}

async function publishProgram(
  programId: number,
  payload: PublishProgramPayload | null
) {
  const { data } = await baseClient.patch<{ message: string }>(
    `/programs/${programId}/publish`,
    { ...payload }
  );
  return data;
}

async function fetchSpallaStatus(spallaId: string) {
  const { data } = await baseClient.get<{ status: string }>(
    `/spalla/${spallaId}`
  );
  return data;
}

export const programsApi = {
  fetchPublishedPrograms,
  fetchUnpublishedPrograms,
  unpublishProgram,
  fetchProgramsTypes,
  fetchProgramsCategories,
  createInstructor,
  patchInstructorAvatar,
  fetchProgram,
  createProgram,
  updateProgram,
  updateProgramPrimaryColor,
  uploadProgramImages,
  publishProgram,
  fetchSpallaStatus,
};
