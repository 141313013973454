import { base } from '@service/base';

import { guestApi } from 'src/app/guestApi';

import { FormData, RequestAnonymizeDTO } from '../interfaces/lgpdInterfaces';

async function postQueryLgpd(params: FormData): Promise<RequestAnonymizeDTO> {
  const endpoint = `/lgpd/anonymize`;
  const response = await guestApi.post(endpoint, params).then(response => {
    if (response.status === 201) {
      return response?.data;
    } else {
      return response;
    }
  });

  return response;
}

async function getUserData() {
  const endpoint = `/user`;
  return await guestApi.get(endpoint);
}

async function getUserByIdData(id: string) {
  const endpoint = `/user/admin/${id}`;
  const response = await guestApi.get(endpoint);
  return response?.data.fullName;
}

async function getAllUserConsulted(): Promise<RequestAnonymizeDTO[]> {
  const endpoint = `/lgpd/anonymize`;
  const response = await guestApi.get(endpoint);
  return response.data;
}

async function patchAnonymizeEvidence(
  idConsult: string
): Promise<RequestAnonymizeDTO> {
  const endpoint = `lgpd/anonymize/${idConsult}`;
  const response = await guestApi
    .patch(endpoint, {
      mode: 'executar',
    })
    .then(response => {
      return response?.data;
    })
    .catch(() => {
      throw new Error();
    });
  return response;
}

async function deleteProfile(idConsult: string): Promise<void> {
  const endpoint = `lgpd/profile/${idConsult}`;
  await base.delete(endpoint);
}
async function deleteAccess(idConsult: string): Promise<void> {
  const endpoint = `lgpd/access/${idConsult}`;
  await base.delete(endpoint);
}
async function deleteCards(idConsult: string): Promise<void> {
  const endpoint = `lgpd/cards/${idConsult}`;
  await base.delete(endpoint);
}

async function getCustomerEvidence(
  idCostumer: string
): Promise<RequestAnonymizeDTO> {
  const endpoint = `lgpd/anonymize/${idCostumer}`;
  const response = await guestApi.get(endpoint).then(response => {
    return response?.data[0];
  });
  return response;
}

async function getUserPermission(id: string) {
  const endpoint = `/user/admin/${id}`;
  const response = await guestApi.get(endpoint);
  return response?.data;
}

export const lgpdApi = {
  getAllUserConsulted,
  postQueryLgpd,
  getUserData,
  getCustomerEvidence,
  patchAnonymizeEvidence,
  deleteProfile,
  deleteAccess,
  deleteCards,
  getUserByIdData,
  getUserPermission,
};
