import { baseClient } from 'src/app/baseClient';

import {
  CreateProgramContent,
  CreateProgramModule,
  UpdateProgramContent,
  UpdateProgramModule,
} from '../types/programModulesAndContents';

async function deleteContent(contentId: number) {
  const { data } = await baseClient.delete<{ message: string }>(
    `/contents/${contentId}`
  );
  return data;
}

async function deleteModule(moduleId: number) {
  await baseClient.delete(`/modules/${moduleId}`, {
    // qs as paramsSerializer not working correctly with this request
    paramsSerializer: null as any,
    params: {
      filter: {
        force: true,
      },
    },
  });
}

async function createModule(payload: CreateProgramModule) {
  const { data } = await baseClient.post<{ id: number; name: string }>(
    '/modules',
    payload
  );
  return data;
}

async function updateModule(payload: UpdateProgramModule) {
  const { id, ...rest } = payload;
  await baseClient.patch(`/modules/${id}`, rest);
}

async function createContent(payload: CreateProgramContent) {
  await baseClient.post('/contents', payload);
}

async function updateContent(payload: UpdateProgramContent) {
  const { id, ...rest } = payload;
  await baseClient.patch(`/contents/${id}`, rest);
}

export const programModulesAndContentsApi = {
  deleteContent,
  deleteModule,
  updateModule,
  createModule,
  createContent,
  updateContent,
};
