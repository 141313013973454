import { message } from 'antd';

type ToastMessageProps = {
  content: string;
  type?: 'info' | 'success' | 'error' | 'warning' | 'loading';
};

export const ToastMessage = ({ content, type = 'info' }: ToastMessageProps) => {
  message[type]({
    content,
    style: {
      display: 'flex',
      justifyContent: 'right',
      marginRight: '20px',
      zIndex: 1000,
    },
  });
};
