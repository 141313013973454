interface WithId {
  id: number;
}

export const LOCAL_FORM_ITEM_ID = 0;

export const filterByLocalFormId = ({ id }: WithId) =>
  LOCAL_FORM_ITEM_ID === id;

export const filterByRemoteFormId = ({ id }: WithId) =>
  LOCAL_FORM_ITEM_ID !== id;
