import { useQuery } from 'react-query';

import { customerApi } from '../api/customerApi';

export const useCustomerActiveDays = (
  customerId: string,
  startDate: string,
  endDate: string
) => {
  const { data, isLoading, isError, refetch } = useQuery({
    queryKey: ['customer-active-days', customerId, startDate, endDate],
    queryFn: () =>
      customerApi.fetchCustomerActiveDays(customerId, startDate, endDate),
    refetchOnWindowFocus: false,
    enabled: !!customerId,
  });

  const refetchOnError = () => {
    refetch();
  };

  return {
    activeDays: data?.activeDays,
    isLoading,
    isError,
    refetchOnError,
  };
};
