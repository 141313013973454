import { useQuery } from 'react-query';

import { customerApi } from '../api/customerApi';

export const useCommunicationEmails = (
  customerId: string,
  startDate?: string,
  endDate?: string,
  page?: number,
  limit = 10,
  subject?: string,
  status?: string
) => {
  const { data, isLoading, isError, refetch } = useQuery({
    queryKey: [
      'customer-communication-emails',
      customerId,
      startDate,
      endDate,
      page,
      limit,
      subject,
      status,
    ],
    queryFn: () =>
      customerApi.fetchCustomerCommunicationEmails(
        customerId,
        startDate,
        endDate,
        page,
        limit,
        subject,
        status
      ),
    refetchOnWindowFocus: false,
    enabled: !!customerId,
  });

  const refetchOnError = () => {
    refetch();
  };

  return {
    customerEmails: data?.emails,
    totalCount: Number(data?.totalItems),
    pageSize: limit,
    isLoading,
    isError,
    refetchOnError,
  };
};

export const useLastCommunicationEmail = (customerId: string) => {
  const { data, isLoading, isError, refetch } = useQuery({
    queryKey: ['customer-last-communication-email', customerId],
    queryFn: () => customerApi.fetchCustomerLastCommunicationMail(customerId),
    refetchOnWindowFocus: false,
    enabled: !!customerId,
  });

  const refetchOnError = () => {
    refetch();
  };

  const isValidLastEmail =
    data && 'subject' in data && 'status' in data ? data : undefined;

  return {
    lastEmail: isValidLastEmail,
    isLoading,
    isError,
    refetchOnError,
  };
};

export const useCommunicationRenewalEmails = (customerId: string) => {
  const { data, isLoading, isError, refetch } = useQuery({
    queryKey: ['customer-communication-renewal-emails', customerId],
    queryFn: () =>
      customerApi.fetchCustomerCommunicationRenewalMails(customerId),
    refetchOnWindowFocus: false,
    enabled: !!customerId,
  });

  const refetchOnError = () => {
    refetch();
  };

  return {
    renewalEmails: data?.emails,
    isLoading,
    isError,
    refetchOnError,
  };
};
