import { ModalRedirect } from '../typings/modalRedirect';

export type RedirectValidationsKey = keyof typeof modalRedirectValidations;

export const modalRedirectValidations = {
  referrerUrl: (value: string, redirects: ModalRedirect[]) => {
    if (redirects.find(item => item.referrerUrl === value)) {
      return 'Página de origem já existe';
    }

    if (/^www\./i.test(value)) {
      return 'Página de origem não deve começar com www.';
    }

    if (/^https?:\/\//i.test(value)) {
      return 'Página de origem não deve começar com https:// ou http://';
    }

    if (!/^\S*$/.test(value)) {
      return 'Página de origem não deve conter espaços em branco';
    }

    if (/^https?:\/\//i.test(value)) {
      return 'Página de origem não deve começar com https:// ou http://';
    }

    if (/\/$/i.test(value)) {
      return 'Página de origem não deve terminar com /';
    }

    return '';
  },
  checkoutNumber: (value: string) =>
    /^\S*$/.test(value)
      ? ''
      : 'Checkout destino não deve conter espaços em branco',
};
