import { Input } from 'antd';
import styled from 'styled-components';

export const TransparentInput = styled(Input)`
  background: transparent !important;
  padding: 0;
  border-color: transparent;
  font-weight: 600;

  /* Fix for Google Chrome Autofill colors */
  transition: color calc(infinity * 1s) step-end,
    background-color calc(infinity * 1s) step-end;

  &[disabled] {
    border-color: transparent;
  }
`;
