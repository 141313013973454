import { useQuery } from 'react-query';

import { customerApi } from '../api/customerApi';

export const useCustomerSearches = (
  customerId: string,
  startDate: string,
  endDate: string,
  page: number,
  limit: number
) => {
  const { data, isLoading, isError, refetch } = useQuery({
    queryKey: [
      'customer-searches',
      customerId,
      startDate,
      endDate,
      page,
      limit,
    ],
    queryFn: () =>
      customerApi.fetchCustomerSearches(
        customerId,
        startDate,
        endDate,
        page,
        limit
      ),
    refetchOnWindowFocus: false,
    enabled: !!customerId,
  });

  const refetchOnError = () => {
    refetch();
  };

  return {
    customerSearchesList: data?.rows,
    totalCount: Number(data?.count),
    pageSize: limit,
    isLoading,
    isError,
    refetchOnError,
  };
};
