import { Card, Col, DatePicker, Row, Space, Spin, Typography } from 'antd';
import { format } from 'date-fns';
import moment from 'moment';
import { RangeValue } from 'rc-picker/lib/interface';
import React, { useState } from 'react';
import styled from 'styled-components';

import { SEGMENTED_PERIOD_OPTIONS } from '../../constants/filters';
import { useCustomerProgramsWatched } from '../../hooks/useCustomerProgramsWatched';
import { EmptyState } from '../EmptyState';
import { ErrorState } from '../ErrorState';
import { SegmentedPeriod } from '../SegmentedPeriod';
import { CustomHorizontalBarChart } from './CustomHorizontalChart';

const { Title } = Typography;
const { RangePicker } = DatePicker;

type ProgramsWatchedType = {
  customerId: string;
};

export const ProgramsWatched: React.FC<ProgramsWatchedType> = ({
  customerId,
}) => {
  const lastYear = SEGMENTED_PERIOD_OPTIONS[0];
  const lastYearRangeDates = [lastYear.startDate, lastYear.endDate];
  const [segmentedLabelSelected, setSegmentedLabelSelected] = useState(
    lastYear.label
  );
  const [rangeDatesSelected, setRangeDatesSelected] =
    useState<string[]>(lastYearRangeDates);
  const [isDatePicker, setIsDatePicker] = useState(false);

  const handleSelectDatePicker = (dates: RangeValue<moment.Moment>) => {
    if (dates && dates[0] && dates[1]) {
      const rangeDates = [
        dates[0].format('YYYY-MM-DD'),
        dates[1].format('YYYY-MM-DD'),
      ];
      setIsDatePicker(() => {
        setRangeDatesSelected(rangeDates);
        return true;
      });
    } else {
      setIsDatePicker(() => {
        setSegmentedLabelSelected(lastYear.label);
        setRangeDatesSelected(lastYearRangeDates);
        return false;
      });
    }
  };

  const handleSegmentedPeriodChange = (
    dates: [string, string],
    labelSelected: string
  ) => {
    setSegmentedLabelSelected(() => {
      setRangeDatesSelected(dates);
      return labelSelected;
    });
  };

  const [startDate, endDate] = rangeDatesSelected;
  const { mostWatchedPrograms, isLoading, isError, refetchOnError } =
    useCustomerProgramsWatched(customerId, startDate, endDate);

  if (isError) {
    return (
      <Col span={12}>
        <FlexCard>
          <Title level={5} style={{ margin: 0 }}>
            Programas Mais Assistidos
          </Title>
          <Row
            justify={'center'}
            align={'middle'}
            style={{
              width: '100%',
              height: '100%',
              gap: '32px',
              paddingBlock: '70px',
            }}
          >
            <Col>
              <ErrorState
                title='Erro ao carregar programas assistidos'
                subtitle='Infelizmente tivemos um erro ao carregar os programas assistidos do cliente. Aperte no botão abaixo para recarregar'
                buttonLabel='Recarregar'
                onClickButton={() => refetchOnError()}
              />
            </Col>
          </Row>
        </FlexCard>
      </Col>
    );
  }

  return (
    <Col span={12}>
      <FlexCard>
        <Space direction='vertical' size='middle' style={{ display: 'flex' }}>
          <Title level={5} style={{ margin: 0 }}>
            Programas Mais Assistidos
          </Title>

          <Row
            justify={'space-between'}
            align={'middle'}
            style={{ width: '100%', gap: '32px' }}
          >
            <SegmentedPeriod
              selectedValue={segmentedLabelSelected}
              onChangePeriod={handleSegmentedPeriodChange}
              disabled={isDatePicker}
            />
            <RangePicker
              style={{ flex: 1 }}
              format={value => format(value.toDate(), 'dd/MM/yyyy')}
              onChange={dates => handleSelectDatePicker(dates)}
            />
          </Row>

          {mostWatchedPrograms?.length !== 0 && mostWatchedPrograms && (
            <CustomHorizontalBarChart
              labels={mostWatchedPrograms.map(program => program.programName)}
              label='Aulas Assistidas'
              dataValues={mostWatchedPrograms.map(program =>
                Number(program.count)
              )}
              backgroundColor={'rgba(0, 123, 255, 0.8)'}
            />
          )}
        </Space>

        {mostWatchedPrograms?.length === 0 && (
          <Row
            style={{
              height: '80%',
              alignItems: 'center',
              flex: '1 1 200px',
              justifyContent: 'center',
              paddingBlock: '70px',
            }}
          >
            <EmptyState
              title='Sem programas assistidos'
              subtitle='Infelizmente não existe programas assistidos para este cliente no período selecionado.'
            />
          </Row>
        )}

        {isLoading && (
          <Row
            justify={'center'}
            align={'middle'}
            style={{
              width: '100%',
              height: '100%',
              gap: '32px',
              paddingBlock: '70px',
            }}
          >
            <Col>
              <Spin />
            </Col>
          </Row>
        )}
      </FlexCard>
    </Col>
  );
};

const FlexCard = styled(Card)`
  height: 100%;
  & > .ant-card-body {
    height: 100%;
  }
`;
