import { UserOutlined } from '@ant-design/icons';
import { slices } from '@core/redux';
import { Avatar, Layout, Menu, Popover } from 'antd';
import { FC, useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import { Logo } from '../../../app/framework/atoms/Icons/Logo';
import { ModalChangePassword } from './ModalChangePassword';
export const AppHeader: FC = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);

  const dispatch = useDispatch();
  const onLogout = useCallback(() => dispatch(slices.auth.tryLogout()), []);
  const history = useHistory();
  const content = (
    <div>
      <MenuSelect onClick={() => setIsModalVisible(true)}>
        Alterar senha
      </MenuSelect>
      <MenuSelect onClick={onLogout} style={{ color: 'red', margin: 0 }}>
        Sair
      </MenuSelect>
    </div>
  );
  return (
    <>
      <Header style={{ paddingInline: '120px' }}>
        <RightMenu
          theme='dark'
          mode='horizontal'
          items={[
            {
              key: 'logo',
              icon: (
                <BrandLogo
                  style={{ marginBottom: 0, cursor: 'initial' }}
                  width={138}
                  height={15}
                />
              ),
            },
            {
              key: 'admin',
              label: 'Admin',
              onClick: () => history.push('/dashboard'),
            },
            {
              key: 'midia',
              label: 'Mídia',
              disabled: true,
              onClick: () => history.push('/midia'),
            },
            {
              key: 'engenharia',
              label: 'Engenharia',
              disabled: true,
              onClick: () => history.push('/engenharia'),
            },
            {
              key: 'Social',
              label: 'Social',
              onClick: () => history.push('/social/dashboard'),
            },
            {
              key: 'logout',
              icon: (
                <Popover content={content} trigger='click'>
                  <Avatar icon={<UserOutlined />} />
                </Popover>
              ),
              style: {
                background: 'inherit',
                position: 'absolute',
                right: 10,
                paddingInline: '120px',
              },
            },
          ]}
        />
      </Header>
      <ModalChangePassword
        isModalVisible={isModalVisible}
        setIsModalVisible={setIsModalVisible}
      />
    </>
  );
};

const RightMenu = styled(Menu)`
  height: 64px;
`;

const Header = styled(Layout.Header)`
  padding: 0px;
`;
const BrandLogo = styled(Logo)`
  margin-bottom: 0;
`;

const MenuSelect = styled.p`
  cursor: pointer;
`;
