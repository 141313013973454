import { InformationStepForm } from '../schemas/informationStepSchema';
import { CreateOrUpdateProgramDetails } from '../types/programDetails';

export const getProgramDetailsFromForm = (
  fields: InformationStepForm,
  programId?: number
): CreateOrUpdateProgramDetails => {
  return {
    programName: fields.name || '',
    gender: fields.gender,
    forMothers: fields.forMothers,
    programId: programId,
    accessories: Boolean(fields.accessory),
    durationValue: fields.durationValue,
    levels: fields.levelIds,
    frequency: fields.frequency,
    objectiveMain: fields.objectiveId,
    instructors: [fields.instructorId],
    modalitys: fields.modalityIds,
    numberOfClasses: fields.numberOfClasses,
    tags: fields.tags.split(',').map(item => item.trim()),
    isQuizRecommended: Boolean(fields.isQuizRecommended),
    objectives: fields.secondaryObjectivesIds,
    durationTime: fields.metadata.quantityMeanDuration,
    recommendationPriority: fields.recommendationPriority ?? null,
  };
};
