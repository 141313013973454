import { memo } from 'react';
import { useQuery } from 'react-query';

import { programsApi } from '../api/programsApi';
import { ProgramsTable } from './ProgramsTable';

export const UnpublishedProgramsTable = memo(() => {
  const { data, isLoading } = useQuery(
    ['unpublished-programs'],
    programsApi.fetchUnpublishedPrograms,
    { refetchOnWindowFocus: false }
  );

  return <ProgramsTable programs={data || []} loading={isLoading} />;
});
