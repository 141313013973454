import { PlusOutlined } from '@ant-design/icons';
import { Button, Space } from 'antd';
import { FC } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';

import { ProgramBonusesForm } from 'src/features/programs/schemas/programBonusesSchema';
import { LOCAL_FORM_ITEM_ID } from 'src/features/programs/utils/formHelpers';
import { showConfirmModal } from 'src/features/programs/utils/modalHelpers';

import { ProgramFormItem } from '../../ProgramFormItem';
import { Closable } from '../../ui/Closable';
import { ProgramSection } from '../../ui/ProgramSection';
import { ProgramSectionGroup } from '../../ui/ProgramSectionGroup';
import { TransparentInput } from '../../ui/TransparentInput';
import { PdfUpload } from './PdfUpload';

interface CustomBonusesProps {
  programId: number | undefined;
}

export const CustomBonuses: FC<CustomBonusesProps> = ({ programId }) => {
  const { control } = useFormContext<ProgramBonusesForm>();
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'customBonuses',
  });

  const confirmRemove = (index: number) => {
    showConfirmModal({
      title: 'Deseja realmente remover?',
      content: 'Tem certeza que deseja remover este PDF?',
      onOk() {
        remove(index);
      },
    });
  };

  return (
    <>
      <Space
        direction='vertical'
        size={24}
        style={{ width: '100%', marginTop: '24px' }}
      >
        {fields.map((field, index) => (
          <Closable key={field.id} onClose={() => confirmRemove(index)}>
            <ProgramSection>
              <ProgramSectionGroup isHeader>
                <ProgramFormItem
                  control={control}
                  name={`customBonuses.${index}.name`}
                  renderItem={({ field }) => (
                    <TransparentInput {...field} placeholder='Título do PDF' />
                  )}
                />
              </ProgramSectionGroup>

              <ProgramSectionGroup>
                <PdfUpload index={index} initialMetadata={field.metadata} />
              </ProgramSectionGroup>
            </ProgramSection>
          </Closable>
        ))}
      </Space>

      <Button
        type='link'
        icon={<PlusOutlined />}
        onClick={() =>
          append({
            name: '',
            bonusProgramId: null,
            bonusId: LOCAL_FORM_ITEM_ID,
            metadata: { file: null, media: '', name: '', type: 'pdf', url: '' },
          })
        }
        style={{ marginTop: 16, marginInline: 'auto' }}
      >
        Adicionar PDF
      </Button>
    </>
  );
};
