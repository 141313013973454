import { Button, Divider, Row, Tabs, Typography } from 'antd';
import { FC, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { useBreadcrumbs } from 'src/features/layout/hooks/useBreadcrumbs';

import { PublishedProgramsTable } from '../components/PublishedProgramsTable';
import { UnpublishedProgramsTable } from '../components/UnpublishedProgramsTable';

const { Title } = Typography;

enum ActiveTabEnum {
  COMPLETED = 'completed',
  INCOMPLETE = 'incomplete',
}

export const ProgramsScreen: FC = () => {
  useBreadcrumbs([
    {
      label: 'Dashboard',
      route: '/dashboard',
    },
    {
      label: 'Programas',
    },
  ]);

  const history = useHistory();

  const [activeTab, setActiveTab] = useState<ActiveTabEnum>(
    ActiveTabEnum.COMPLETED
  );

  const isCompletedTab = activeTab === ActiveTabEnum.COMPLETED;

  return (
    <>
      <Row style={{ marginBottom: '40px' }} justify='space-between'>
        <Title level={3}>
          Programas {isCompletedTab ? 'completos' : 'incompletos'}
        </Title>

        <Button
          type='primary'
          onClick={() => history.push('/programs/create-or-update')}
        >
          Criar programa
        </Button>
      </Row>

      <Divider style={{ marginBottom: '24px' }} />

      <Tabs
        activeKey={activeTab}
        onChange={tab => setActiveTab(tab as ActiveTabEnum)}
        items={[
          {
            label: 'Completos',
            key: ActiveTabEnum.COMPLETED,
            children: <PublishedProgramsTable />,
          },
          {
            label: 'Incompletos',
            key: ActiveTabEnum.INCOMPLETE,
            children: <UnpublishedProgramsTable />,
          },
        ]}
      />
    </>
  );
};
