import {
  Button,
  Col,
  Divider,
  Input,
  message,
  Row,
  Select,
  Typography,
} from 'antd';
import { memo, useState } from 'react';

import { linkGeneratorApi } from '../api/linkGeneratorApi';
import { Affiliate, Domain, Plan } from '../typings/linkGenerator';

const { Title } = Typography;
const { Option } = Select;

interface LinkGeneratorHeaderProps {
  plans: Plan[];
  domains: Domain[];
  affiliates: Affiliate[];
  loading: boolean;
}

export const LinkGeneratorHeader = memo<LinkGeneratorHeaderProps>(
  ({ plans, domains, affiliates, loading }) => {
    const [destinationUrl, setDestinationUrl] = useState('');
    const [selectedPlan, setSelectedPlan] = useState<string | undefined>();
    const [selectedDomain, setSelectedDomain] = useState<string | undefined>();
    const [selectedAffiliate, setSelectedAffiliate] = useState<
      string | undefined
    >();
    const [description, setDescription] = useState('');
    const [isGeneratingLink, setIsGeneratingLink] = useState(false);

    const onGenerateLink = async () => {
      if (
        !destinationUrl ||
        !selectedPlan ||
        !selectedDomain ||
        !selectedAffiliate
      ) {
        message.warning('Por favor, preencha todos os campos.');
        return;
      }

      setIsGeneratingLink(true);
      try {
        await linkGeneratorApi.generateLink(
          destinationUrl,
          selectedPlan,
          selectedDomain,
          selectedAffiliate,
          description
        );
        message.success('Link gerado com sucesso!');
      } catch (error) {
        message.error('Erro ao gerar o link.');
      } finally {
        setIsGeneratingLink(false);
      }
    };

    return (
      <>
        <Title level={3}>Gerador de links</Title>

        <Divider style={{ marginBlock: '40px' }} />

        <Row gutter={16} style={{ marginBottom: '16px' }}>
          <Col span={12}>
            <Input
              placeholder='Cole aqui seu link de destino'
              value={destinationUrl}
              onChange={e => setDestinationUrl(e.target.value)}
            />
          </Col>
          <Col span={12}>
            <Select
              placeholder='Selecione um plano'
              style={{ width: '100%' }}
              value={selectedPlan}
              onChange={value => setSelectedPlan(value)}
              loading={loading}
            >
              {plans.map(plan => (
                <Option key={plan.id} value={plan.id}>
                  {`ID:${plan.id} - ${plan.name}`}
                </Option>
              ))}
            </Select>
          </Col>
        </Row>
        <Row gutter={16} style={{ marginBottom: '16px' }}>
          <Col span={12}>
            <Select
              placeholder='Selecione um domínio'
              style={{ width: '100%' }}
              value={selectedDomain}
              onChange={value => setSelectedDomain(value)}
              loading={loading}
            >
              {domains.map(domain => (
                <Option key={domain.id} value={domain.id}>
                  {domain.domain}
                </Option>
              ))}
            </Select>
          </Col>
          <Col span={12}>
            <Select
              placeholder='Selecione um colaborador'
              style={{ width: '100%' }}
              value={selectedAffiliate}
              onChange={value => setSelectedAffiliate(value)}
              loading={loading}
            >
              {affiliates.map(affiliate => (
                <Option key={affiliate.id} value={affiliate.id}>
                  {affiliate.description}
                </Option>
              ))}
            </Select>
          </Col>
        </Row>
        <Row style={{ marginBottom: '24px' }}>
          <Col span={24}>
            <Input
              placeholder='Digite uma descrição'
              value={description}
              onChange={e => setDescription(e.target.value)}
            />
          </Col>
        </Row>
        <Row justify='end'>
          <Button
            type='primary'
            onClick={onGenerateLink}
            loading={isGeneratingLink}
          >
            Gerar link
          </Button>
        </Row>
      </>
    );
  }
);
