import {
  EditOutlined,
  EyeInvisibleOutlined,
  MoreOutlined,
} from '@ant-design/icons';
import { Button, Dropdown, MenuProps, Typography } from 'antd';
import { FC } from 'react';
import { useQueryClient } from 'react-query';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import { programsApi } from '../api/programsApi';
import { showConfirmModal, showInfoModal } from '../utils/modalHelpers';

const { Text } = Typography;

interface ProgramsTableActionsProps {
  programId: number;
  programName: string;
  isPublished: boolean;
}

export const ProgramsTableActions: FC<ProgramsTableActionsProps> = ({
  programId,
  programName,
  isPublished,
}) => {
  const history = useHistory();
  const queryClient = useQueryClient();

  const handleEditProgram = async () => {
    history.push(`/programs/create-or-update/${programId}`);
  };

  const handleUnpublishProgram = async () => {
    try {
      await programsApi.unpublishProgram(programId);
      showInfoModal({
        title: 'Sucesso',
        content: `Programa ${programName} despublicado com sucesso!`,
        afterClose: () => {
          queryClient.invalidateQueries('published-programs');
          queryClient.invalidateQueries('unpublished-programs');
        },
      });
    } catch (error) {
      showInfoModal({
        title: 'Tivemos um problema...',
        content: 'Não foi possível despublicar o programa, tente novamente.',
      });
    }
  };

  const confirmUnpublishProgram = async () => {
    showConfirmModal({
      title: 'Despublicar programa',
      okText: 'Despublicar',
      cancelText: 'Cancelar',
      content: (
        <Text>
          Você está prestes a despublicar o programa{' '}
          <Text strong>{programName}</Text>. Tem certeza que deseja prosseguir
          com a alteração?
        </Text>
      ),
      onOk: handleUnpublishProgram,
    });
  };

  const handleClick: MenuProps['onClick'] = event => {
    switch (event.key) {
      case 'EDIT':
        handleEditProgram();
        break;
      case 'UNPUBLISH':
        confirmUnpublishProgram();
        break;
      default:
        break;
    }
  };

  const items: MenuProps['items'] = [
    {
      key: 'EDIT',
      label: 'Editar',
      icon: <EditOutlined />,
    },
    isPublished
      ? {
          key: 'UNPUBLISH',
          label: `Despublicar`,
          icon: <EyeInvisibleOutlined />,
        }
      : null,
  ];

  return (
    <Dropdown
      placement='bottomRight'
      menu={{ items, onClick: handleClick }}
      trigger={['click']}
    >
      <CustomButton
        data-testid='program-menu-actions'
        style={{ border: 'none' }}
      >
        <MoreOutlined />
      </CustomButton>
    </Dropdown>
  );
};

const CustomButton = styled(Button)`
  &:hover {
    background-color: rgba(62, 62, 62, 0.7);
    color: white;
  }
`;
