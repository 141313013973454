import { ChartOptions, Plugin } from 'chart.js';
import React from 'react';
import { Bar } from 'react-chartjs-2';

interface CustomHorizontalBarChartProps {
  labels: string[];
  label: string;
  dataValues: number[];
  backgroundColor: string;
}

export const CustomHorizontalBarChart: React.FC<CustomHorizontalBarChartProps> =
  ({ labels, dataValues, backgroundColor, label }) => {
    const data = {
      labels: labels,
      datasets: [
        {
          label: label,
          data: dataValues,
          backgroundColor: backgroundColor,
        },
      ],
    };

    let maxYValue = Math.ceil(Math.max(...dataValues) * 1.25);
    if (maxYValue % 2 !== 0) {
      maxYValue += 1;
    }

    const options: ChartOptions<'bar'> = {
      indexAxis: 'y',
      scales: {
        x: {
          beginAtZero: true,
          max: maxYValue,
          grid: {
            color: 'rgba(217, 217, 255, 0.25)',
            lineWidth: 1,
            borderDash: [3, 3],
          },
          ticks: {
            color: 'rgba(255, 255, 255, 0.8)',
          },
        },
        y: {
          beginAtZero: true,
          grid: {
            color: 'rgba(217, 217, 255, 0.25)',
            lineWidth: 1,
            borderDash: [3, 3],
          },
          ticks: {
            color: 'rgba(255, 255, 255, 0.8)',
          },
        },
      },
      plugins: {
        legend: {
          display: true,
          position: 'bottom',
        },
      },
    };

    const textInsideBarPlugin: Plugin = {
      id: 'textInsideBarPlugin',
      afterDatasetsDraw(chart) {
        const { ctx, data } = chart;
        ctx.save();
        data.datasets.forEach((dataset, i) => {
          const meta = chart.getDatasetMeta(i);
          meta.data.forEach((bar, index) => {
            const value = dataset.data[index] as number;
            ctx.fillStyle = 'white';
            ctx.font = '400 14px sans-serif';
            ctx.textAlign = 'center';
            ctx.textBaseline = 'middle';
            ctx.fillText(value.toString(), bar.x - 10, bar.y + 5 / 2);
          });
        });
        ctx.restore();
      },
    };

    return (
      <Bar data={data} options={options} plugins={[textInsideBarPlugin]} />
    );
  };
