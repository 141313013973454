import { PlusOutlined } from '@ant-design/icons';
import { Button, Divider, Drawer, Input, Row, Space, Typography } from 'antd';
import { RcFile } from 'antd/lib/upload';
import { FC, useState } from 'react';
import { useQueryClient } from 'react-query';

import { programsApi } from 'src/features/programs/api/programsApi';
import { showInfoModal } from 'src/features/programs/utils/modalHelpers';

import { CreateInstructorAvatar } from './CreateInstructorAvatar';

const { Title, Text } = Typography;
const { TextArea } = Input;

interface CreateInstructorProps {
  programId?: number;
}

export const CreateInstructor: FC<CreateInstructorProps> = ({ programId }) => {
  const queryClient = useQueryClient();

  const [open, setOpen] = useState(false);
  const [avatar, setAvatar] = useState<RcFile | null>(null);
  const [avatarKey, setAvatarKey] = useState(0);
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [lastInstructorId, setLastInstructorId] = useState<number | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  const isDisabled = !name || !description;
  const formLength = name.length + description.length;

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);

    setTimeout(() => {
      setName('');
      setDescription('');
      setAvatar(null);
      setAvatarKey(avatarKey + 1);
      setLastInstructorId(null);
    }, 150);
  };

  const handleFinish = () => {
    queryClient.resetQueries(['program-details-options']);
    onClose();
  };

  const handleInstructorAvatarError = () => {
    return showInfoModal({
      title: 'Tivemos um problema...',
      content:
        'Não foi possível salvar a imagem, entretanto o cadastro do treinador foi realizado. Vocé pode tentar enviar novamente ou editar posteriormente o cadastro do treinador.',
      afterClose: handleFinish,
    });
  };

  const handlePatchInstructorAvatar = async (instructorId: number) => {
    if (!programId) return handleInstructorAvatarError();

    const file = new FormData();
    file.append('linkImage', avatar as File);

    try {
      await programsApi.patchInstructorAvatar(file, instructorId, programId);
    } catch (err) {
      return handleInstructorAvatarError();
    }
  };

  const handleCreateInstructor = async () => {
    setIsLoading(true);

    if (lastInstructorId) {
      await handlePatchInstructorAvatar(lastInstructorId);
      setIsLoading(false);
      return;
    }

    try {
      const data = await programsApi.createInstructor(name, description);
      if (avatar) {
        setLastInstructorId(data.id);
        const errorModal = await handlePatchInstructorAvatar(data.id);
        if (errorModal) return;
      }
      showInfoModal({
        title: 'Sucesso',
        content: 'Treinador cadastrado com sucesso!',
        afterClose: handleFinish,
      });
    } catch (err) {
      showInfoModal({
        title: 'Tivemos um problema...',
        content: 'Não foi possível cadastrar o treinador, tente novamente.',
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <Button
        type='link'
        icon={<PlusOutlined />}
        style={{ marginTop: '8px', marginLeft: 'auto' }}
        onClick={showDrawer}
      >
        Adicionar treinador(a)
      </Button>

      <Drawer
        title='Adicionar treinador(a)'
        width={700}
        onClose={onClose}
        open={open}
        bodyStyle={{ paddingBottom: 80 }}
      >
        <Title level={4}>Bio</Title>

        <CreateInstructorAvatar key={avatarKey} onChange={setAvatar} />

        <Divider />

        <Text
          type='secondary'
          style={{ display: 'inline-block', marginBottom: '4px' }}
        >
          Nome*
        </Text>
        <Input
          placeholder='Nome do(a) treinador(a)'
          value={name}
          onChange={event => setName(event.target.value)}
          maxLength={formLength === 300 ? name.length : undefined}
          style={{ marginBottom: '16px' }}
        />

        <Text
          type='secondary'
          style={{ display: 'inline-block', marginBottom: '4px' }}
        >
          Descrição*
        </Text>
        <TextArea
          placeholder='Descrição do(a) treinador(a)'
          value={description}
          onChange={event => setDescription(event.target.value)}
          maxLength={formLength >= 300 ? description.length : undefined}
          rows={5}
          style={{ marginBottom: '4px' }}
        />
        <Row justify='end'>
          <Text type='secondary'>{formLength}/300 caracteres</Text>
        </Row>

        <Divider />

        <Space style={{ width: '100%', justifyContent: 'flex-end' }}>
          <Button onClick={onClose}>Cancelar</Button>
          <Button
            onClick={handleCreateInstructor}
            type='primary'
            loading={isLoading}
            disabled={isDisabled}
          >
            Salvar
          </Button>
        </Space>
      </Drawer>
    </>
  );
};
