import { Button, Divider, Layout, Space, Timeline, Typography } from 'antd';
import { FC, useState } from 'react';
import styled from 'styled-components';

import { useHistory } from '../../hooks/useHistory';
import { ICustomerHistory } from '../../types/customer';
import { dateUtils } from '../../utils/dates';

const { Sider, Content } = Layout;
const { Text, Title } = Typography;

interface CustomerHistoryProps {
  customerId: string;
}

export const CustomerHistory: FC<CustomerHistoryProps> = ({ customerId }) => {
  const { data } = useHistory(customerId);
  const [selectedContent, setSelectedContent] =
    useState<ICustomerHistory | null>(null);

  function renderAccessValue(actionName: string) {
    if (actionName === 'giveAccessToProfile') {
      return selectedContent?.newValue;
    }
    if (actionName === 'giveAccessToProfile') {
      return selectedContent?.newValue;
    }
    return 'N/A';
  }
  return (
    <StyledLayout>
      <StyledSider width={480}>
        <Title level={5} style={{ marginBottom: '24px' }}>
          Histórico de Interações
        </Title>

        <Timeline mode='left'>
          {data?.map(item => (
            <Timeline.Item
              key={item.id}
              color={selectedContent?.id === item.id ? '#1890ff' : '#FFFFFF40'}
            >
              <Button
                type='text'
                onClick={() => setSelectedContent(item)}
                style={{
                  padding: 0,
                  textAlign: 'left',
                }}
              >
                <div>
                  <Text strong>{item.action.title}</Text>
                  <br />
                  <Text type='secondary' style={{ fontSize: 14 }}>
                    {dateUtils.formatDateString(item.createdAt, 'dd MMM HH:mm')}
                  </Text>
                </div>
              </Button>
            </Timeline.Item>
          ))}
        </Timeline>
      </StyledSider>

      <Divider
        type='vertical'
        style={{ height: 'auto', alignSelf: 'stretch' }}
      />
      {selectedContent && (
        <StyledContent>
          <Space direction='vertical' style={{ width: '100%' }}>
            <>
              <Space direction='vertical'>
                <Text strong>Analista responsável:</Text>
                <Text>{selectedContent.author.fullName}</Text>
              </Space>
              <Divider />
              <StyledSpace direction='vertical'>
                <Text strong>Ação:</Text>
                <Text>{selectedContent.action.title}</Text>
              </StyledSpace>
              <StyledSpace direction='vertical'>
                <Text strong>Motivo da ação:</Text>
                <Text>
                  {selectedContent.reason ? selectedContent.reason : 'N/A'}
                </Text>
              </StyledSpace>
              {/* <StyledSpace direction='vertical'>
              <Text strong>Assinaturas Removidas:</Text>
              {Array.isArray(selectedContent.details.removedSubscription) &&
              selectedContent.details.removedSubscription.length > 0 ? (
                <ul>
                  {selectedContent.details.removedSubscription.map(
                    (item, index) => (
                      <li key={index}>{item}</li>
                    )
                  )}
                </ul>
              ) : (
                <Text>
                  {selectedContent.details.removedSubscription || 'N/A'}
                </Text>
              )}
            </StyledSpace> */}
              <StyledSpace direction='vertical'>
                <Text strong>Acesso:</Text>
                <Text>{renderAccessValue(selectedContent.action.name)}</Text>
              </StyledSpace>
              <StyledSpace direction='vertical'>
                <Text strong>Gerou link de Redefinição de Senha:</Text>
                <Text>
                  {selectedContent.action.name === 'generatePasswordResetLink'
                    ? 'Sim'
                    : 'N/A'}
                </Text>
              </StyledSpace>
              {/* <Space direction='vertical' style={{ width: '100%' }}>
              <Text strong>Anexos:</Text>
              <Row gutter={24} justify='space-between'>
                <Col span={12}>
                  <Text>{selectedContent.details.attached}</Text>
                </Col>
                <Col span={12} style={{ textAlign: 'right' }}>
                  <Button type='link'>
                    <DownloadOutlined style={{ fontSize: '16px' }} />
                  </Button>
                </Col>
              </Row>
            </Space> */}
            </>
          </Space>
        </StyledContent>
      )}
    </StyledLayout>
  );
};

const StyledLayout = styled(Layout)`
  background: none;
  padding: 0;
  min-height: 100vh;
`;

const StyledSider = styled(Sider)`
  background: none;
  margin-top: 24px;
`;

const StyledContent = styled(Content)`
  padding-left: 32px;
  margin-top: 24px;
`;

const StyledSpace = styled(Space)`
  margin-bottom: 24px;
`;
