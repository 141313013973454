import { Segmented, SegmentedProps } from 'antd';
import React, { useCallback } from 'react';

import { SEGMENTED_PERIOD_OPTIONS } from '../constants/filters';

interface SegmentedDateRangeProps
  extends Omit<SegmentedProps, 'options' | 'ref'> {
  onChangePeriod: (dates: [string, string], labelSelected: string) => void;
  selectedValue: string;
}

export const SegmentedPeriod: React.FC<SegmentedDateRangeProps> = ({
  onChangePeriod,
  selectedValue,
  ...rest
}) => {
  const handleSegmentedChange = useCallback(
    (label: string) => {
      const selectedOption = SEGMENTED_PERIOD_OPTIONS.find(
        option => option.label === label
      );
      if (selectedOption) {
        onChangePeriod(
          [selectedOption.startDate, selectedOption.endDate],
          label
        );
      }
    },
    [onChangePeriod]
  );

  return (
    <Segmented
      options={SEGMENTED_PERIOD_OPTIONS.map(option => option.label)}
      onChange={value => handleSegmentedChange(value as string)}
      value={selectedValue}
      onResize={undefined}
      onResizeCapture={undefined}
      {...rest}
    />
  );
};
