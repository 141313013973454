import { CopyOutlined } from '@ant-design/icons';
import { Button, message, Row, Typography } from 'antd';
import { FC, useState } from 'react';

const { Text } = Typography;

interface LinkGeneratorTableActionsProps {
  link: string;
}

export const LinkGeneratorTableActions: FC<LinkGeneratorTableActionsProps> = ({
  link,
}) => {
  const [copied, setCopied] = useState(false);

  const handleCopyLink = () => {
    navigator.clipboard
      .writeText(link)
      .then(() => {
        message.success('Link copiado para a área de transferência!');
        setCopied(true);

        setTimeout(() => {
          setCopied(false);
        }, 2000);
      })
      .catch(() => {
        message.error('Falha ao copiar o link.');
      });
  };

  return (
    <Row justify='center'>
      {copied ? (
        <Text style={{ lineHeight: '32px' }}>Copiado!</Text>
      ) : (
        <Button type='text' onClick={handleCopyLink} icon={<CopyOutlined />} />
      )}
    </Row>
  );
};
