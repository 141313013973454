import { baseClient } from 'src/app/baseClient';

import {
  Affiliate,
  AffiliateLinks,
  Domain,
  LinkResponse,
  Plan,
} from '../typings/linkGenerator';

async function fetchPlans(): Promise<Plan[]> {
  const endpoint = `/transaction/plans`;
  const { data } = await baseClient.get<Plan[]>(endpoint);
  return data;
}

async function fetchDomains(): Promise<Domain[]> {
  const endpoint = `/transaction/domain-links`;
  const { data } = await baseClient.get<Domain[]>(endpoint);
  return data;
}

async function fetchAffiliates(): Promise<Affiliate[]> {
  const endpoint = `/transaction/affiliate/inside-sales`;
  const { data } = await baseClient.get<Affiliate[]>(endpoint);
  return data;
}

async function fetchAffiliateLinks(
  selectCustomer: number
): Promise<AffiliateLinks[]> {
  const endpoint = `/transaction/affiliate/link/${selectCustomer}`;
  const { data } = await baseClient.get(endpoint);
  return data;
}

async function generateLink(
  destinationUrl: string,
  planId: string,
  domainId: string,
  affiliateId: string,
  description: string
): Promise<LinkResponse> {
  const endpoint = `/transaction/affiliate/link`;
  const { data } = await baseClient.post<LinkResponse>(endpoint, {
    destinationUrl,
    percent: 0, // Always 0 for Inside Sales users
    planId: parseInt(planId, 10),
    domainId: parseInt(domainId, 10),
    affiliateId: parseInt(affiliateId, 10),
    description,
  });
  return data;
}

export const linkGeneratorApi = {
  fetchPlans,
  fetchDomains,
  fetchAffiliates,
  fetchAffiliateLinks,
  generateLink,
};
