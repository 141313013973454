import { Col, Row, Space, Typography } from 'antd';

import { UsageEngagement } from '../../types/customer';
import { dateUtils } from '../../utils/dates';
import { CustomCard } from '../CustomCard';
import { EmptyState } from '../EmptyState';

type AccessSectionProps = {
  usageEngagement: UsageEngagement;
};

const { Text, Title } = Typography;

export const AccessSection: React.FC<AccessSectionProps> = ({
  usageEngagement,
}) => {
  const { lastAccessPlatform, lastContent } = usageEngagement;
  return (
    <Space direction='vertical' size='middle' style={{ display: 'flex' }}>
      <Title level={5} style={{ margin: 0 }}>
        Acessos
      </Title>
      <Row gutter={24} justify='space-between' align={'top'}>
        <Col span={12}>
          {lastAccessPlatform ? (
            <CustomCard title='Último acesso a plataforma'>
              <Row justify='space-between' align={'middle'}>
                <Text type='secondary'>Dia</Text>
              </Row>
              <Row justify='space-between' align={'middle'}>
                <Title level={4} style={{ margin: 0 }}>
                  {dateUtils.formatDateString(lastAccessPlatform, 'dd/MM/yyyy')}
                </Title>
              </Row>
            </CustomCard>
          ) : (
            <CustomCard>
              <EmptyState
                title='Sem acessos a plataforma'
                subtitle='O Cliente selecionado não possui acessos a plataforma.'
              />
            </CustomCard>
          )}
        </Col>
        <Col span={12}>
          {lastContent ? (
            <CustomCard title='Data da realização da ultima aula'>
              <Row justify='space-between' align={'middle'}>
                <Text type='secondary'>{`${lastContent.contentName} - ${lastContent.programName}`}</Text>
              </Row>
              <Row justify='space-between' align={'middle'}>
                <Title level={4} style={{ margin: 0 }}>
                  {dateUtils.formatDateString(lastContent.date, 'dd/MM/yyyy')}
                </Title>
              </Row>
            </CustomCard>
          ) : (
            <CustomCard>
              <EmptyState
                title='Sem realizações de aula'
                subtitle='O Cliente selecionado não realizou nenhuma aula.'
              />
            </CustomCard>
          )}
        </Col>
      </Row>
    </Space>
  );
};
