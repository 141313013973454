import { DatePicker } from 'antd';
import { RangeValue } from 'rc-picker/lib/interface';

export type DateRangePickerProps = {
  onSelectDate: (dates: [string, string]) => void;
} & Omit<React.ComponentProps<typeof DatePicker.RangePicker>, 'onChange'>;

const { RangePicker } = DatePicker;

export const DateRangePicker = ({
  onSelectDate,
  ...rest
}: DateRangePickerProps) => {
  const handleDateChange = (dates: RangeValue<moment.Moment>) => {
    if (dates && dates[0] && dates[1]) {
      const formattedDates: [string, string] = [
        dates[0].format('YYYY-MM-DD'),
        dates[1].format('YYYY-MM-DD'),
      ];
      onSelectDate(formattedDates);
    }
  };

  return (
    <RangePicker format='DD/MM/YYYY' onChange={handleDateChange} {...rest} />
  );
};
