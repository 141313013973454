import { DownloadOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { Button, Space, Typography, Upload } from 'antd';
import { FC } from 'react';
import { useFormContext } from 'react-hook-form';
import styled from 'styled-components';

import { env } from 'src/app/env';
import { DesignStepForm } from 'src/features/programs/schemas/designStepSchema';

import { ProgramFormItem } from '../../ProgramFormItem';

const { Text } = Typography;

interface ImageUploadProps {
  title: string;
  helperText?: string;
  fieldName: string;
  templateHref?: string;
}

export const ImageUpload: FC<ImageUploadProps> = ({
  title,
  helperText,
  fieldName,
  templateHref,
}) => {
  const { control, watch } = useFormContext<DesignStepForm>();

  const file = watch(`${fieldName}.file` as any);
  const defaultImage = watch(`${fieldName}.preview` as any);

  const fileUrl = file && URL.createObjectURL(file);
  const previewImage =
    fileUrl || (defaultImage && `${env.ASSETS}${defaultImage}`);

  return (
    <ProgramFormItem
      control={control}
      name={`${fieldName}.file`}
      renderItem={({ field: { onChange, ref } }) => (
        <Space direction='horizontal' size={16}>
          <Upload
            showUploadList={false}
            onChange={info => onChange(info.file.originFileObj)}
            accept='image/png'
          >
            <UploadTrigger ref={ref}>
              {previewImage ? (
                <img src={previewImage} alt='Preview da imagem' />
              ) : (
                <Text>Adicionar imagem</Text>
              )}
            </UploadTrigger>
          </Upload>

          <Space
            direction='vertical'
            size={24}
            style={{ alignSelf: 'flex-start' }}
          >
            <Text strong>{title}</Text>
            {Boolean(helperText) && (
              <Space
                direction='horizontal'
                size={8}
                align='start'
                style={{ paddingTop: '4px' }}
              >
                <QuestionCircleOutlined
                  style={{ fontSize: '20px', marginTop: '1px' }}
                />
                <Text type='secondary' style={{ maxWidth: '60%' }}>
                  {helperText}
                </Text>
              </Space>
            )}
            {templateHref && (
              <Button
                type='link'
                icon={<DownloadOutlined />}
                style={{ padding: 0 }}
                href={templateHref}
              >
                Baixar template
              </Button>
            )}
          </Space>
        </Space>
      )}
    />
  );
};

const UploadTrigger = styled(Button)`
  width: 170px;
  height: 170px;
  background-color: #262626;
  padding: 0;

  img {
    width: 160px;
    height: calc(100% - 10px);
    object-fit: contain;
  }
`;
