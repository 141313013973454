import { slices } from '@core/redux';
import { yupResolver } from '@hookform/resolvers/yup';
import { Form, Space, Typography } from 'antd';
import { FC, useEffect, useState } from 'react';
import { FormProvider, SubmitErrorHandler, useForm } from 'react-hook-form';
import { useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { programDetailsApi } from 'src/features/programs/api/programDetailsApi';
import { programsApi } from 'src/features/programs/api/programsApi';
import { useInformationStepData } from 'src/features/programs/hooks/useInformationStepData';
import {
  InformationStepForm,
  informationStepSchema,
} from 'src/features/programs/schemas/informationStepSchema';
import { CreateOrUpdateProgramBaseInfo } from 'src/features/programs/types/program';
import { CreateOrUpdateProgramDetails } from 'src/features/programs/types/programDetails';
import { getProgramBaseInfoFromForm } from 'src/features/programs/utils/getProgramBaseInfoFromForm';
import { getProgramDetailsFromForm } from 'src/features/programs/utils/getProgramDetailsFromForm';
import { showInfoModal } from 'src/features/programs/utils/modalHelpers';

import { ProgramName } from '../../ProgramName';
import { ProgramStepFooter } from '../../ProgramStepFooter';
import { ProgramCopy } from './ProgramCopy';
import { ProgramDetails } from './ProgramDetails';
import { ProgramHowItWorks } from './ProgramHowItWorks';
import { ProgramVideoLink } from './ProgramVideoLink';

const { Text, Paragraph } = Typography;

interface CreateOrUpdateProgram {
  detailsPayload: CreateOrUpdateProgramDetails;
  baseInfoPayload: CreateOrUpdateProgramBaseInfo;
}

interface InformationStepProps {
  programId?: number;
}

export const InformationStep: FC<InformationStepProps> = ({ programId }) => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const history = useHistory();

  const [howItWorksKey, setHowItWorksKey] = useState(new Date().toISOString());
  const { formValues, isLoading } = useInformationStepData(programId);

  const form = useForm<InformationStepForm>({
    resolver: yupResolver(informationStepSchema),
    values: formValues,
  });

  useEffect(() => {
    if (isLoading) {
      dispatch(slices.layout.increaseLoading());
    } else {
      dispatch(slices.layout.decreaseLoading());
    }
  }, [isLoading]);

  const resetStepQueries = () => {
    queryClient.resetQueries(['program-contents', programId]);
    queryClient.resetQueries(['program-details', programId]);
  };

  const handleCreateProgram = async (payload: CreateOrUpdateProgram) => {
    try {
      dispatch(slices.layout.increaseLoading());
      const { id } = await programsApi.createProgram(payload.baseInfoPayload);
      await programDetailsApi.createProgramDetails(id, payload.detailsPayload);
      showInfoModal({
        title: 'Sucesso',
        content: 'Programa criado com sucesso!',
        afterClose: () => {
          history.replace(`/programs/create-or-update/${id}`);
        },
      });
    } catch (error) {
      showInfoModal({
        title: 'Tivemos um problema...',
        content: (
          <>
            <Paragraph>Faça algumas verificações nos dados:</Paragraph>
            <Text>
              1 - Verifique se já existe um programa com o mesmo nome.
            </Text>
            <br />
            <Text>
              2 - Verifique se o ID do vídeo está correto e se já está publicado
              na plataforma de streaming.
            </Text>
          </>
        ),
      });
    } finally {
      dispatch(slices.layout.decreaseLoading());
    }
  };

  const handleUpdateProgram = async (payload: CreateOrUpdateProgram) => {
    try {
      dispatch(slices.layout.increaseLoading());
      await Promise.all([
        programsApi.updateProgram(programId as number, payload.baseInfoPayload),
        programDetailsApi.updateProgramDetails(
          programId as number,
          payload.detailsPayload
        ),
      ]);
      showInfoModal({
        title: 'Sucesso',
        content: 'Dados do programa atualizados com sucesso!',
        afterClose: resetStepQueries,
      });
    } catch (error) {
      showInfoModal({
        title: 'Tivemos um problema...',
        content:
          'Não foi possível atualizar os dados do programa, tente novamente.',
      });
    } finally {
      dispatch(slices.layout.decreaseLoading());
    }
  };

  const onSubmit = (fields: InformationStepForm) => {
    const detailsPayload = getProgramDetailsFromForm(fields, programId);
    const baseInfoPayload = getProgramBaseInfoFromForm(fields);

    const data = { detailsPayload, baseInfoPayload };

    programId ? handleUpdateProgram(data) : handleCreateProgram(data);
  };

  const onError: SubmitErrorHandler<InformationStepForm> = errors => {
    const errorField = Object.keys(errors)[0];
    document
      .getElementsByName(errorField)[0]
      .scrollIntoView?.({ block: 'center' });
  };

  const resetForm = () => {
    if (programId) {
      resetStepQueries();
    } else {
      form.reset();
      setHowItWorksKey(new Date().toISOString());
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  };

  return (
    <FormProvider {...form}>
      <Form layout='vertical' onFinish={form.handleSubmit(onSubmit, onError)}>
        <Space direction='vertical' size={24} style={{ width: '100%' }}>
          <ProgramName />
          <ProgramDetails programId={programId} />
          <ProgramHowItWorks key={howItWorksKey} />
          <ProgramCopy />
          <ProgramVideoLink />
        </Space>
        <ProgramStepFooter
          programId={programId}
          onCancelConfirmation={resetForm}
        />
      </Form>
    </FormProvider>
  );
};
