import { baseClient } from 'src/app/baseClient';

import { ModalRedirect, userTypes } from '../typings/modalRedirect';

async function fetchRedirects() {
  const { data } = await baseClient.get<ModalRedirect[]>(
    '/user/freemiumRedirect',
    {
      params: {
        filter: {
          order: 'createdAt desc',
        },
      },
    }
  );

  // Filter to only show userType "new-user" links and referrerUrl is not null
  return data.filter(
    item => item.userType === userTypes.NEW_USER && item.referrerUrl !== null
  );
}

async function addRedirect(referrerUrl: string, redirect: string) {
  const { data } = await baseClient.post<ModalRedirect>(
    '/user/freemiumRedirect',
    {
      referrerUrl,
      redirect,
      userType: userTypes.NEW_USER,
    }
  );

  // Also create "active-user" and "inactive-user" but don't show
  await Promise.all([
    baseClient.post('/user/freemiumRedirect', {
      referrerUrl,
      redirect,
      userType: userTypes.ACTIVE_USER,
    }),
    baseClient.post('/user/freemiumRedirect', {
      referrerUrl,
      redirect,
      userType: userTypes.INACTIVE_USER,
    }),
  ]);

  return data;
}

async function removeRedirect(id: number) {
  // Remove userType "new-user" and its consecutively "active-user" and "inactive-user"
  await Promise.all([
    baseClient.delete(`/user/freemiumRedirect/${id}`),
    baseClient.delete(`/user/freemiumRedirect/${id + 1}`),
    baseClient.delete(`/user/freemiumRedirect/${id + 2}`),
  ]);

  return { id };
}

export const modalRedirectApi = {
  fetchRedirects,
  addRedirect,
  removeRedirect,
};
