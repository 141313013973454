import { useQuery } from 'react-query';

import { customerApi } from '../api/customerApi';

export const useCustomerInstructorsWatched = (
  customerId: string,
  startDate: string,
  endDate: string
) => {
  const { data, isLoading, isError, refetch } = useQuery({
    queryKey: [
      'customer-most-watched-instructors',
      customerId,
      startDate,
      endDate,
    ],
    queryFn: () =>
      customerApi.fetchCustomerMostWatchedInstructors(
        customerId,
        startDate,
        endDate
      ),
    refetchOnWindowFocus: false,
    enabled: !!customerId,
  });

  const refetchOnError = () => {
    refetch();
  };

  return {
    mostWatchedInstructors: data,
    isLoading,
    isError,
    refetchOnError,
  };
};
