import { Button, Divider, Row, Space, Typography } from 'antd';
import { FC } from 'react';

import { env } from 'src/app/env';

import { showConfirmModal } from '../utils/modalHelpers';

const { Text, Link } = Typography;

interface ProgramStepFooterProps {
  programId: number | undefined;
  disabled?: boolean;
  onCancelConfirmation?: () => void;
}

const PROGRAM_BASE_URL =
  (env.BASE_URL || '').indexOf('apidev') === -1
    ? 'https://play.queimadiaria.com'
    : 'https://dev.queimadiaria.com';

export const ProgramStepFooter: FC<ProgramStepFooterProps> = ({
  programId,
  disabled,
  onCancelConfirmation,
}) => {
  const link = `${PROGRAM_BASE_URL}/program/${programId}`;

  const handleCancel = () => {
    showConfirmModal({
      title: 'Deseja realmente cancelar?',
      content: 'Com isso você perderá todos os dados não salvos.',
      onOk: onCancelConfirmation,
    });
  };

  return (
    <>
      <Divider />

      <Row>
        {!!programId && (
          <div>
            <Text strong>Visualize o programa:</Text>
            <br />
            <Link href={link} target='_blank'>
              {link}
            </Link>
          </div>
        )}

        {!disabled && (
          <Space style={{ marginLeft: 'auto' }}>
            <Button onClick={handleCancel}>Cancelar</Button>
            <Button type='primary' htmlType='submit'>
              Salvar
            </Button>
          </Space>
        )}
      </Row>
    </>
  );
};
