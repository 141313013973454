import { message } from 'antd';
import { useEffect, useState } from 'react';

import { linkGeneratorApi } from '../api/linkGeneratorApi';
import {
  Affiliate,
  AffiliateLinks,
  Domain,
  Plan,
} from '../typings/linkGenerator';

export const useLinkGeneratorData = (filterByAffiliate: number) => {
  const [plans, setPlans] = useState<Plan[]>([]);
  const [domains, setDomains] = useState<Domain[]>([]);
  const [affiliates, setAffiliates] = useState<Affiliate[]>([]);
  const [loading, setLoading] = useState(false);
  const [affiliateLinks, setAffiliateLinks] = useState<AffiliateLinks[]>([]);
  const [filterLoading, setFilterLoading] = useState(false);

  const fetchInitialData = async () => {
    setLoading(true);
    try {
      const [fetchedPlans, fetchedDomains, fetchedAffiliate] =
        await Promise.all([
          linkGeneratorApi.fetchPlans(),
          linkGeneratorApi.fetchDomains(),
          linkGeneratorApi.fetchAffiliates(),
        ]);

      setPlans(fetchedPlans);
      setDomains(fetchedDomains);
      setAffiliates(fetchedAffiliate);
    } catch (error) {
      message.error('Erro ao buscar dados iniciais.');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const fetchFilteredLinks = async () => {
      setFilterLoading(true);
      try {
        const filteredLinks = await linkGeneratorApi.fetchAffiliateLinks(
          filterByAffiliate
        );
        setAffiliateLinks(filteredLinks);
      } catch (error) {
        message.error('Erro ao buscar links filtrados.');
      } finally {
        setFilterLoading(false);
      }
    };

    fetchFilteredLinks();
  }, [filterByAffiliate]);

  useEffect(() => {
    fetchInitialData();
  }, []);

  return {
    plans,
    domains,
    affiliates,
    loading,
    affiliateLinks,
    filterLoading,
  };
};
