import * as yup from 'yup';

const REQUIRED_MESSAGE = 'Por favor, preencha este campo';

export const publishStepSchema = yup.object({
  name: yup.string(),
  publish: yup
    .string()
    .required('Selecione uma das duas opções disponiveis para continuar'),
  date: yup
    .date()
    .nullable()
    .when('publish', {
      is: 'scheduled',
      then: yup.date().required(REQUIRED_MESSAGE),
    }),
  time: yup
    .date()
    .nullable()
    .when('publish', {
      is: 'scheduled',
      then: yup.date().required(REQUIRED_MESSAGE),
    }),
});

export type PublishStepForm = yup.InferType<typeof publishStepSchema>;
