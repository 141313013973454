import { Row } from 'antd';
import React from 'react';

import { CommunicationCarousel } from './CommunicationCarousel';
import { CommunicationLastEmail } from './CommunicationLastEmail';

interface EmailsSummaryProps {
  customerId: string;
}

export const EmailsSummary: React.FC<EmailsSummaryProps> = ({ customerId }) => {
  return (
    <Row gutter={16}>
      <CommunicationLastEmail customerId={customerId} />
      <CommunicationCarousel customerId={customerId} />
    </Row>
  );
};
