import * as yup from 'yup';

const REQUIRED_MESSAGE = 'Por favor, preencha este campo';

const baseImageFieldSchema = yup.object({
  preview: yup.string().nullable(),
  file: yup.mixed(),
});

const requiredImageFieldSchema = yup.object({
  preview: yup.string(),
  file: yup.mixed().when('preview', {
    is: '',
    then: yup.mixed().required(REQUIRED_MESSAGE),
  }),
});

export const designStepSchema = yup.object({
  name: yup.string(),
  primaryColor: yup.string().required(REQUIRED_MESSAGE),
  imageCard: requiredImageFieldSchema,
  imageCardWeb: requiredImageFieldSchema,
  imageVertical: requiredImageFieldSchema,
  imageLogo: requiredImageFieldSchema,
  thumbPdf: baseImageFieldSchema,
  bannerHighlight: yup.object({
    tablet: requiredImageFieldSchema,
    mobile: requiredImageFieldSchema,
  }),
  bannerSuper: yup.object({
    tv: requiredImageFieldSchema,
    tablet: requiredImageFieldSchema,
    mobile: requiredImageFieldSchema,
  }),
  bannerOnboarding: yup.object({
    tv: baseImageFieldSchema,
    mobile: baseImageFieldSchema,
  }),
});

export type DesignStepForm = yup.InferType<typeof designStepSchema>;
