import { baseClient } from 'src/app/baseClient';
import { SegmentsDto } from 'src/models/Segments.model';

type Segments = Omit<SegmentsDto, 'orderingForBanners' | 'activated'>;

async function fetchSegments() {
  const { data } = await baseClient.get<{ segments: Segments[] }>(`/segments`);
  return data;
}

export const segmentsApi = {
  fetchSegments,
};
