import { TableComponent } from '@atoms';
import { FilterHeader } from '@organisms';
import { Divider, Input } from 'antd';
import { FC, useMemo, useState } from 'react';
import styled from 'styled-components';

import { dateFormatted } from 'src/app/helpers/DateHelper';
import { useBreadcrumbs } from 'src/features/layout/hooks/useBreadcrumbs';

import { LinkShortnerHeader } from '../components/LinkShortnerHeader';
import { LinkShortnerTableActions } from '../components/LinkShortnerTableActions';
import { useShortenedLinks } from '../hooks/useShortenedLinks';
import { ShortenedLink } from '../typings/linkShortner';
import { withLinkShortnerPrefix } from '../utils/withLinkShortnerPrefix';

export const LinkShortner: FC = () => {
  useBreadcrumbs([
    {
      label: 'Dashboard',
      route: '/dashboard',
    },
    {
      label: 'Encurtador de Links',
    },
  ]);

  const [searchParam, setSearchParam] = useState('');

  const { data, isLoading, currentPage, handleUpdatePage } =
    useShortenedLinks(searchParam);

  const linkShortnerColumns = useMemo(
    () => [
      {
        title: 'Link encurtado',
        dataIndex: 'slug',
        render: (slug: string) => {
          return withLinkShortnerPrefix(slug);
        },
      },
      {
        title: 'Link destino',
        dataIndex: 'destination',
        render: (destination: string) => {
          return <DestinationWrapper>{destination}</DestinationWrapper>;
        },
      },
      {
        title: 'Descrição',
        dataIndex: 'description',
      },
      {
        title: 'Criado em',
        dataIndex: 'createdAt',
        render: (createdAt: string) => {
          return dateFormatted(createdAt, false);
        },
      },
      {
        title: 'Ações',
        dataIndex: 'link',
        align: 'center' as const,
        render: (_: any, rowData: ShortenedLink) => (
          <LinkShortnerTableActions
            linkId={rowData.id}
            link={withLinkShortnerPrefix(rowData.slug)}
          />
        ),
      },
    ],
    []
  );

  return (
    <>
      <LinkShortnerHeader />

      <Divider style={{ marginBlock: '40px' }} />

      <FilterContainer>
        <FilterHeader
          customFilter={[
            <Input.Search
              loading={isLoading}
              placeholder={'Busque pela descrição do link desejado'}
              value={searchParam}
              onChange={event => setSearchParam(event.target.value)}
              style={{ width: '360px' }}
              allowClear
            />,
          ]}
          buttonList={[]}
        />
      </FilterContainer>

      <TableComponent
        pageSize={10}
        data-testid='shortened-links-table'
        columns={linkShortnerColumns}
        data={data}
        total={99999}
        loading={isLoading}
        currentPage={currentPage}
        onChangePage={handleUpdatePage}
        showSizeChanger={false}
      />
    </>
  );
};

const DestinationWrapper = styled.div`
  display: inline-block;
  max-width: 300px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const FilterContainer = styled.div`
  margin-bottom: 24px;
`;
