import { SearchOutlined } from '@ant-design/icons';
import { Col, DatePicker, Form, Input, Select } from 'antd';
import { format } from 'date-fns';
import moment from 'moment';
import { RangeValue } from 'rc-picker/lib/interface';
import { FC } from 'react';

import {
  EMAIL_STATUS_LABEL,
  EmailStatusEnum,
} from '../../constants/communication';

const { Option } = Select;

interface FilterCustomerCommunicationProps {
  subject: string;
  setSubject: (value: string) => void;
  setStatusSelected: (value?: string) => void;
  onSelectRangeDates: (rangeSelected: string[]) => void;
}

export const FilterCustomerCommunication: FC<FilterCustomerCommunicationProps> =
  ({ subject, setSubject, setStatusSelected, onSelectRangeDates }) => {
    const handleSelectDatePicker = (dates: RangeValue<moment.Moment>) => {
      if (dates && dates[0] && dates[1]) {
        const rangeDates = [
          dates[0].format('YYYY-MM-DD'),
          dates[1].format('YYYY-MM-DD'),
        ];
        onSelectRangeDates(rangeDates);
      } else {
        onSelectRangeDates([]);
      }
    };

    return (
      <Form layout='inline'>
        <Col span={12}>
          <Form.Item>
            <Input
              data-testid='input-subject'
              onChange={e => setSubject(e.target.value)}
              value={subject}
              placeholder='Assunto do e-mail'
              suffix={<SearchOutlined />}
            />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item style={{ display: 'contents !important' }}>
            <DatePicker.RangePicker
              style={{ width: '100%' }}
              format={value => format(value.toDate(), 'dd/MM/yyyy')}
              onChange={dates => handleSelectDatePicker(dates)}
            />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Select
            placeholder='Filtrar status'
            allowClear
            onChange={value => setStatusSelected(value?.toString())}
            style={{ width: '100%' }}
            data-testid='select'
          >
            <Option value={EmailStatusEnum.DELIVERY}>
              {EMAIL_STATUS_LABEL.delivery}
            </Option>
            <Option value={EmailStatusEnum.OPEN}>
              {EMAIL_STATUS_LABEL.open}
            </Option>
            <Option value={EmailStatusEnum.CLICK}>
              {EMAIL_STATUS_LABEL.click}
            </Option>
          </Select>
        </Col>
      </Form>
    );
  };
