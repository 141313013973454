import { Button, Col, Typography } from 'antd';
import { FC } from 'react';

import { ProgramSection } from '../../ui/ProgramSection';
import { ProgramSectionGroup } from '../../ui/ProgramSectionGroup';

const { Text } = Typography;

interface BonusCardProps {
  bonusesCount: number;
  openEditBonuses: () => void;
}

export const BonusCard: FC<BonusCardProps> = ({
  bonusesCount,
  openEditBonuses,
}) => {
  return (
    <ProgramSection>
      <ProgramSectionGroup isHeader>Bônus</ProgramSectionGroup>
      <ProgramSectionGroup>
        <Col style={{ flex: 1 }}>
          <Text type='secondary' style={{ lineHeight: '32px' }}>
            {bonusesCount} subseções
          </Text>
        </Col>
        <Col>
          <Button type='link' onClick={openEditBonuses}>
            Editar
          </Button>
        </Col>
      </ProgramSectionGroup>
    </ProgramSection>
  );
};
