import { Tag } from 'antd';
import { FC } from 'react';
import { useQuery } from 'react-query';

import { programsApi } from 'src/features/programs/api/programsApi';

interface SpallaStatusProps {
  spallaId: string | undefined;
  fetchEnabled?: boolean;
}

export const SpallaStatus: FC<SpallaStatusProps> = ({
  spallaId,
  fetchEnabled = true,
}) => {
  const { data } = useQuery(
    ['spalla', spallaId],
    () => programsApi.fetchSpallaStatus(spallaId as string),
    {
      enabled: fetchEnabled && !!spallaId,
      refetchOnWindowFocus: false,
    }
  );

  if (!data) return null;

  const { status } = data;

  return (
    <Tag
      style={{ position: 'absolute', top: -2, right: 0, margin: 0 }}
      color={status === 'Publicado' ? 'success' : 'warning'}
    >
      {status}
    </Tag>
  );
};
