import { Skeleton, Space, Typography } from 'antd';
import { FC, useEffect } from 'react';
import {
  Controller,
  ControllerProps,
  get,
  useFormContext,
} from 'react-hook-form';

const { Text } = Typography;

interface ProgramFormItemProps {
  label?: string;
  isLoading?: boolean;
  loadingType?: 'field' | 'options';
  unregisterOnUnmount?: boolean;
  control: ControllerProps<any>['control'];
  name: ControllerProps['name'];
  defaultValue?: ControllerProps['defaultValue'];
  renderItem: ControllerProps['render'];
}

export const ProgramFormItem: FC<ProgramFormItemProps> = ({
  label,
  isLoading,
  loadingType = 'field',
  unregisterOnUnmount,
  control,
  name,
  defaultValue,
  renderItem,
}) => {
  const {
    unregister,
    formState: { errors },
  } = useFormContext();

  useEffect(() => {
    return () => {
      if (unregisterOnUnmount) {
        unregister(name);
      }
    };
  }, [unregisterOnUnmount, name]);

  const loading = {
    field: <Skeleton.Input active block />,
    options: <OptionsSkeleton />,
  };

  const error = get(errors, name);

  return (
    <Space direction='vertical' size={4} style={{ width: '100%' }}>
      {!!label && <Text type='secondary'>{label}</Text>}
      {isLoading ? (
        loading[loadingType]
      ) : (
        <Controller
          control={control}
          name={name}
          defaultValue={defaultValue}
          render={renderItem}
        />
      )}
      {!!error && <Text type='danger'>{error.message}</Text>}
    </Space>
  );
};

const OptionsSkeleton: FC = () => {
  return (
    <Space style={{ width: '100%' }}>
      {new Array(6).fill(0).map((_, index) => (
        <Skeleton.Button active key={index} style={{ width: '100px' }} />
      ))}
    </Space>
  );
};
