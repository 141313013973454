import { DesignStepForm } from '../schemas/designStepSchema';

export const getProgramDesignFromForm = (fields: DesignStepForm) => {
  const formData = new FormData();

  if (fields.imageCardWeb.file) {
    formData.append('imageCardWeb', fields.imageCardWeb.file);
  }

  if (fields.imageVertical.file) {
    formData.append('imageVertical', fields.imageVertical.file);
  }

  if (fields.imageCard.file) {
    formData.append('imageCard', fields.imageCard.file);
  }

  if (fields.imageLogo.file) {
    formData.append('imageLogo', fields.imageLogo.file);
  }

  if (fields.thumbPdf.file) {
    formData.append('thumbPdf', fields.thumbPdf.file);
  }

  if (fields.bannerHighlight.tablet.file) {
    formData.append(
      'imageBannerTabletHighlight',
      fields.bannerHighlight.tablet.file
    );
  }

  if (fields.bannerHighlight.mobile.file) {
    formData.append(
      'imageBannerMobileHighlight',
      fields.bannerHighlight.mobile.file
    );
  }

  if (fields.bannerSuper.tv.file) {
    formData.append('imageBannerTvSuper', fields.bannerSuper.tv.file);
  }

  if (fields.bannerSuper.tablet.file) {
    formData.append('imageBannerTabletSuper', fields.bannerSuper.tablet.file);
  }

  if (fields.bannerSuper.mobile.file) {
    formData.append('imageBannerMobileSuper', fields.bannerSuper.mobile.file);
  }

  if (fields.bannerOnboarding.tv.file) {
    formData.append('imageBannerTvOnboarding', fields.bannerOnboarding.tv.file);
  }

  if (fields.bannerOnboarding.mobile.file) {
    formData.append(
      'imageBannerMobileOnboarding',
      fields.bannerOnboarding.mobile.file
    );
  }

  return formData;
};
