import { UnlockOutlined } from '@ant-design/icons';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Form, Input, Modal } from 'antd';
import { passwordStrength } from 'check-password-strength';
import { Dispatch, FC, SetStateAction } from 'react';
import { Controller, useForm } from 'react-hook-form';
import styled from 'styled-components';
import { object, ref, SchemaOf, string } from 'yup';

import OpenNotification from 'src/app/components/antd/OpenNotification';
import { toFieldStatus } from 'src/app/utils/toFieldStatus';
import { userApi } from 'src/features/user/userApi';

type ModalProps = {
  isModalVisible: boolean;
  setIsModalVisible: Dispatch<SetStateAction<boolean>>;
};

interface ChangePasswordType {
  password: string;
  newPassword: string;
  confirmNewPasswowrd: string;
}
const { Item: FormItem } = Form;
const TAMANHO_MINIMO_SENHA = 8;

const validationSchema: SchemaOf<ChangePasswordType> = object({
  confirmNewPasswowrd: string()
    .oneOf([ref('newPassword'), null], 'As senhas não correspondem!')
    .required('Por favor preencha este o campo para confirmar a senha'),
  newPassword: string()
    .min(
      TAMANHO_MINIMO_SENHA,
      'A nova senha precisa ter pelo menos 8 caracteres'
    )
    .required('Por favor preencha este o campo de Senha'),
  password: string().required('Por favor preencha este o campo de Senha'),
}).defined();

export const ModalChangePassword: FC<ModalProps> = ({
  isModalVisible,
  setIsModalVisible,
}) => {
  const [form] = Form.useForm();

  const { control, handleSubmit, setValue } = useForm<ChangePasswordType>({
    resolver: yupResolver(validationSchema),
  });

  const handleCancelChangePassword = () => {
    setIsModalVisible(false);
  };

  const onSubmitChangePassword = handleSubmit(
    async (data: ChangePasswordType) => {
      const response = await userApi.changePassword({
        currentPasswordParam: data.password,
        newPasswordParam: data.newPassword,
      });
      if (response) {
        OpenNotification(false, 'Senha atualizada com sucesso', '');
        setValue('password', '');
        setValue('newPassword', '');
        setValue('confirmNewPasswowrd', '');
        return setIsModalVisible(false);
      } else {
        OpenNotification(
          true,
          'Erro ao atualizar senha do admin',
          'Por favor verifique novamente as senhas informadas e tente novamente'
        );
      }
    }
  );

  return (
    <>
      <Modal
        onCancel={handleCancelChangePassword}
        width={640}
        title='Alterar senha admin'
        onOk={onSubmitChangePassword}
        open={isModalVisible}
        footer={[
          <Button
            key='back'
            data-testid='back'
            onClick={handleCancelChangePassword}
          >
            Cancelar
          </Button>,
          <Button
            key='submit'
            data-testid='submit'
            type='primary'
            onClick={onSubmitChangePassword}
          >
            Salvar
          </Button>,
        ]}
      >
        <>
          <Form layout='vertical' form={form}>
            <label>Senha atual</label>
            <Controller
              name='password'
              control={control}
              data-testid='passwordTest'
              defaultValue=''
              render={({ field, fieldState }) => {
                const { status, help } = toFieldStatus(fieldState.error);
                return (
                  <FormItem validateStatus={status} help={help}>
                    <InputFieldPassword
                      data-testid='passwordTest'
                      autoComplete='off'
                      placeholder='Insira a senha atual'
                      prefix={<UnlockOutlined style={{ color: '#177DDC' }} />}
                      {...field}
                    />
                  </FormItem>
                );
              }}
            />
            <Rectangule>
              {
                'A senha deve conter pelo menos 8 caracteres, 1 letra maiúscula, 1 número e 1 carácter especial'
              }
            </Rectangule>
            <br></br>
            <label>Nova senha</label>
            <Controller
              control={control}
              defaultValue=''
              name='newPassword'
              render={({ field, fieldState }) => {
                const fieldStatus = toFieldStatus(fieldState.error);
                if (
                  (field.value &&
                    passwordStrength(field.value).value === 'Weak') ||
                  passwordStrength(field.value).value === 'Too weak'
                ) {
                  fieldStatus.help = 'Sua senha não é forte suficiente';
                  fieldStatus.status = 'error';
                }
                return (
                  <FormItem
                    validateStatus={fieldStatus.status}
                    help={fieldStatus.help}
                  >
                    <InputFieldPassword
                      autoComplete='off'
                      data-testid='newPassword'
                      placeholder='Insira a nova senha'
                      prefix={<UnlockOutlined style={{ color: '#177DDC' }} />}
                      {...field}
                    />
                  </FormItem>
                );
              }}
            />
            <label>Confirmar nova senha </label>
            <Controller
              name='confirmNewPasswowrd'
              control={control}
              defaultValue=''
              render={({ field, fieldState }) => {
                const { status, help } = toFieldStatus(fieldState.error);
                return (
                  <FormItem validateStatus={status} help={help}>
                    <InputFieldPassword
                      data-testid='confirmNewPasswowrd'
                      placeholder='Confirmar a nova senha'
                      autoComplete='off'
                      prefix={<UnlockOutlined style={{ color: '#177DDC' }} />}
                      {...field}
                    />
                  </FormItem>
                );
              }}
            />
          </Form>
        </>
      </Modal>
    </>
  );
};
const InputFieldPassword = styled(Input.Password)`
  margin: 10;
`;
const Rectangule = styled.div`
  padding: 10px;
  background: #2c2c2c;
`;
