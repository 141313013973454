import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  QuestionCircleOutlined,
} from '@ant-design/icons';
import { Button, Col, Row, Space, Spin, Tooltip, Typography } from 'antd';
import React, { useState } from 'react';

import { PERIOD_OPTIONS } from '../../constants/filters';
import { useCustomerActiveDays } from '../../hooks/useCustomerActiveDays';
import { UsageEngagement } from '../../types/customer';
import { CustomCard } from '../CustomCard';
import { EmptyState } from '../EmptyState';
import { PeriodSelect } from '../PeriodSelect';
import { ContentsWatched } from './ContentsWatched';
import { DeviceAccess } from './DeviceAccess';
import { InstructorsWatched } from './InstructorsWatched';
import { ProgramsWatched } from './ProgramsWatched';

type EngagementSectionProps = {
  usageEngagement: UsageEngagement;
  customerId: string;
};

const { Text, Title } = Typography;

export const EngagementSection: React.FC<EngagementSectionProps> = ({
  usageEngagement,
  customerId,
}) => {
  const {
    quizRecommendation,
    totalWatchedOfLastContent,
    recommendedContentWatched,
  } = usageEngagement;

  const [dateRange, setDateRange] = useState<string[]>([
    PERIOD_OPTIONS[0].startDate,
    PERIOD_OPTIONS[0].endDate,
  ]);

  const [startDate, endDate] = dateRange;

  const { activeDays, isLoading, isError, refetchOnError } =
    useCustomerActiveDays(customerId, startDate, endDate);

  return (
    <Space direction='vertical' size='middle' style={{ display: 'flex' }}>
      <Title level={5} style={{ margin: 0 }}>
        Engajamento
      </Title>
      <Space direction='vertical' size='middle' style={{ display: 'flex' }}>
        <Row gutter={24} justify='space-between' align={'top'}>
          <Col span={8}>
            <CustomCard title='Recomendação do quiz?'>
              <Row justify='space-between' align={'middle'}>
                {quizRecommendation ? (
                  <Row justify='center' align='middle' style={{ gap: '8px' }}>
                    <CheckCircleOutlined
                      width={32}
                      height={32}
                      style={{ color: 'green' }}
                    />
                    <Title level={4} style={{ margin: 0 }}>
                      Sim
                    </Title>
                  </Row>
                ) : (
                  <Row justify='center' align='middle' style={{ gap: '8px' }}>
                    <CloseCircleOutlined
                      width={32}
                      height={32}
                      style={{ color: 'red' }}
                    />
                    <Title level={4} style={{ margin: 0 }}>
                      Não
                    </Title>
                  </Row>
                )}
              </Row>
            </CustomCard>
          </Col>

          <Col span={8}>
            {recommendedContentWatched ? (
              <CustomCard title='Aulas recomendadas assistidas'>
                <Row justify='space-between' align={'middle'}>
                  <Title level={4} style={{ margin: 0 }}>
                    {recommendedContentWatched} aulas
                  </Title>
                </Row>
              </CustomCard>
            ) : (
              <CustomCard>
                <EmptyState title='Cliente não assistiu a aulas recomendadas' />
              </CustomCard>
            )}
          </Col>

          <Col span={8}>
            <CustomCard
              title='Dias ativos'
              extra={
                <Tooltip
                  placement='leftTop'
                  title='Usuário acessou a plataforma e concluiu no mínimo 1 aula (75% assistida).'
                  overlayInnerStyle={{
                    marginTop: '-8px',
                  }}
                >
                  <QuestionCircleOutlined
                    style={{ marginLeft: 8, fontSize: '18px' }}
                  />
                </Tooltip>
              }
            >
              <Row justify='space-between' align={'middle'}>
                {isError ? (
                  <>
                    <Text type='secondary'>Erro ao carregar dias ativos</Text>
                    <Button
                      type='primary'
                      size='middle'
                      onClick={() => refetchOnError()}
                    >
                      Recarregar
                    </Button>
                  </>
                ) : (
                  <>
                    <Title level={4} style={{ margin: 0 }}>
                      {isLoading ? <Spin /> : `${activeDays ?? 0} Dias`}
                    </Title>
                    <PeriodSelect
                      style={{
                        width: '100%',
                        maxWidth: '160px',
                        height: '28px',
                        color: '#1890ff',
                        textAlign: 'right',
                      }}
                      onSelectDate={setDateRange}
                    />
                  </>
                )}
              </Row>
            </CustomCard>
          </Col>
        </Row>

        <Row gutter={24} justify='space-between'>
          <ProgramsWatched customerId={customerId} />
          <InstructorsWatched customerId={customerId} />
        </Row>

        <Row gutter={24} justify='space-between' align={'top'}>
          <DeviceAccess customerId={customerId} />
          <ContentsWatched
            totalWatchedOfLastContent={totalWatchedOfLastContent}
          />
        </Row>
      </Space>
    </Space>
  );
};
