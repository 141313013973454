import { useQuery } from 'react-query';

import { customerApi } from '../api/customerApi';

export const useCustomerDeviceAccess = (
  customerId: string,
  startDate: string,
  endDate: string
) => {
  const { data, isLoading, isError, refetch } = useQuery({
    queryKey: ['customer-watched-devices', customerId, startDate, endDate],
    queryFn: () =>
      customerApi.fetchCustomerWatchedClassesByDevices(
        customerId,
        startDate,
        endDate
      ),
    refetchOnWindowFocus: false,
    enabled: !!customerId,
  });

  const refetchOnError = () => {
    refetch();
  };

  return {
    classesByDevice: data,
    isLoading,
    isError,
    refetchOnError,
  };
};
