import { slices } from '@core/redux';
import { createAsyncThunk } from '@reduxjs/toolkit';
// import { authApi, Credentials } from '../apis/authApi';
import { auth } from '@service/api';
import * as authUtil from '@utils/auth';
import jwtDecode from 'jwt-decode';

import OpenNotification from 'src/app/components/antd/OpenNotification';

import * as storage from './auth.storage';

export const tryLogin = createAsyncThunk(
  'auth/tryLogin',
  async (credentials: auth.Credentials, { rejectWithValue }) => {
    try {
      if (!credentials.email) {
        OpenNotification(true, 'O campo de email é obrigatório');
      }
      if (!credentials.password) {
        OpenNotification(true, 'O campo de senha é obrigatório');
      }
      const data = await auth.login(credentials);
      const access_token = 'Bearer ' + data.access_token;
      localStorage.setItem('AUTH_TOKEN', access_token);
      const payload = jwtDecode<slices.auth.TokenPayload>(data.access_token);
      storage.saveLoginEmailOnStorage(credentials.email);
      return {
        ...payload,
        access_token,
      };
    } catch (error: any) {
      if (error.status === 401) {
        OpenNotification(true, 'Credeenciais inválidas');
      }
      return rejectWithValue(error);
    }
  }
);
export const tryLogout = createAsyncThunk('auth/tryLogout', async () => {
  await storage.removeAuthToken();
});

export const tryRestoreAuth = createAsyncThunk(
  'auth/tryRestoreAuth',
  async () => {
    const token = await storage.getAuthToken();
    const payload = authUtil.decodeAndValidateAuthToken(token);

    return { token, payload };
  }
);
