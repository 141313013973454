import { slices } from '@core/redux';
import { yupResolver } from '@hookform/resolvers/yup';
import { Form, Space, Typography } from 'antd';
import { FC, useEffect, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';

import { isSerializedApiError } from 'src/app/utils/isSerializedApiError';
import { programsApi } from 'src/features/programs/api/programsApi';
import { useProgram } from 'src/features/programs/hooks/useProgram';
import {
  DesignStepForm,
  designStepSchema,
} from 'src/features/programs/schemas/designStepSchema';
import { getProgramDesignErrorFields } from 'src/features/programs/utils/getProgramDesignErrorFields';
import { getProgramDesignFromForm } from 'src/features/programs/utils/getProgramDesignFromForm';
import { showInfoModal } from 'src/features/programs/utils/modalHelpers';

import { ProgramName } from '../../ProgramName';
import { ProgramStepFooter } from '../../ProgramStepFooter';
import { ProgramDesignContent } from './ProgramDesignContent';

const { Paragraph, Text } = Typography;

interface DesignStepProps {
  programId?: number;
}

export const DesignStep: FC<DesignStepProps> = ({ programId }) => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const { program, isLoadingProgram } = useProgram(programId);

  const formValues = useMemo(
    (): DesignStepForm => ({
      name: program?.name,
      primaryColor: program?.metadata?.colors?.primary || '',
      imageCard: {
        preview: program?.imageCard || '',
        file: null,
      },
      imageCardWeb: {
        preview: program?.imageCardWeb || '',
        file: null,
      },
      imageVertical: {
        preview: program?.imageVertical || '',
        file: null,
      },
      imageLogo: {
        preview: program?.imageLogo || '',
        file: null,
      },
      thumbPdf: {
        preview: program?.thumbPdf,
        file: null,
      },
      bannerHighlight: {
        tablet: {
          preview: program?.dynamicBanner?.tablet?.highlight || '',
          file: null,
        },
        mobile: {
          preview: program?.dynamicBanner?.mobile?.highlight || '',
          file: null,
        },
      },
      bannerSuper: {
        tv: {
          preview: program?.dynamicBanner?.tv?.super || '',
          file: null,
        },
        tablet: {
          preview: program?.dynamicBanner?.tablet?.super || '',
          file: null,
        },
        mobile: {
          preview: program?.dynamicBanner?.mobile?.super || '',
          file: null,
        },
      },
      bannerOnboarding: {
        tv: {
          preview: program?.dynamicBanner?.tv?.onboarding || '',
          file: null,
        },
        mobile: {
          preview: program?.dynamicBanner?.mobile?.onboarding || '',
          file: null,
        },
      },
    }),
    [program]
  );

  const form = useForm({
    resolver: yupResolver(designStepSchema),
    values: formValues,
  });

  useEffect(() => {
    if (isLoadingProgram) {
      dispatch(slices.layout.increaseLoading());
    } else {
      dispatch(slices.layout.decreaseLoading());
    }
  }, [isLoadingProgram]);

  const resetStepQueries = () => {
    queryClient.resetQueries(['program-contents', programId]);
  };

  const onSubmit = async (fields: DesignStepForm) => {
    dispatch(slices.layout.increaseLoading());

    const payload = getProgramDesignFromForm(fields);

    try {
      const filesCount = Array.from(payload.keys()).length;
      if (filesCount > 0) {
        await programsApi.uploadProgramImages(programId as number, payload);
      }

      if (formValues.primaryColor !== fields.primaryColor) {
        await programsApi.updateProgramPrimaryColor(
          programId as number,
          fields.primaryColor.trim()
        );
      }

      showInfoModal({
        title: 'Sucesso',
        content: 'Imagens do programa atualizadas com sucesso!',
        afterClose: resetStepQueries,
      });
    } catch (error) {
      if (
        isSerializedApiError(error) &&
        error.status === 400 &&
        error.message
      ) {
        const errorFields = getProgramDesignErrorFields(error);
        return showInfoModal({
          title: 'Tivemos um problema...',
          content: (
            <>
              <Paragraph>
                Não foi possível subir as imagens, verifique as resoluções e
                tente novamente:
              </Paragraph>
              <ul style={{ paddingInlineStart: '24px' }}>
                {errorFields.map(({ field, message }) => (
                  <li key={field}>
                    <Paragraph>
                      <Text strong>{field}</Text>: {message}
                    </Paragraph>
                  </li>
                ))}
              </ul>
            </>
          ),
        });
      }
      showInfoModal({
        title: 'Tivemos um problema...',
        content:
          'Não foi possível subir as imagens e/ou atualizar a cor primária, tente novamente.',
      });
    } finally {
      dispatch(slices.layout.decreaseLoading());
    }
  };

  return (
    <FormProvider {...form}>
      <Form layout='vertical' onFinish={form.handleSubmit(onSubmit)}>
        <Space direction='vertical' size={24} style={{ width: '100%' }}>
          <ProgramName isDisabled />
          <ProgramDesignContent />
          <ProgramStepFooter
            programId={programId}
            onCancelConfirmation={resetStepQueries}
          />
        </Space>
      </Form>
    </FormProvider>
  );
};
