import { CloseOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { FC, ReactNode } from 'react';
import styled from 'styled-components';

interface ClosableProps {
  onClose: () => void;
  children?: ReactNode;
}

export const Closable: FC<ClosableProps> = ({ onClose, children }) => {
  return (
    <div style={{ position: 'relative' }}>
      <ButtonWrapper>
        <Button
          size='small'
          shape='circle'
          icon={<CloseOutlined />}
          onClick={onClose}
        />
      </ButtonWrapper>
      {children}
    </div>
  );
};

const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  position: absolute;
  top: -16px;
  right: -16px;
  z-index: 1;
  background-color: #141414;

  .ant-btn:not(:hover, :focus) {
    border-color: white;
  }
`;
