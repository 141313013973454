import { Customer } from '../../types/customer';
import { AccessManagement } from './AccessManagement';
import { ChangePassword } from './ChangePassword';
import { GenerateLink } from './GenerateLink';
import { ReSendEmail } from './ReSendEmail';

interface MenuContentProps {
  customerData: Customer;
  selectedMenu: string;
}

export const MenuContent: React.FC<MenuContentProps> = ({
  customerData,
  selectedMenu,
}) => {
  switch (selectedMenu) {
    case 'email':
      return (
        <ReSendEmail
          customerId={customerData.id}
          customerEmail={customerData.email}
        />
      );
    case 'link':
      return <GenerateLink customerId={customerData.id} />;
    case 'access':
      return <AccessManagement customerId={customerData.id} />;
    case 'password':
      return <ChangePassword customerId={customerData.id} />;
    default:
      return null;
  }
};
