import { useQuery } from 'react-query';

import { customerApi } from '../api/customerApi';

export const useAccessAllowed = (customerId: string) => {
  return useQuery({
    queryKey: ['access-allowed', customerId],
    queryFn: () => customerApi.fetchAccessAllowed(customerId),
  });
};
