import { CheckCircleOutlined } from '@ant-design/icons';
import { Col, Row, Typography } from 'antd';
import React, { useCallback } from 'react';

import {
  EMAIL_STATUS_LABEL,
  STATUS_STEPS,
} from '../../constants/communication';
import { Email } from '../../types/customer';

const { Text } = Typography;

interface EmailStatusProps {
  currentStatus?: Email['status'];
}

export const EmailStatus: React.FC<EmailStatusProps> = ({ currentStatus }) => {
  const getIconColor = useCallback(
    (index: number): string => {
      if (!currentStatus) {
        return '#BFBFBF';
      }

      const currentIndex = STATUS_STEPS.indexOf(currentStatus);

      return index <= currentIndex ? '#49AA19' : '#BFBFBF';
    },
    [currentStatus]
  );

  return (
    <Row justify='space-evenly' align='middle'>
      {STATUS_STEPS.map((status, index) => (
        <Col key={index} style={{ textAlign: 'center', padding: '12px 0' }}>
          <CheckCircleOutlined
            style={{ color: getIconColor(index), fontSize: '20px' }}
          />
          <Text style={{ fontSize: '14px', display: 'block', margin: '8px 0' }}>
            {EMAIL_STATUS_LABEL[status as keyof typeof EMAIL_STATUS_LABEL]}
          </Text>
        </Col>
      ))}
    </Row>
  );
};
