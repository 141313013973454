import { store } from '@core/redux';
import { removeAuthToken } from '@core/redux/slices/auth/auth.storage';
import axios from 'axios';
import qs from 'qs';

import { normalizeErrorsInterceptor } from '../service/interceptors/normalizeErrorsInterceptor';
import { env } from './env';

const statusCodeUnauthorized = 401;
const statusCodeTimedOut = 408;

export const baseClient = axios.create({
  baseURL: env.BASE_URL,
  paramsSerializer: qs.stringify,
  headers: {
    'Content-Type': 'application/json',
  },
});

baseClient.interceptors?.request.use(function (config) {
  const token =
    store.getState().auth.payload?.access_token || store.getState().auth.token;

  config.headers.Authorization = token;
  return config;
});

baseClient.interceptors?.response.use(
  response => response,
  error => {
    if (error.response.status === statusCodeUnauthorized) {
      removeAuthToken();
      window.location.href = 'login';
    }

    return Promise.reject(error);
  }
);

axios.interceptors?.response.use(
  config => config,
  error => {
    if (
      error.response.status === statusCodeTimedOut ||
      error.code === 'ECONNABORTED'
    ) {
      window.location.href = 'erro';
    }
    return Promise.reject(error);
  }
);

baseClient.interceptors?.response.use(undefined, normalizeErrorsInterceptor);
