import { Modal, ModalFuncProps } from 'antd';

const { info, confirm } = Modal;

const baseProps: ModalFuncProps = {
  icon: null,
  centered: true,
  closable: true,
  width: 650,
};

export const showInfoModal = (props: ModalFuncProps) => {
  return info({
    okText: 'Entendi',
    ...baseProps,
    ...props,
  });
};

export const showConfirmModal = (props: ModalFuncProps) => {
  return confirm({
    okText: 'Sim',
    cancelText: 'Não',
    maskClosable: true,
    ...baseProps,
    ...props,
  });
};
