export enum EmailStatusEnum {
  DELIVERY = 'delivery',
  COMPLAINT = 'complaint',
  SEND = 'send',
  OPEN = 'open',
  BOUNCE = 'bounce',
  CLICK = 'click',
}

export const EMAIL_STATUS_LABEL: Record<
  EmailStatusEnum.CLICK | EmailStatusEnum.DELIVERY | EmailStatusEnum.OPEN,
  string
> = {
  [EmailStatusEnum.DELIVERY]: 'Entregue',
  [EmailStatusEnum.OPEN]: 'Aberto',
  [EmailStatusEnum.CLICK]: 'Clicado',
};

export const STATUS_STEPS = [
  EmailStatusEnum.DELIVERY,
  EmailStatusEnum.OPEN,
  EmailStatusEnum.CLICK,
];
