import { baseClient } from 'src/app/baseClient';

import {
  CreateBonusPayload,
  CreateCustomBonusPayload,
  ProgramBonus,
} from '../types/programBonuses';

const fetchAllBonuses = async (programId: number) => {
  const { data } = await baseClient.get<ProgramBonus[]>(
    `/programs/${programId}/bonus`
  );
  return data;
};

const fetchDefaultBonuses = async (programId: number) => {
  const { data } = await baseClient.get<ProgramBonus[]>(
    `/programs/${programId}/bonus/default`
  );
  return data;
};

const fetchCustomBonuses = async (programId: number) => {
  const { data } = await baseClient.get<ProgramBonus[]>(
    `/programs/${programId}/bonus/custom`
  );
  return data;
};

const patchBonusName = async (bonusId: number, name: string) => {
  await baseClient.patch(`/programs/bonus/relationship/${bonusId}`, { name });
};

const uploadBonusPdfFile = async (
  bonusId: number,
  programId: number,
  formData: FormData
) => {
  await baseClient.patch(
    `/programs/${programId}/bonus/${bonusId}/upload`,
    formData,
    {
      headers: { 'Content-Type': 'multipart/form-data' },
    }
  );
};

const createCustomBonus = async (payload: CreateCustomBonusPayload) => {
  const { data } = await baseClient.post<{
    bonusId: number;
    programId: number;
  }>('/programs/bonus/relationship', payload);
  return data;
};

const createBonus = async (payload: CreateBonusPayload) => {
  await baseClient.post('/programs/bonus/relationship/associate', payload);
};

const deleteBonus = async (bonusProgramId: number) => {
  await baseClient.delete(`/programs/bonus/relationship/${bonusProgramId}`);
};

export const programBonusApi = {
  fetchAllBonuses,
  fetchDefaultBonuses,
  fetchCustomBonuses,
  patchBonusName,
  uploadBonusPdfFile,
  createCustomBonus,
  createBonus,
  deleteBonus,
};
