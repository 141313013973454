import { InformationStepForm } from '../schemas/informationStepSchema';
import { CreateOrUpdateProgramBaseInfo } from '../types/program';

export const getProgramBaseInfoFromForm = (
  fields: InformationStepForm
): CreateOrUpdateProgramBaseInfo => {
  return {
    name: fields.name || '',
    typeProgramId: fields.typeProgramId,
    instructorId: fields.instructorId,
    metadata: {
      quantityMeanDuration: fields.metadata.quantityMeanDuration,
      quantityDuration: fields.metadata.quantityDuration,
      durationMeasure: fields.metadata.durationMeasure,
      generalVision: fields.metadata?.generalVision?.map(section => ({
        title: section.title?.trim(),
        description: section.description?.trim(),
        topics: section.topics?.map(topic => topic.description?.trim() || ''),
      })),
    },
    segmentId: fields.segmentId,
    description: fields.description?.trim(),
    theoPlayerId: fields.theoPlayerId,
    levelId: fields.levelIds?.[0],
    objectiveId: fields.objectiveId,
    categoryIds: fields.categoryIds,
    theoPlayerUrl: fields.theoPlayerId
      ? `https://beyond.spalla.io/player/?video=${fields.theoPlayerId}&mensage=1`
      : '',
  };
};
