import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  position: sticky;
  top: 0;
`;

export const Centered = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: ${() => `${window.document.documentElement.scrollHeight}px`};
  background-color: rgba(255, 255, 255, 0.1);
  z-index: 9999;
`;
