import { useCallback } from 'react';
import { useMutation, useQueryClient } from 'react-query';

import { customerApi } from '../api/customerApi';
import { ToastMessage } from '../components/ToastMessage';

interface GenerateLinkParams {
  customerId: string;
}

export const useGenerateLinkToResetPassword = () => {
  const queryClient = useQueryClient();

  const onSuccess = useCallback(
    (_, variables: { customerId: string }) => {
      queryClient.invalidateQueries(['reset-password', variables.customerId]);
      ToastMessage({
        content: 'Link gerado com sucesso!',
        type: 'success',
      });
    },
    [queryClient]
  );

  const onError = useCallback(() => {
    ToastMessage({
      content: 'Falha ao gerar link. Tente novamente!',
      type: 'error',
    });
  }, [queryClient]);

  const { mutateAsync: generateLink, isLoading } = useMutation(
    ({ customerId }: GenerateLinkParams) =>
      customerApi.generateLinkToResetPassword(customerId),
    { onSuccess, onError }
  );

  return { generateLink, isLoading };
};
