import { Spin } from 'antd';
import { FC, useEffect } from 'react';

import * as S from './Spinner.style';

export const Spinner: FC = () => {
  useEffect(() => {
    document.body.style.overflow = 'hidden';
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });

    return () => {
      document.body.style.overflow = '';
    };
  }, []);
  return (
    <S.Centered>
      <S.Container>
        <Spin size='large' />
      </S.Container>
    </S.Centered>
  );
};
