import { useQuery } from 'react-query';

import { playlistApi } from '../api/playlistApi';

export const usePlaylist = (programId: number | undefined) => {
  const { data: playlist, isLoading: isLoadingPlaylist } = useQuery(
    ['program-playlist', programId],
    () => playlistApi.getPlaylist(programId as number),
    { enabled: !!programId, refetchOnWindowFocus: false }
  );

  return { playlist, isLoadingPlaylist };
};
