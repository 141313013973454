import { FolderAddOutlined, PlusOutlined } from '@ant-design/icons';
import { slices } from '@core/redux';
import { Button, Input, Space, Typography } from 'antd';
import { useEffect } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { useMutation } from 'react-query';
import { useDispatch } from 'react-redux';

import { programModulesAndContentsApi } from 'src/features/programs/api/programModulesAndContentsApi';
import { StructureStepForm } from 'src/features/programs/schemas/structureStepSchema';
import { LOCAL_FORM_ITEM_ID } from 'src/features/programs/utils/formHelpers';
import {
  showConfirmModal,
  showInfoModal,
} from 'src/features/programs/utils/modalHelpers';

import { ProgramFormItem } from '../../ProgramFormItem';
import { Closable } from '../../ui/Closable';
import { ProgramSection } from '../../ui/ProgramSection';
import { ProgramSectionGroup } from '../../ui/ProgramSectionGroup';
import { TransparentInput } from '../../ui/TransparentInput';
import { ProgramContents } from './ProgramContents';

const { Title, Text } = Typography;

export const ProgramModules = () => {
  const { control, watch } = useFormContext<StructureStepForm>();
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'modules',
  });

  const { mutateAsync: deleteModule, isLoading: isDeleting } = useMutation(
    programModulesAndContentsApi.deleteModule
  );

  const dispatch = useDispatch();

  useEffect(() => {
    if (isDeleting) {
      dispatch(slices.layout.increaseLoading());
    } else {
      dispatch(slices.layout.decreaseLoading());
    }
  }, [isDeleting]);

  const handleRemove = async (moduleId: number, index: number) => {
    try {
      if (moduleId !== LOCAL_FORM_ITEM_ID) {
        await deleteModule(moduleId);
      }
      remove(index);
    } catch (error) {
      showInfoModal({
        title: 'Tivemos um problema...',
        content:
          'Não foi possível excluir a seção do programa, tente novamente.',
      });
    }
  };

  const confirmRemove = (index: number) => {
    const currentModule = watch(`modules`)?.[index];
    if (!currentModule) return;

    if (currentModule.contents?.length) {
      return showInfoModal({
        title: 'Verifique alguns dados',
        content: 'Para excluir esta seção, exclua todas as aulas antes.',
      });
    }

    let description = 'Tem certeza que deseja remover esta seção?';

    if (currentModule.moduleId !== LOCAL_FORM_ITEM_ID) {
      description += ' Essa ação não poderá ser desfeita.';
    }

    showConfirmModal({
      title: 'Deseja realmente remover?',
      content: description,
      onOk() {
        handleRemove(currentModule.moduleId, index);
      },
    });
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <Title level={4}>Seções</Title>

      {fields.length === 0 ? (
        <Space direction='vertical' align='center' style={{ width: '100%' }}>
          <FolderAddOutlined style={{ fontSize: '40px' }} />
          <Text style={{ fontSize: 18 }}>
            Estruture o programa criando seções, subseções e aulas
          </Text>
        </Space>
      ) : (
        <Space direction='vertical' size={24} style={{ width: '100%' }}>
          {fields.map((field, index) => (
            <Closable key={field.id} onClose={() => confirmRemove(index)}>
              <ProgramSection>
                <ProgramSectionGroup isHeader>
                  <ProgramFormItem
                    control={control}
                    name={`modules.${index}.name`}
                    renderItem={({ field }) => (
                      <TransparentInput
                        {...field}
                        placeholder='Título da seção'
                      />
                    )}
                  />
                </ProgramSectionGroup>

                <ProgramSectionGroup>
                  <ProgramFormItem
                    control={control}
                    name={`modules.${index}.description`}
                    renderItem={({ field }) => (
                      <Input {...field} placeholder='Ex.: #FFFFFF' />
                    )}
                  />
                </ProgramSectionGroup>

                <ProgramContents moduleIndex={index} />
              </ProgramSection>
            </Closable>
          ))}
        </Space>
      )}

      <Button
        type='link'
        icon={<PlusOutlined />}
        onClick={() =>
          append({
            moduleId: LOCAL_FORM_ITEM_ID,
            name: '',
            description: '',
            contents: [],
          })
        }
        style={{ marginTop: 16, marginInline: 'auto' }}
      >
        Adicionar seção
      </Button>
    </div>
  );
};
