import { retriveLoginEmailFromStorage } from '@core/redux/slices/auth/auth.storage';
import { useState } from 'react';

import OpenNotification from 'src/app/components/antd/OpenNotification';

import { createFreezingSchedule } from '../apis/freezingApis';
import { FreezingFormData } from '../interfaces/frezzingInterfaces';

export default function useControllerModal() {
  const [isLoading, setIsLoading] = useState(false);
  const createFreezing = (data: FreezingFormData, onSuccess: () => void) => {
    setIsLoading(true);
    const requestingUserMail = retriveLoginEmailFromStorage();
    const freezingPayload = {
      ...data,
      startDate: data.startDate.toISOString(),
      returnDate: data.returnDate.toISOString(),
      requestingUserMail,
    };
    createFreezingSchedule(freezingPayload)
      .then(() => onSuccess())
      .catch(error => {
        OpenNotification(true, 'Ocorreu um erro', `${error}`);
      })
      .finally(() => setIsLoading(false));
  };

  return {
    isLoading,
    createFreezing,
  };
}
