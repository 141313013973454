import { slices } from '@core/redux';
import { yupResolver } from '@hookform/resolvers/yup';
import { Form, Space } from 'antd';
import { FC, useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';

import { programsApi } from 'src/features/programs/api/programsApi';
import { useProgram } from 'src/features/programs/hooks/useProgram';
import {
  PublishStepForm,
  publishStepSchema,
} from 'src/features/programs/schemas/publishStepSchema';
import { PublishProgramPayload } from 'src/features/programs/types/program';
import { showInfoModal } from 'src/features/programs/utils/modalHelpers';

import { ProgramName } from '../../ProgramName';
import { ProgramStepFooter } from '../../ProgramStepFooter';
import { ProgramPublishContent } from './ProgramPublishContent';

interface PublishStepProps {
  programId?: number;
}

export const PublishStep: FC<PublishStepProps> = ({ programId }) => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const { program, isLoadingProgram } = useProgram(programId);

  const isPublished = Boolean(program?.publicated);

  const form = useForm<PublishStepForm>({
    resolver: yupResolver(publishStepSchema),
    values: {
      name: program?.name,
      publish: '',
      date: null,
      time: null,
    },
  });

  useEffect(() => {
    if (isLoadingProgram) {
      dispatch(slices.layout.increaseLoading());
    } else {
      dispatch(slices.layout.decreaseLoading());
    }
  }, [isLoadingProgram]);

  const resetStepQueries = () => {
    queryClient.resetQueries(['program-contents', programId]);
  };

  const onSubmit = async (fields: PublishStepForm) => {
    const { date, time } = fields;
    let payload: PublishProgramPayload | null = null;

    if (fields.publish === 'scheduled' && date && time) {
      const timeNow = new Date().getTime();
      const publicationDate = new Date(
        date.getFullYear(),
        date.getMonth(),
        date.getDate(),
        time.getHours(),
        time.getMinutes()
      );

      if (timeNow > publicationDate.getTime()) {
        return showInfoModal({
          title: 'Verifique alguns dados',
          content:
            'Sua data de publicação não pode ser anterior a sua data atual.',
        });
      }

      payload = {
        year: publicationDate.getUTCFullYear(),
        month: publicationDate.getUTCMonth() + 1,
        date: publicationDate.getUTCDate(),
        hours: publicationDate.getUTCHours() - 3,
        minutes: publicationDate.getUTCMinutes(),
      };
    }

    dispatch(slices.layout.increaseLoading());

    try {
      const response = await programsApi.publishProgram(
        programId as number,
        payload
      );
      showInfoModal({
        title: 'Sucesso',
        content: response.message,
        afterClose: resetStepQueries,
      });
    } catch (error) {
      showInfoModal({
        title: 'Tivemos um problema...',
        content: 'Não foi possível publicar o programa, tente novamente.',
      });
    } finally {
      dispatch(slices.layout.decreaseLoading());
    }
  };

  return (
    <FormProvider {...form}>
      <Form layout='vertical' onFinish={form.handleSubmit(onSubmit)}>
        <Space direction='vertical' size={24} style={{ width: '100%' }}>
          <ProgramName isDisabled />
          <ProgramPublishContent isPublished={isPublished} />
        </Space>
        <ProgramStepFooter
          programId={programId}
          disabled={isPublished}
          onCancelConfirmation={resetStepQueries}
        />
      </Form>
    </FormProvider>
  );
};
