import { PlusOutlined } from '@ant-design/icons';
import { Button, Input, Space, Typography } from 'antd';
import { useFieldArray, useFormContext } from 'react-hook-form';

import { InformationStepForm } from 'src/features/programs/schemas/informationStepSchema';
import { showConfirmModal } from 'src/features/programs/utils/modalHelpers';

import { ProgramFormItem } from '../../ProgramFormItem';
import { Closable } from '../../ui/Closable';
import { ProgramSection } from '../../ui/ProgramSection';
import { ProgramSectionGroup } from '../../ui/ProgramSectionGroup';
import { TransparentInput } from '../../ui/TransparentInput';
import { GeneralVisionTopics } from './GeneralVisionTopics';

const { Title, Text } = Typography;
const { TextArea } = Input;

export const ProgramHowItWorks = () => {
  const { control } = useFormContext<InformationStepForm>();

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'metadata.generalVision',
  });

  const hasFields = fields.length > 0;

  const addSection = () => {
    append({
      title: '',
      description: '',
      topics: [],
    });
  };

  const confirmRemove = async (index: number) => {
    showConfirmModal({
      title: 'Deseja realmente remover?',
      content: 'Tem certeza que quer remover esta seção?',
      onOk() {
        remove(index);
      },
    });
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <Title level={4} style={{ marginBottom: hasFields ? undefined : 0 }}>
        Como funciona
      </Title>

      <Space direction='vertical' size={24} style={{ width: '100%' }}>
        {fields.map((field, index) => (
          <Closable key={field.id} onClose={() => confirmRemove(index)}>
            <ProgramSection>
              <ProgramSectionGroup isHeader>
                <ProgramFormItem
                  control={control}
                  name={`metadata.generalVision.${index}.title`}
                  renderItem={({ field }) => (
                    <TransparentInput
                      {...field}
                      placeholder='Título da subseção'
                    />
                  )}
                />
              </ProgramSectionGroup>

              <ProgramSectionGroup>
                <Text strong style={{ marginBottom: 16 }}>
                  Caso a sua subseção tenha apenas tópicos, deixe o parágrafo em
                  branco
                </Text>
                <ProgramFormItem
                  control={control}
                  name={`metadata.generalVision.${index}.description`}
                  label='Parágrafo'
                  renderItem={({ field }) => (
                    <TextArea
                      {...field}
                      placeholder='Informações relevantes do programa'
                      rows={4}
                    />
                  )}
                />
              </ProgramSectionGroup>

              <GeneralVisionTopics fieldIndex={index} />
            </ProgramSection>
          </Closable>
        ))}
      </Space>

      <Button
        type='link'
        icon={<PlusOutlined />}
        onClick={addSection}
        style={{ marginTop: hasFields ? 12 : 0, marginInline: 'auto' }}
      >
        Adicionar subseção
      </Button>
    </div>
  );
};
