import { Input } from 'antd';
import { MaskedInput } from 'antd-mask-input';
import { useState } from 'react';

interface InputColorProps {
  onChange: (value: string) => void;
  defaultValue?: string;
}
export const InputColor = ({ onChange, defaultValue }: InputColorProps) => {
  const [value, setValue] = useState(defaultValue);

  const handleChange = (value: string) => {
    setValue(value);
    onChange(value);
  };
  return (
    <MaskedInput
      type='text'
      mask={/^#[0-9a-f]{0,6}$/i}
      value={value}
      onChange={({ target }) => handleChange(target.value)}
      onBlur={({ target }) => {
        onChange(target.value);
      }}
      prefix={
        <div
          style={{
            position: 'relative',
            display: 'flex',
            border: '1px solid rgb(67, 67, 67)',
            height: '20px',
            width: '20px',
            backgroundColor: `${value}`,
            marginRight: 5,
          }}
        >
          <Input
            type='color'
            style={{
              height: '24px',
              width: '24px',
              opacity: 0,
            }}
            onChange={({ target }) => setValue(target.value)}
          />
        </div>
      }
    />
  );
};
