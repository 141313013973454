import { TableComponent } from '@atoms';
import { FilterHeader } from '@organisms';
import { Divider, Input, message } from 'antd';
import { ChangeEvent, FC, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';

import { useBreadcrumbs } from 'src/features/layout/hooks/useBreadcrumbs';

import { modalRedirectApi } from '../api/modalRedirectApi';
import { ModalRedirectHeader } from '../components/ModalRedirectHeader';
import { ModalRedirectTableActions } from '../components/ModalRedirectTableActions';
import { ModalRedirect as IModalRedirect } from '../typings/modalRedirect';

export const ModalRedirect: FC = () => {
  useBreadcrumbs([
    {
      label: 'Dashboard',
      route: '/dashboard',
    },
    {
      label: 'Modal Redirect',
    },
  ]);

  const [redirects, setRedirects] = useState<IModalRedirect[]>([]);
  const [filter, setFilter] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const filteredRedirects = useMemo(() => {
    if (!filter) return redirects;
    return redirects.filter(item => item.referrerUrl.indexOf(filter) === 0);
  }, [redirects, filter]);

  useEffect(() => {
    async function getRedirects() {
      setIsLoading(true);
      try {
        const data = await modalRedirectApi.fetchRedirects();
        setRedirects(data);
      } catch (err) {
        message.error('Não foi possivel os redirecionamentos. Tente novamente');
      } finally {
        setIsLoading(false);
      }
    }

    getRedirects();
  }, []);

  const onChangeFilter = (e: ChangeEvent<HTMLInputElement>) => {
    setFilter(e.target.value);
  };

  const redirectsColumns = useMemo(
    () => [
      {
        title: 'Página de origem',
        dataIndex: 'referrerUrl',
      },
      {
        title: 'Checkout destino',
        dataIndex: 'redirect',
      },
      {
        title: 'Ações',
        dataIndex: 'actions',
        align: 'center' as const,
        render: (_: any, rowData: IModalRedirect) => (
          <ModalRedirectTableActions
            createdAt={rowData.createdAt}
            redirectId={rowData.id}
            setRedirects={setRedirects}
          />
        ),
      },
    ],
    [setRedirects]
  );

  return (
    <>
      <ModalRedirectHeader redirects={redirects} setRedirects={setRedirects} />

      <Divider style={{ marginBlock: '40px' }} />

      <FilterContainer>
        <FilterHeader
          buttonList={[]}
          customFilter={[
            <Input.Search
              placeholder={'Busque pela página de origem'}
              value={filter}
              onChange={onChangeFilter}
              style={{ width: '360px' }}
              allowClear
            />,
          ]}
        />
      </FilterContainer>

      <TableComponent
        pageSize={10}
        data-testid='redirects-table'
        columns={redirectsColumns}
        data={filteredRedirects}
        total={filteredRedirects.length}
        loading={isLoading}
        showSizeChanger={false}
      />
    </>
  );
};

const FilterContainer = styled.div`
  margin-bottom: 24px;
`;
