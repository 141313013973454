import { baseClient } from 'src/app/baseClient';

import { CreateOrUpdatePlaylist, Playlist } from '../types/playlist';

async function getPlaylist(programId: number) {
  const { data } = await baseClient.get<Playlist>(
    `/programs/${programId}/playlists`
  );
  return data;
}

async function createPlaylist(
  programId: number,
  payload: CreateOrUpdatePlaylist
) {
  const { data } = await baseClient.post<Playlist>(
    `/programs/${programId}/playlists`,
    payload
  );
  return data;
}

async function updatePlaylist(
  programId: number,
  payload: CreateOrUpdatePlaylist
) {
  const { data } = await baseClient.put<Playlist>(
    `/programs/${programId}/playlists`,
    payload
  );
  return data;
}

export const playlistApi = {
  getPlaylist,
  createPlaylist,
  updatePlaylist,
};
