import {
  DesktopOutlined,
  MobileOutlined,
  TabletOutlined,
} from '@ant-design/icons';
import { TvIcon } from '@assets/icons/tv';
import { Card, Col, Row, Space, Spin, Typography } from 'antd';
import React, { useCallback, useState } from 'react';
import styled from 'styled-components';

import { PERIOD_OPTIONS } from '../../constants/filters';
import { useCustomerDeviceAccess } from '../../hooks/useCustomerDeviceAccess';
import { CustomCard } from '../CustomCard';
import { DateRangePicker } from '../DateRangePicker';
import { EmptyState } from '../EmptyState';
import { ErrorState } from '../ErrorState';

const { Text } = Typography;

type DeviceAccessType = {
  customerId: string;
};

export const DeviceAccess: React.FC<DeviceAccessType> = ({ customerId }) => {
  const [dateRange, setDateRange] = useState<string[]>([
    PERIOD_OPTIONS[0].startDate,
    PERIOD_OPTIONS[0].endDate,
  ]);

  const [startDate, endDate] = dateRange;

  const { classesByDevice, isError, isLoading, refetchOnError } =
    useCustomerDeviceAccess(customerId, startDate, endDate);

  const getIcon = useCallback((type: string) => {
    const iconsType: Record<string, JSX.Element | null> = {
      tv: <CustomTvIcon />,
      desktop: <DesktopOutlined style={{ fontSize: '17px' }} />,
      mobile: <MobileOutlined style={{ fontSize: '17px' }} />,
      tablet: <TabletOutlined style={{ fontSize: '17px' }} />,
    };

    return iconsType[type] || null;
  }, []);

  if (isError)
    return (
      <Col span={12}>
        <Space
          direction='vertical'
          size='middle'
          style={{ display: 'flex', width: '100%' }}
        >
          <Text style={{ margin: 0, fontSize: '16px', fontWeight: 300 }}>
            Aulas visualizadas por dispositivo
          </Text>
          <Card
            style={{
              flex: 1,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              minHeight: '316px',
            }}
          >
            <ErrorState
              style={{ flex: 1 }}
              title='Erro ao carregar dados de aulas visualizadas por dispositivo'
              subtitle='Não foi possível carregar os dados de aulas visualizadas por dispositivo para este cliente, aperte o botão abaixo para recarregar'
              onClickButton={() => refetchOnError()}
            />
          </Card>
        </Space>
      </Col>
    );

  return (
    <Col span={12}>
      <Space
        direction='vertical'
        size='middle'
        style={{ display: 'flex', width: '100%' }}
      >
        <Row justify={'space-between'} align={'middle'}>
          <Text style={{ margin: 0, fontSize: '16px', fontWeight: 300 }}>
            Aulas visualizadas por dispositivo
          </Text>
          <DateRangePicker
            style={{
              width: '100%',
              maxWidth: '260px',
              height: '28px',
              color: '#1890ff',
              textAlign: 'right',
            }}
            onSelectDate={setDateRange}
          />
        </Row>

        {classesByDevice?.length !== 0 && !isLoading && classesByDevice && (
          <Row gutter={[24, 24]}>
            {classesByDevice.slice(0, 4).map((device, index) => (
              <Col key={index} span={12}>
                <CustomCard
                  title={device.deviceName}
                  extra={getIcon(device.deviceName)}
                  style={{ textTransform: 'capitalize' }}
                >
                  <Row
                    justify='center'
                    align='middle'
                    style={{
                      gap: '10px',
                      marginTop: '14px',
                      marginBottom: '14px',
                    }}
                  >
                    <Text style={{ fontSize: '30px', fontWeight: 'bold' }}>
                      {device.count}
                    </Text>
                    <Text
                      style={{
                        fontSize: '13px',
                        fontWeight: 300,
                        marginTop: '8px',
                      }}
                    >
                      Acessos
                    </Text>
                  </Row>
                </CustomCard>
              </Col>
            ))}
          </Row>
        )}

        {classesByDevice?.length === 0 && !isLoading && (
          <Row justify={'center'} align={'middle'}>
            <Card
              style={{
                flex: 1,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                minHeight: '316px',
              }}
            >
              <EmptyState
                style={{ flex: 1 }}
                title='Sem dados de aulas visualizadas por dispositivo'
                subtitle='Infelizmente não exite aulas visualizadas por dispositivo para esse cliente no período selecionado.'
              />
            </Card>
          </Row>
        )}

        {isLoading && (
          <Row
            justify={'center'}
            align={'middle'}
            style={{
              minHeight: '316px',
            }}
          >
            <Spin />
          </Row>
        )}
      </Space>
    </Col>
  );
};

const CustomTvIcon = styled(TvIcon)`
  & svg {
    width: 20px;
    height: 20px;
  }
`;
