import { DeleteOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { FC } from 'react';
import { useMutation } from 'react-query';

import { programModulesAndContentsApi } from 'src/features/programs/api/programModulesAndContentsApi';
import { LOCAL_FORM_ITEM_ID } from 'src/features/programs/utils/formHelpers';
import {
  showConfirmModal,
  showInfoModal,
} from 'src/features/programs/utils/modalHelpers';

interface DeleteContentButtonProps {
  contentId: number;
  onDeleteSuccess: () => void;
}

export const DeleteContentButton: FC<DeleteContentButtonProps> = ({
  contentId,
  onDeleteSuccess,
}) => {
  const { mutateAsync: deleteContent, isLoading: isDeleting } = useMutation(
    programModulesAndContentsApi.deleteContent
  );

  const handleRemove = async () => {
    try {
      if (contentId !== LOCAL_FORM_ITEM_ID) {
        const data = await deleteContent(contentId);
        if (!data.message) {
          return showInfoModal({
            title: 'Tivemos um problema...',
            content:
              'Algo de errado aconteceu ao tentar remover este vídeo, tente novamente.',
          });
        }
      }
      onDeleteSuccess();
    } catch (error) {
      showInfoModal({
        title: 'Tivemos um problema...',
        content: 'Não foi possível excluir esse conteúdo, tente novamente.',
      });
    }
  };

  const confirmRemove = () => {
    let description = 'Tem certeza que deseja remover esta aula?';

    if (contentId !== LOCAL_FORM_ITEM_ID) {
      description += ' Essa ação não poderá ser desfeita.';
    }

    showConfirmModal({
      title: 'Deseja realmente remover?',
      content: description,
      onOk: handleRemove,
    });
  };

  return (
    <Button
      icon={<DeleteOutlined style={{ fontSize: 18 }} />}
      onClick={confirmRemove}
      loading={isDeleting}
      style={{ marginLeft: 12, border: 'none' }}
    />
  );
};
