import {
  FundOutlined,
  HistoryOutlined,
  MailOutlined,
  SlidersOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { PageHeader } from '@organisms';
import { Col, Row, Tabs } from 'antd';
import { useMemo } from 'react';
import styled from 'styled-components';

import { ContentComponent } from 'src/app/components/ContentComponent';

import { Actions } from '../components/Actions';
import { CustomerCommunicationTab } from '../components/CustomerCommunication';
import { CustomerEngagementTab } from '../components/CustomerEngagement';
import { CustomerHistory } from '../components/CustomerHistory';
import { CustomerSummaryTab } from '../components/CustomerSummary';
import { EmptyState } from '../components/EmptyState';
import { InputSearch, InputSearchData } from '../components/SearchInput';
import { useCustomerDetails } from '../hooks/useCustomerDetails';

export const CustomerDetailsScreen: React.FC = () => {
  const {
    tabActive,
    setTabActive,
    customerData,
    customerExists,
    handleSearchCustomer,
    isLoading,
  } = useCustomerDetails();

  const items = useMemo(() => {
    if (!customerData || !customerExists) return [];

    return [
      {
        label: (
          <span>
            <UserOutlined />
            Resumo do Cliente
          </span>
        ),
        key: '0',
        children: (
          <CustomerSummaryTab
            customerData={customerData}
            setTabActive={setTabActive}
          />
        ),
      },
      {
        label: (
          <span>
            <MailOutlined />
            Comunicações
          </span>
        ),
        key: '1',
        children: <CustomerCommunicationTab customerData={customerData} />,
      },
      {
        label: (
          <span>
            <FundOutlined />
            Uso e Engajamento
          </span>
        ),
        key: '2',
        children: <CustomerEngagementTab customerData={customerData} />,
      },
      {
        label: (
          <span>
            <SlidersOutlined />
            Ações
          </span>
        ),
        key: '3',
        children: <Actions customerData={customerData.customer} />,
      },
      {
        label: (
          <span>
            <HistoryOutlined />
            Histórico
          </span>
        ),
        key: '4',
        children: <CustomerHistory customerId={customerData.customer.id} />,
      },
    ];
  }, [customerData, customerExists, setTabActive]);

  return (
    <ContentComponent isError={false} isLoading={isLoading}>
      <PageHeader
        backToPath={() => {
          window.history.back();
        }}
        title={!customerExists ? 'Cliente' : customerData?.customer.name || ''}
        subTitle={'Cadastre, edite e configure dados do cliente'}
        extra={
          <InputSearch
            onSearch={(inputData: InputSearchData) =>
              handleSearchCustomer(inputData.search)
            }
          />
        }
        hasDivider={false}
      />
      {customerData && (
        <CustomTab
          items={items}
          activeKey={tabActive}
          onChange={setTabActive}
        />
      )}

      {!customerExists && (
        <Row
          justify='center'
          align='middle'
          style={{ width: '100%', marginTop: '64px' }}
        >
          <Col>
            <EmptyState
              title='Cliente não encontrado.'
              subtitle='Faça a pesquisa novamente utilizando id, ou email do cliente.'
            />
          </Col>
        </Row>
      )}
    </ContentComponent>
  );
};

const CustomTab = styled(Tabs)`
  margin-top: 32px;
  margin-bottom: 0px;
  .ant-tabs-nav {
    margin: 0;
  }
`;
