import { useQuery } from 'react-query';

import { customerApi } from '../api/customerApi';

export const useGetLinkToResetPassword = (customerId: string) => {
  return useQuery({
    queryKey: ['reset-password', customerId],
    queryFn: () => customerApi.fetchLinkToResetPassword(customerId),
  });
};
