import { baseClient } from 'src/app/baseClient';

import {
  CreateLinkParams,
  FetchLinksParams,
  ShortenedLink,
} from '../typings/linkShortner';

async function fetchLinks(params: FetchLinksParams) {
  const { data } = await baseClient.get<ShortenedLink[]>('/link-shortner', {
    params,
  });
  return data;
}

async function checkIfLinkExistsBySlug(slug: string): Promise<boolean> {
  try {
    const { data } = await baseClient.get(`/link-shortner/exists/${slug}`);
    return data;
  } catch (err) {
    return false;
  }
}

async function createLink(params: CreateLinkParams) {
  const { data } = await baseClient.post<ShortenedLink>('/link-shortner', {
    slug: params.slug,
    destination: params.destination,
    description: params.description,
  });
  return data;
}

async function deleteLink(linkId: string): Promise<void> {
  await baseClient.delete(`/link-shortner/${linkId}`);
}

export const linkShortnerApi = {
  fetchLinks,
  checkIfLinkExistsBySlug,
  createLink,
  deleteLink,
};
