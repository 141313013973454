import { Col, Row, Space } from 'antd';

import { Spiner } from 'src/app/components/antd/Spiner';

import { useCustomerEngagement } from '../../hooks/useCustomerEngagement';
import { CustomerData } from '../../types/customer';
import { CustomCard } from '../CustomCard';
import { EmptyState } from '../EmptyState';
import { ErrorState } from '../ErrorState';
import { AccessSection } from './AccessSection';
import { CustomerSearches } from './CustomerSearches';
import { EngagementSection } from './EngagementSection';
import { RecommendationsSection } from './RecommendationsSection';

type CustomerEngagementTabProps = {
  customerData: CustomerData;
};

export const CustomerEngagementTab: React.FC<CustomerEngagementTabProps> = ({
  customerData,
}) => {
  const { id: customerId } = customerData.customer;
  const { usageEngagement, isLoading, isError, refetchOnError } =
    useCustomerEngagement(customerId);

  if (isLoading) return <Spiner />;

  if (isError)
    return (
      <Row style={{ marginTop: '24px' }}>
        <Col span={24}>
          <CustomCard>
            <ErrorState
              title='Erro ao carregar dados de engajamento do usuário'
              subtitle='Infelizmente tivemos um problema no momento de carregar os dados. Clique no botão abaixo para tentar novamente.'
              buttonLabel='Recarregar dados de engajamento do usuário'
              onClickButton={() => refetchOnError()}
            />
          </CustomCard>
        </Col>
      </Row>
    );

  if (!usageEngagement)
    return (
      <Row style={{ marginTop: '24px' }}>
        <Col span={24}>
          <CustomCard>
            <EmptyState
              title='Nenhum dado disponível no momento'
              subtitle='As atividades e insights do usuário serão exibidos aqui.'
            />
          </CustomCard>
        </Col>
      </Row>
    );

  return (
    <Space
      direction='vertical'
      size='large'
      style={{ display: 'flex', marginTop: '24px' }}
    >
      <AccessSection usageEngagement={usageEngagement} />
      <EngagementSection
        usageEngagement={usageEngagement}
        customerId={customerId}
      />
      <RecommendationsSection customerId={customerId} />
      <CustomerSearches customerId={customerId} />
    </Space>
  );
};
