import { DownOutlined } from '@ant-design/icons';
import { Select, SelectProps } from 'antd';
import { useCallback } from 'react';

import { PERIOD_OPTIONS } from '../constants/filters';

export type PeriodSelectProps = {
  onSelectDate: (dates: [string, string]) => void;
} & Omit<SelectProps, 'onChange'>;

export const PeriodSelect = ({ onSelectDate, ...rest }: PeriodSelectProps) => {
  const handleSelectChange = useCallback(
    value => {
      const selectedOption = PERIOD_OPTIONS.find(
        option => option.value === value
      );
      if (selectedOption) {
        onSelectDate([selectedOption.startDate, selectedOption.endDate]);
      }
    },
    [onSelectDate]
  );

  return (
    <Select
      bordered={false}
      defaultValue={PERIOD_OPTIONS[0].value}
      suffixIcon={
        <DownOutlined style={{ color: '#1890ff', margin: '4px 0 0 0' }} />
      }
      options={PERIOD_OPTIONS.map(option => ({
        label: option.label,
        value: option.value,
      }))}
      onChange={handleSelectChange}
      {...rest}
    />
  );
};
