import {
  Checkbox,
  Col,
  Input,
  InputNumber,
  Radio,
  Select,
  Typography,
} from 'antd';
import { FC, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';

import { useProgramDetailsOptions } from 'src/features/programs/hooks/useProgramDetailsOptions';
import { InformationStepForm } from 'src/features/programs/schemas/informationStepSchema';
import { removeSpecialCharacters } from 'src/features/programs/utils/removeSpecialCharacters';

import { ProgramFormItem } from '../../ProgramFormItem';
import { ProgramSection } from '../../ui/ProgramSection';
import { ProgramSectionGroup } from '../../ui/ProgramSectionGroup';
import { CreateInstructor } from './CreateInstructor';

const { Text } = Typography;

interface ProgramDetailsProps {
  programId?: number;
}

export const ProgramDetails: FC<ProgramDetailsProps> = ({ programId }) => {
  const { detailsOptions, loadingOptions } = useProgramDetailsOptions();

  const { control, setValue, watch } = useFormContext<InformationStepForm>();

  const objectiveId = watch('objectiveId');
  const secondaryObjectivesIds = watch('secondaryObjectivesIds');
  const quantityDuration = watch('metadata.quantityDuration');
  const isQuizRecommended = watch('isQuizRecommended');

  const durationOptions = useMemo(() => {
    const isPlural = quantityDuration && parseInt(quantityDuration) > 1;
    return [
      { value: 'D', label: `${isPlural ? 'Dias' : 'Dia'}` },
      { value: 'S', label: `${isPlural ? 'Semanas' : 'Semana'}` },
      { value: 'M', label: `${isPlural ? 'Meses' : 'Mês'}` },
    ];
  }, [quantityDuration]);

  return (
    <ProgramSection title='Detalhes do programa'>
      <ProgramSectionGroup isHeader>
        <Text>Detalhes do programa</Text>
      </ProgramSectionGroup>

      <ProgramSectionGroup gutter={[32, 0]}>
        <Col span={12}>
          <ProgramFormItem
            control={control}
            name='typeProgramId'
            label='Tipo de programa'
            isLoading={loadingOptions}
            defaultValue={
              !programId ? detailsOptions.types[0]?.value : undefined
            }
            renderItem={({ field }) => (
              <Select {...field} options={detailsOptions.types} />
            )}
          />
        </Col>
        <Col span={12} style={{ display: 'flex', flexDirection: 'column' }}>
          <ProgramFormItem
            control={control}
            name='instructorId'
            label='Treinador(a)'
            isLoading={loadingOptions}
            defaultValue={
              !programId ? detailsOptions.instructors[0]?.value : undefined
            }
            renderItem={({ field }) => (
              <Select
                {...field}
                showSearch
                filterOption={(input, option) =>
                  (option?.label ?? '')
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                options={detailsOptions.instructors}
              />
            )}
          />
          <CreateInstructor programId={programId} />
        </Col>
      </ProgramSectionGroup>

      <ProgramSectionGroup gutter={[32, 0]}>
        <Col span={8}>
          <ProgramFormItem
            control={control}
            name='metadata.quantityMeanDuration'
            label='Tempo em média'
            renderItem={({ field }) => (
              <InputNumber
                {...field}
                type='number'
                min={1}
                placeholder='Ex.: 15 (use sempre min.)'
              />
            )}
          />
        </Col>
        <Col span={8}>
          <ProgramFormItem
            control={control}
            name='metadata.quantityDuration'
            label='Duração total do programa'
            renderItem={({ field }) => (
              <InputNumber
                {...field}
                type='number'
                min={1}
                placeholder='Ex.: 4'
              />
            )}
          />
        </Col>
        <Col span={8}>
          <ProgramFormItem
            control={control}
            name='metadata.durationMeasure'
            label='Duração total (mês/semana/dia)'
            defaultValue={durationOptions[0].value}
            renderItem={({ field }) => (
              <Select {...field} options={durationOptions} />
            )}
          />
        </Col>
      </ProgramSectionGroup>

      <ProgramSectionGroup>
        <ProgramFormItem
          control={control}
          name='numberOfClasses'
          label='Número de aulas'
          defaultValue={1}
          renderItem={({ field }) => (
            <InputNumber {...field} type='number' min={1} />
          )}
        />
      </ProgramSectionGroup>

      <ProgramSectionGroup>
        <ProgramFormItem
          control={control}
          name='segmentId'
          label='Segmentação'
          renderItem={({ field }) => (
            <Select
              {...field}
              showSearch
              filterOption={(input, option) =>
                (option?.label ?? '')
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              placeholder='Selecione a segmentação'
              options={detailsOptions.segments}
            />
          )}
        />
      </ProgramSectionGroup>

      <ProgramSectionGroup>
        <Col span={16}>
          <ProgramFormItem
            control={control}
            name='objectiveId'
            label='Objetivo primário'
            isLoading={loadingOptions}
            loadingType='options'
            renderItem={({ field }) => (
              <Radio.Group
                {...field}
                optionType='button'
                options={detailsOptions.objectives}
                onChange={event => {
                  const newValue = event.target.value;
                  setValue(
                    'secondaryObjectivesIds',
                    secondaryObjectivesIds?.filter(item => item !== newValue)
                  );
                  field.onChange(newValue);
                }}
              />
            )}
          />
        </Col>
      </ProgramSectionGroup>

      <ProgramSectionGroup>
        <Col span={16}>
          <ProgramFormItem
            control={control}
            name='secondaryObjectivesIds'
            label='Objetivos Secundários'
            isLoading={loadingOptions}
            loadingType='options'
            renderItem={({ field }) => (
              <Checkbox.Group
                {...field}
                options={detailsOptions.objectives}
                onChange={checkedValue => {
                  const filteredValue = checkedValue.filter(
                    item => item !== objectiveId
                  );
                  if (filteredValue.length <= 2) {
                    field.onChange(filteredValue);
                  }
                }}
              />
            )}
          />
        </Col>
      </ProgramSectionGroup>

      <ProgramSectionGroup>
        <Col span={16}>
          <ProgramFormItem
            control={control}
            name='categoryIds'
            label='Categorias (Somente para TV)'
            isLoading={loadingOptions}
            loadingType='options'
            renderItem={({ field }) => (
              <Checkbox.Group {...field} options={detailsOptions.categories} />
            )}
          />
        </Col>
      </ProgramSectionGroup>

      <ProgramSectionGroup>
        <ProgramFormItem
          control={control}
          name='levelIds'
          label='Nível'
          isLoading={loadingOptions}
          loadingType='options'
          renderItem={({ field }) => (
            <Checkbox.Group {...field} options={detailsOptions.levels} />
          )}
        />
      </ProgramSectionGroup>

      <ProgramSectionGroup>
        <ProgramFormItem
          control={control}
          name='modalityIds'
          label='Modalidades'
          isLoading={loadingOptions}
          loadingType='options'
          renderItem={({ field }) => (
            <Checkbox.Group
              {...field}
              options={detailsOptions.modalitys}
              onChange={checkedValue => {
                if (checkedValue.length <= 3) {
                  field.onChange(checkedValue);
                }
              }}
            />
          )}
        />
      </ProgramSectionGroup>

      <ProgramSectionGroup>
        <ProgramFormItem
          control={control}
          name='tags'
          label='Tags'
          renderItem={({ field }) => (
            <Input
              {...field}
              placeholder='Ex.: Funcional, Lançamento'
              onChange={event =>
                field.onChange(removeSpecialCharacters(event.target.value))
              }
            />
          )}
        />
      </ProgramSectionGroup>

      <ProgramSectionGroup>
        <ProgramFormItem
          control={control}
          name='accessory'
          label='Programa com Acessórios?'
          renderItem={({ field }) => (
            <Radio.Group
              {...field}
              optionType='button'
              options={[
                { value: 1, label: 'Com Acessórios' },
                { value: 0, label: 'Sem Acessórios' },
              ]}
            />
          )}
        />
      </ProgramSectionGroup>

      <ProgramSectionGroup>
        <ProgramFormItem
          control={control}
          name='gender'
          label='Gênero'
          renderItem={({ field }) => (
            <Radio.Group
              {...field}
              optionType='button'
              options={[
                { value: 'M', label: 'Masculino' },
                { value: 'F', label: 'Feminino' },
                { value: 'T', label: 'Todos os Gêneros' },
              ]}
            />
          )}
        />
      </ProgramSectionGroup>

      <ProgramSectionGroup>
        <ProgramFormItem
          control={control}
          name='forMothers'
          label='Para mães'
          isLoading={loadingOptions}
          loadingType='options'
          renderItem={({ field }) => (
            <Radio.Group
              {...field}
              optionType='button'
              options={detailsOptions.forMothers}
            />
          )}
        />
      </ProgramSectionGroup>

      <ProgramSectionGroup>
        <ProgramFormItem
          control={control}
          name='durationValue'
          label='Duração das aulas'
          isLoading={loadingOptions}
          loadingType='options'
          renderItem={({ field }) => (
            <Radio.Group
              {...field}
              optionType='button'
              options={detailsOptions.durationValues}
            />
          )}
        />
      </ProgramSectionGroup>

      <ProgramSectionGroup>
        <ProgramFormItem
          control={control}
          name='frequency'
          label='Repetição'
          defaultValue={0}
          renderItem={({ field }) => (
            <InputNumber {...field} type='number' min={0} />
          )}
        />
      </ProgramSectionGroup>

      <ProgramSectionGroup>
        <ProgramFormItem
          control={control}
          name='isQuizRecommended'
          label='Recomendado no Quiz'
          renderItem={({ field }) => (
            <Radio.Group
              {...field}
              optionType='button'
              options={[
                { value: 1, label: 'Sim' },
                { value: 0, label: 'Não' },
              ]}
            />
          )}
        />
      </ProgramSectionGroup>

      {isQuizRecommended === 1 && (
        <ProgramSectionGroup>
          <ProgramFormItem
            unregisterOnUnmount
            control={control}
            name='recommendationPriority'
            label='Prioridade de indicação'
            renderItem={({ field }) => (
              <InputNumber
                {...field}
                type='number'
                min={0}
                placeholder='Ex.: 30'
              />
            )}
          />
        </ProgramSectionGroup>
      )}
    </ProgramSection>
  );
};
