import { Button, Col, Divider, Input, message, Row, Typography } from 'antd';
import { memo, useState } from 'react';
import styled from 'styled-components';

import { linkShortnerApi } from '../api/linkShortnerApi';
import { linkShortnerPrefix } from '../utils/withLinkShortnerPrefix';

const { Title, Text } = Typography;

export const LinkShortnerHeader = memo(() => {
  const [slug, setSlug] = useState('');
  const [destination, setDestination] = useState('');
  const [description, setDescription] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const handleCreateLink = async () => {
    setIsLoading(true);
    try {
      await linkShortnerApi.createLink({
        slug: slug || undefined,
        destination,
        description,
      });
      message.success('Link criado com sucesso!');
    } catch (err) {
      message.error('Não foi possivel criar o link. Tente novamente');
    } finally {
      setIsLoading(false);
    }
  };

  const shortenLink = async () => {
    setIsLoading(true);
    let slugExists = false;

    if (slug) {
      slugExists = await linkShortnerApi.checkIfLinkExistsBySlug(slug);
    }

    if (slugExists) {
      setIsLoading(false);
      message.error('Esse link já existe!');
      return;
    }

    await handleCreateLink();
  };

  return (
    <>
      <Title level={3}>Encurtador de links</Title>

      <Divider style={{ marginBlock: '40px' }} />

      <Row
        style={{ marginBottom: '24px', alignItems: 'flex-end' }}
        gutter={[16, 0]}
      >
        <Col>
          <Text style={{ fontSize: '24px' }}>{linkShortnerPrefix}</Text>
        </Col>
        <Col flex={1}>
          <UnderlineInput
            placeholder='Digite seu link personalizado (opcional)'
            value={slug}
            onChange={event => setSlug(event.target.value)}
          />
        </Col>
      </Row>
      <Row style={{ marginBottom: '24px' }}>
        <Col span={24}>
          <Input
            placeholder='Cole aqui o link de destino'
            value={destination}
            onChange={event => setDestination(event.target.value)}
          />
        </Col>
      </Row>
      <Row style={{ marginBottom: '24px' }}>
        <Col span={24}>
          <Input
            placeholder='Digite a descrição do seu link'
            value={description}
            onChange={event => setDescription(event.target.value)}
          />
        </Col>
      </Row>

      <Row justify='end'>
        <Button type='primary' onClick={shortenLink} loading={isLoading}>
          Encurtar link
        </Button>
      </Row>
    </>
  );
});

const UnderlineInput = styled(Input)`
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px !important;
  padding-inline: 8px;
`;
