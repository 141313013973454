import { useCallback } from 'react';
import { useMutation, useQueryClient } from 'react-query';

import { customerApi } from '../api/customerApi';

interface AllowAccessParams {
  customerId: string;
  profileId: string;
  numberOfAccessDays: number;
  reason: string;
}

export const useAllowAccess = () => {
  const queryClient = useQueryClient();

  const onSuccess = useCallback(
    (_, variables: { customerId: string }) => {
      queryClient.invalidateQueries(['access-allowed', variables.customerId]);
    },
    [queryClient]
  );

  const { mutate: allowAccess } = useMutation(
    ({
      customerId,
      profileId,
      numberOfAccessDays,
      reason,
    }: AllowAccessParams) =>
      customerApi.allowAccess(
        customerId,
        profileId,
        numberOfAccessDays,
        reason
      ),
    { onSuccess }
  );

  return { allowAccess };
};
