import { TableComponent } from '@atoms';
import { Col, Divider, Select } from 'antd';
import { useCallback, useMemo, useState } from 'react';

import { useBreadcrumbs } from 'src/features/layout/hooks/useBreadcrumbs';

import { LinkGeneratorHeader } from '../components/LinkGeneratorHeader';
import { LinkGeneratorTableActions } from '../components/LinkGeneratorTableActions';
import { useLinkGeneratorData } from '../hooks/useLinkGeneratorData';

const { Option } = Select;

export const LinkGenerator = () => {
  useBreadcrumbs([
    {
      label: 'Dashboard',
      route: '/dashboard',
    },
    {
      label: 'Gerar Links',
    },
  ]);

  const [filterByAffiliate, setFilterByAffiliate] = useState<number>(8); // id inicial da Mari Fiorini

  const { plans, domains, affiliates, loading, affiliateLinks, filterLoading } =
    useLinkGeneratorData(filterByAffiliate);

  const affiliateLinksColumns = useMemo(() => {
    return [
      {
        title: 'Link',
        dataIndex: 'link',
      },
      {
        title: 'Plano',
        dataIndex: 'name',
      },
      {
        title: 'Descrição',
        dataIndex: 'description',
      },
      {
        title: 'Clicks',
        dataIndex: 'clicks',
      },
      {
        title: 'Ações',
        dataIndex: 'link',
        width: 160,
        align: 'center' as const,
        render: (link: string) => <LinkGeneratorTableActions link={link} />,
      },
    ];
  }, []);

  const changeFilterField = useCallback(
    value => setFilterByAffiliate(value),
    [filterByAffiliate]
  );

  return (
    <>
      <LinkGeneratorHeader
        plans={plans}
        domains={domains}
        affiliates={affiliates}
        loading={loading}
      />

      <Divider style={{ marginBlock: '40px' }} />

      <Col span={8} style={{ marginBottom: '24px' }}>
        <Select
          placeholder='Selecione um colaborador'
          style={{ width: '100%' }}
          value={affiliates?.length > 0 ? filterByAffiliate : undefined}
          onChange={changeFilterField}
          loading={loading}
        >
          {affiliates.map(affiliate => (
            <Option key={affiliate.id} value={affiliate.id}>
              {affiliate.description}
            </Option>
          ))}
        </Select>
      </Col>

      <TableComponent
        pageSize={10}
        data-testid='links-table'
        columns={affiliateLinksColumns}
        data={affiliateLinks}
        total={affiliateLinks.length}
        loading={loading || filterLoading}
        showSizeChanger={false}
      />
    </>
  );
};
