export const formatDetailsOptions = (options: any[]) => {
  const firstOption = options?.[0];

  if (!firstOption) return [];

  if (typeof firstOption === 'string') {
    return options.map(option => ({
      value: option,
      label: option,
    }));
  }

  return options.map(option => ({
    value: option?.id,
    label: option?.name,
  }));
};
