import { Dispatch, SetStateAction, useEffect, useState } from 'react';

import { ToastMessage } from 'src/features/customer/components/ToastMessage';
import { lgpdApi } from 'src/features/Lgpd/apis/lgpdApis';
import {
  ConsultLgpdProps,
  ModalLgpdData,
} from 'src/features/Lgpd/interfaces/lgpdInterfaces';
import { CurrentModal, EraseType } from 'src/features/Lgpd/typings/lgpdTipyngs';
import { factoryAnonimizeDto } from 'src/features/Lgpd/utils/factoryAnonimizeDto';
import { formatDate } from 'src/features/Lgpd/utils/formatDate';

import { ModalAnonymizedData } from '../ModalAnonymizedData';
import { ModalNoAniminization } from '../ModalNoAnonymization';

export const useModalAnonymization = (
  closeModal: () => void,
  setConsultLgpd: Dispatch<SetStateAction<ConsultLgpdProps | undefined>>,
  setCurrentModal: Dispatch<SetStateAction<CurrentModal>>,
  consultLgpd?: ConsultLgpdProps
) => {
  const [anonymizedModal, setAnonymizedModal] = useState<ModalLgpdData>();
  const [hasError, setHasError] = useState<boolean>(false);

  useEffect(() => {
    setAnonymizedModal({
      element: defineTemplateAnonymizationModal(),
      textButtonOk: 'Concluir',
      textButtonCancel: 'Anterior',
      handleButtonOk: closeModal,
      handleButtonCancel: closeModal,
      handleButtonPrev: handleButtonPrev,
      buttonOkState: defineStatusButtonOk(),
    });
  }, [consultLgpd]);

  function defineStatusButtonOk() {
    switch (consultLgpd?.status) {
      case 'successAnonymization':
        return 'enable';
      case 'errorAnonymization':
        return 'disabled';
      case 'loading':
        return 'loading';
      default:
        return 'disabled';
    }
  }

  function buttonAnonymizedLoading() {
    return consultLgpd?.status === 'loadingAnonymization';
  }

  const consultAnonimization = async () => {
    if (consultLgpd?.consultId) {
      await lgpdApi
        .getCustomerEvidence(consultLgpd?.consultId)
        .then(async response => {
          const newResponse = await factoryAnonimizeDto(response);
          if (newResponse.status === 'successAnonymization') {
            setConsultLgpd(newResponse);
          } else {
            setTimeout(consultAnonimization, 30000);
          }
        });
    }
  };

  const anonymizeData = async () => {
    if (consultLgpd) {
      return await lgpdApi
        .patchAnonymizeEvidence(consultLgpd?.consultId)
        .then(async response => {
          const newResponse = await factoryAnonimizeDto(response);
          setConsultLgpd(newResponse);
          consultAnonimization();
        })
        .catch(() => {
          setHasError(true);
        });
    }
  };

  const handleDeleteProfile = async () => {
    if (consultLgpd) {
      try {
        await lgpdApi.deleteProfile(consultLgpd?.consultId);
        ToastMessage({
          content: 'Perfil deletado com sucesso!',
          type: 'success',
        });
      } catch (error) {
        ToastMessage({
          content: 'Erro ao tentar deletar o perfil!',
          type: 'error',
        });
        console.error('Erro ao tentar deletar o perfil!', error);
      }
    }
  };

  const handleDeleteAccess = async () => {
    if (consultLgpd) {
      try {
        await lgpdApi.deleteAccess(consultLgpd?.consultId);
        ToastMessage({
          content: 'Acesso deletado com sucesso!',
          type: 'success',
        });
      } catch (error) {
        ToastMessage({
          content: 'Erro ao tentar deletar o Acesso!',
          type: 'error',
        });
        console.error('Erro ao tentar deletar o Acesso!', error);
      }
    }
  };

  const handleDeleteCards = async () => {
    if (consultLgpd) {
      try {
        await lgpdApi.deleteCards(consultLgpd?.consultId);
        ToastMessage({
          content: 'Cartões de crédito deletados com sucesso!',
          type: 'success',
        });
      } catch (error) {
        ToastMessage({
          content: 'Erro ao tentar deletar os Cartões de crédito!',
          type: 'error',
        });
        console.error('Erro ao tentar deletar os Cartões de crédito!', error);
      }
    }
  };

  const handleButtonPrev = () => {
    setCurrentModal('stepTwo');
  };

  const handleButton = async (dataTypesToErase: EraseType[]) => {
    const promisesByType = {
      anonimize: anonymizeData,
      deleteProfile: handleDeleteProfile,
      deleteCards: handleDeleteCards,
      deleteAccess: handleDeleteAccess,
    };
    const promisesToExecute: (() => Promise<void>)[] = [];
    dataTypesToErase.forEach(dataType => {
      promisesToExecute.push(promisesByType[dataType]);
    });
    await Promise.all(promisesToExecute.map(promise => promise()));
  };

  const defineTemplateAnonymizationModal = () => {
    if (consultLgpd?.status !== 'successAnonymization') {
      return (
        <ModalNoAniminization
          handleButton={handleButton}
          buttonState={buttonAnonymizedLoading()}
          hasError={hasError}
        />
      );
    } else {
      return (
        consultLgpd && (
          <ModalAnonymizedData
            name={consultLgpd?.customerName}
            email={consultLgpd.emailCustomer}
            cpf={consultLgpd?.customerDocument}
            requester={consultLgpd.requesterName}
            date={formatDate(consultLgpd.createdData)}
            linkCsv={consultLgpd.linkCsvAnonimization}
          />
        )
      );
    }
  };

  return anonymizedModal;
};
