import { Divider, Row } from 'antd';
import { RowProps } from 'antd/lib/grid/row';
import { FC, ReactNode } from 'react';
import styled from 'styled-components';

interface ProgramSectionGroupProps {
  isHeader?: boolean;
  gutter?: RowProps['gutter'];
  children?: ReactNode;
}

export const ProgramSectionGroup: FC<ProgramSectionGroupProps> = ({
  isHeader,
  gutter,
  children,
}) => {
  return (
    <>
      <SectionGroup isHeader={isHeader} gutter={gutter}>
        {children}
      </SectionGroup>

      {isHeader && <Divider style={{ margin: 0 }} />}
    </>
  );
};

const SectionGroup = styled(Row).withConfig({
  shouldForwardProp: prop => !['isHeader'].includes(prop),
})<{ isHeader?: boolean }>`
  padding: ${props => (props.isHeader ? '24px 16px' : '16px')};
  font-weight: ${props => (props.isHeader ? 600 : 'inherit')};
`;
