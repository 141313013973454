import { SerializedApiError } from 'src/app/typings/global';

const fieldsMap: Record<string, string> = {
  imageCardWeb: 'Cards - Desktop',
  imageVertical: 'Cards - Mobile',
  imageCard: 'Cards - TV (com marca)',
  imageLogo: 'Logotipo',
  imageBannerTabletHighlight: 'Banner Highlight - Tablet',
  imageBannerMobileHighlight: 'Banner Highlight - Mobile',
  imageBannerTvSuper: 'Banner Super - Desktop/TV',
  imageBannerTabletSuper: 'Banner Super - Tablet',
  imageBannerMobileSuper: 'Banner Super - Mobile',
  imageBannerTvOnboarding: 'Banner Quiz - Desktop/TV',
  imageBannerMobileOnboarding: 'Banner Quiz - Mobile',
};

export const getProgramDesignErrorFields = (error: SerializedApiError) => {
  const { data } = error.message as unknown as {
    data: { input: string; message: string }[];
  };

  const errorFields = data.map(({ input, message }) => ({
    field: fieldsMap[input] || input,
    message,
  }));

  return errorFields;
};
