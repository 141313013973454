import * as yup from 'yup';

const REQUIRED_MESSAGE = 'Por favor, preencha este campo';

export const structureStepSchema = yup.object({
  name: yup.string(),
  modules: yup.array().of(
    yup.object({
      moduleId: yup.number().required(REQUIRED_MESSAGE),
      name: yup.string().required(REQUIRED_MESSAGE),
      description: yup.string().nullable(),
      contents: yup.array().of(
        yup.object({
          contentId: yup.number().required(REQUIRED_MESSAGE),
          name: yup.string().required(REQUIRED_MESSAGE),
          theoPlayerId: yup.string().required(REQUIRED_MESSAGE),
          order: yup.number().required(REQUIRED_MESSAGE),
          status: yup.string().required(REQUIRED_MESSAGE),
        })
      ),
    })
  ),
});

export type StructureStepForm = yup.InferType<typeof structureStepSchema>;
