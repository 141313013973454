import { Row, Tag as AntTag, Tooltip, Typography } from 'antd';
import React, { useMemo } from 'react';
import styled from 'styled-components';

import { EMAIL_STATUS_LABEL } from '../../constants/communication';
import { Email } from '../../types/customer';
import { EmailStatus } from './EmailStatus';

interface StatusProps {
  status: Email['status'];
}

const statusColors: { [key: string]: string } = {
  open: '#854ECA',
  click: '#1668DC',
  delivery: '#FFF',
};

const { Title } = Typography;

export const Status: React.FC<StatusProps> = ({ status }) => {
  const tooltipContent = useMemo(
    () => (
      <TooltipContent>
        <TitleStyled level={5}>Status</TitleStyled>
        <EmailStatus currentStatus={status} />
      </TooltipContent>
    ),
    [status]
  );

  return (
    <Tooltip
      title={tooltipContent}
      overlayInnerStyle={{ color: 'red', width: '438px' }}
      color='#1f1f1f'
    >
      <Row align='middle'>
        <Tag color={statusColors[status] || '#FFF'} />
        {EMAIL_STATUS_LABEL[status as keyof typeof EMAIL_STATUS_LABEL]}
      </Row>
    </Tooltip>
  );
};

const TooltipContent = styled.div`
  width: 100%;
  background: #1f1f1f;
  padding: 16px;
`;

const TitleStyled = styled(Title)`
  text-align: center;
  margin: 0 0 8px;
`;

const Tag = styled(AntTag)<{ color: string }>`
  width: 6px;
  height: 6px;
  border-radius: 50%;
  margin-right: 8px;
  padding: 0;
  line-height: 0;
  background-color: ${({ color }) => color};
`;
