import { useMemo } from 'react';
import { useQuery } from 'react-query';

import { programDetailsApi } from '../api/programDetailsApi';
import { InformationStepForm } from '../schemas/informationStepSchema';
import { showInfoModal } from '../utils/modalHelpers';
import { useProgram } from './useProgram';

export const useInformationStepData = (programId: number | undefined) => {
  const { program, isLoadingProgram } = useProgram(programId);

  const { data: programDetails, isLoading: isLoadingDetails } = useQuery(
    ['program-details', programId],
    () => programDetailsApi.fetchProgramDetails(programId as number),
    {
      enabled: !!programId,
      refetchOnWindowFocus: false,
      onError: () => {
        showInfoModal({
          title: 'Tivemos um problema...',
          content:
            'Não foi possível buscar algumas informações do programa, tente novamente.',
        });
      },
    }
  );

  const formValues = useMemo(() => {
    if (!programDetails || !program) {
      return {} as InformationStepForm;
    }

    const data: InformationStepForm = {
      name: programDetails.programName,
      accessory: +programDetails.accessories,
      durationValue: programDetails.durationValue,
      forMothers: programDetails.forMothers ?? '',
      frequency: programDetails.frequency,
      gender: programDetails.gender ?? '',
      levelIds: programDetails.levels,
      modalityIds: programDetails.modalitys,
      objectiveId: program.objectiveId,
      secondaryObjectivesIds: programDetails.objectives?.filter(
        id => id !== program.objectiveId
      ),
      isQuizRecommended: +programDetails.isQuizRecommended,
      recommendationPriority: programDetails.recommendationPriority,
      numberOfClasses: programDetails.numberOfClasses,
      tags: programDetails.tags?.join(', ') ?? '',
      categoryIds: program.categories?.map(category => category.id),
      instructorId: program.instructors?.[0]?.id,
      segmentId: program.segmentId,
      typeProgramId: program.typeProgramId,
      metadata: {
        quantityMeanDuration: programDetails.durationTime,
        durationMeasure: program.metadata?.durationMeasure,
        quantityDuration: program.metadata?.quantityDuration,
        generalVision: program.metadata?.generalVision?.map(item => ({
          title: item.title,
          description: item.description,
          topics: item.topics?.map(topic => ({
            description: topic,
          })),
        })),
      },
      description: program.description,
      theoPlayerId: program.theoPlayerId,
    };

    return data;
  }, [programDetails, program]);

  return {
    formValues,
    isLoading: isLoadingProgram || isLoadingDetails,
  };
};
