import { ArrowLeftOutlined } from '@ant-design/icons';
import { slices } from '@core/redux';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Col, Form, Space, Switch, Typography } from 'antd';
import { FC, useEffect } from 'react';
import { FormProvider, useFieldArray, useForm } from 'react-hook-form';
import { useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';

import { useMutateProgramBonuses } from 'src/features/programs/hooks/useMutateProgramBonuses';
import { useProgramBonusesData } from 'src/features/programs/hooks/useProgramBonusesData';
import {
  ProgramBonusesForm,
  programBonusesSchema,
} from 'src/features/programs/schemas/programBonusesSchema';
import { showConfirmModal } from 'src/features/programs/utils/modalHelpers';

import { ProgramFormItem } from '../../ProgramFormItem';
import { ProgramStepFooter } from '../../ProgramStepFooter';
import { ProgramSection } from '../../ui/ProgramSection';
import { ProgramSectionGroup } from '../../ui/ProgramSectionGroup';
import { CustomBonuses } from './CustomBonuses';

const { Text } = Typography;

interface ProgramBonusesProps {
  programId?: number;
  goBack: () => void;
}

export const ProgramBonuses: FC<ProgramBonusesProps> = ({
  programId,
  goBack,
}) => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const { formValues, isLoading } = useProgramBonusesData(programId);
  const { mutateProgramBonuses } = useMutateProgramBonuses(programId);

  const form = useForm({
    resolver: yupResolver(programBonusesSchema),
    values: formValues,
  });

  const { fields } = useFieldArray({
    control: form.control,
    name: 'defaultBonuses',
  });

  useEffect(() => {
    if (isLoading) {
      dispatch(slices.layout.increaseLoading());
    } else {
      dispatch(slices.layout.decreaseLoading());
    }
  }, [isLoading]);

  const handleBack = () => {
    showConfirmModal({
      title: 'Deseja realmente voltar?',
      content: 'Com isso você perderá todos os dados não salvos.',
      onOk: goBack,
    });
  };

  const onSubmit = (fields: ProgramBonusesForm) => {
    mutateProgramBonuses(fields, formValues);
  };

  const resetForm = () => {
    queryClient.resetQueries(['default-program-bonuses', programId]);
    queryClient.resetQueries(['custom-program-bonuses', programId]);
  };

  return (
    <FormProvider {...form}>
      <Form
        style={{ display: 'flex', flexDirection: 'column' }}
        onFinish={form.handleSubmit(onSubmit)}
      >
        <Space direction='vertical' size={24} style={{ width: '100%' }}>
          <Button
            icon={<ArrowLeftOutlined />}
            size='large'
            onClick={handleBack}
          />

          {fields?.map((item, index) => (
            <ProgramSection key={item.id}>
              <ProgramSectionGroup>
                <Col style={{ flex: 1 }}>
                  <Text strong>{item.name}</Text>
                </Col>
                <Col>
                  <ProgramFormItem
                    control={form.control}
                    name={`defaultBonuses.${index}.active`}
                    renderItem={({ field: { value, ...field } }) => (
                      <Switch checked={value} {...field} />
                    )}
                  />
                </Col>
              </ProgramSectionGroup>
            </ProgramSection>
          ))}
        </Space>

        <CustomBonuses programId={programId} />

        <ProgramStepFooter
          programId={programId}
          onCancelConfirmation={resetForm}
        />
      </Form>
    </FormProvider>
  );
};
