import { baseClient } from 'src/app/baseClient';

import {
  CreateOrUpdateProgramDetails,
  ProgramDetails,
  ProgramDetailsOptions,
} from '../types/programDetails';

async function fetchProgramDetailsOptions() {
  const { data } = await baseClient.get<ProgramDetailsOptions>(
    '/programs/details/options'
  );
  return data;
}

async function fetchProgramDetails(programId: number) {
  const { data } = await baseClient.get<ProgramDetails>(
    `/programs/${programId}/details`
  );
  return data;
}

async function createProgramDetails(
  programId: number,
  payload: CreateOrUpdateProgramDetails
) {
  await baseClient.post(`/programs/${programId}/details`, payload);
}

async function updateProgramDetails(
  programId: number,
  payload: CreateOrUpdateProgramDetails
) {
  await baseClient.patch(`/programs/${programId}/details`, payload);
}

export const programDetailsApi = {
  fetchProgramDetailsOptions,
  fetchProgramDetails,
  createProgramDetails,
  updateProgramDetails,
};
