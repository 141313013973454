import {
  DeleteOutlined,
  ExclamationCircleOutlined,
  KeyOutlined,
} from '@ant-design/icons';
import {
  Button,
  Card,
  Col,
  Divider,
  Input,
  Modal,
  Radio,
  Row,
  Select,
  Space,
  Typography,
} from 'antd';
import { FC } from 'react';
import { Controller, useForm } from 'react-hook-form';
import styled from 'styled-components';

import { useAccessAllowed } from '../../hooks/useAccessAllowed';
import { useAllowAccess } from '../../hooks/useAllowAccess';
import { useRemoveAccess } from '../../hooks/useRemoveAccess';
import { MenuContentType } from '../../types/customer';
import { dateUtils } from '../../utils/dates';
import { ToastMessage } from '../ToastMessage';

const { Text, Title } = Typography;
const { TextArea } = Input;
const { confirm } = Modal;

interface FormData {
  selectedDays?: string;
  customDays?: string;
  content: string;
  reason: string;
}

export const AccessManagement: FC<MenuContentType> = ({ customerId }) => {
  const {
    control,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm<FormData>();

  const { data } = useAccessAllowed(customerId);
  const { allowAccess } = useAllowAccess();
  const { removeAccess, isLoadingRemoveAccess } = useRemoveAccess();

  const confirmGrantAccessModal = (formData: FormData) => {
    confirm({
      title: 'Confirmação de liberação de acesso',
      icon: <ExclamationCircleOutlined />,
      centered: true,
      okText: 'Liberar acesso',
      width: '50%',
      content: (
        <Text type='secondary' style={{ marginTop: '16px' }}>
          Você está prestes a liberar o acesso para este cliente. Deseja
          continuar?
        </Text>
      ),
      onOk: async () => {
        try {
          allowAccess({
            customerId,
            profileId: formData.content,
            numberOfAccessDays: formData.selectedDays
              ? Number(formData.selectedDays)
              : Number(formData.customDays),
            reason: formData.reason,
          });
          ToastMessage({
            content: 'Acesso liberado com sucesso!',
            type: 'success',
          });
          reset();
        } catch (error) {
          ToastMessage({
            content: 'Falha ao liberar acesso. Tente novamente!',
            type: 'error',
          });
        }
      },
      onCancel() {
        reset();
      },
    });
  };

  const onSubmit = (data: FormData) => {
    confirmGrantAccessModal(data);
  };

  const handleCancel = () => {
    reset();
  };

  const confirmRemoveAccessModal = (profileId: number) => {
    confirm({
      title: 'Confirme a remoção do acesso',
      icon: <ExclamationCircleOutlined />,
      centered: true,
      okText: 'Remover acesso',
      width: '50%',
      content: (
        <Text type='secondary' style={{ marginTop: '16px' }}>
          Você tem certeza de que deseja remover este acesso? Esta ação não
          poderá ser desfeita.
        </Text>
      ),
      onOk: async () => {
        removeAccess({
          customerId,
          profileId,
        });
      },
    });
  };

  return (
    <Row justify='center' align='middle'>
      <Col>
        <Title level={5}>Liberação de Acesso</Title>
        <Text type='secondary'>
          Libere o acesso aos conteúdos Queima Diária.
        </Text>

        <Divider style={{ margin: '16px 0' }} />
        <form
          onSubmit={handleSubmit(onSubmit)}
          style={{ display: 'flex', flexDirection: 'column' }}
        >
          <Text style={{ marginBottom: '8px' }}>
            Liberar dias de acesso (A partir de hoje)
          </Text>
          <Row>
            <Col>
              <Controller
                name='selectedDays'
                control={control}
                render={({ field }) => (
                  <Radio.Group
                    {...field}
                    onChange={e => {
                      field.onChange(e.target.value);
                      setValue('customDays', undefined);
                    }}
                  >
                    <Space size='small'>
                      <Radio.Button value={15}>15</Radio.Button>
                      <Radio.Button value={30}>30</Radio.Button>
                      <Radio.Button value={60}>60</Radio.Button>
                      <Radio.Button value={90}>90</Radio.Button>
                      <Radio.Button value={120}>120</Radio.Button>
                      <Radio.Button value={180}>180</Radio.Button>
                      <Radio.Button value={250}>250</Radio.Button>
                      <Radio.Button value={300}>300</Radio.Button>
                      <Radio.Button value={365}>365</Radio.Button>
                    </Space>
                  </Radio.Group>
                )}
              />
            </Col>
            <Col>
              <Controller
                name='customDays'
                control={control}
                rules={{
                  min: { value: 15, message: 'O valor mínimo é 15' },
                  max: { value: 550, message: 'O valor máximo é 550' },
                  pattern: {
                    value: /^[0-9]*$/,
                    message: 'Insira apenas números',
                  },
                }}
                render={({ field }) => (
                  <Input
                    {...field}
                    onChange={e => {
                      field.onChange(e);
                      setValue('selectedDays', undefined);
                    }}
                    placeholder='Outro valor'
                    style={{
                      marginLeft: '8px',
                      width: '96px',
                      textAlign: 'center',
                    }}
                  />
                )}
              />
            </Col>
          </Row>
          {(errors.selectedDays || errors.customDays) && (
            <Text type='danger'>
              {errors.selectedDays
                ? errors.selectedDays.message
                : errors.customDays?.message}
            </Text>
          )}

          <Text style={{ marginTop: '16px', marginBottom: '8px' }}>
            Selecione o Conteúdo
          </Text>
          <Controller
            name='content'
            control={control}
            defaultValue=''
            rules={{ required: 'Selecione um conteúdo!' }}
            render={({ field }) => (
              <Select
                {...field}
                placeholder='Selecione o Conteúdo'
                showSearch
                filterOption={(input, option) =>
                  option?.children.toLowerCase().includes(input.toLowerCase())
                }
                style={{ width: '100%' }}
              >
                <Select.Option value='2'>Colaborador</Select.Option>
                <Select.Option value='11'>Treino</Select.Option>
                <Select.Option value='12'>Nutricional</Select.Option>
                <Select.Option value='1'>Treino & Nutricional</Select.Option>
                <Select.Option value='16'>Nutricional MEAB</Select.Option>
                <Select.Option value='14'>Pump10</Select.Option>
                <Select.Option value='15'>Nutricional Pump10</Select.Option>
                <Select.Option value='17'>Coach 100% Magra</Select.Option>
                <Select.Option value='18'>Coach Mudança 21 dias</Select.Option>
                <Select.Option value='19'>Protocolo Caio Franco</Select.Option>
                <Select.Option value='20'>Projeto Juju Salimeni</Select.Option>
                <Select.Option value='24'>LATAM</Select.Option>
                <Select.Option value='27'>+QNUTRI</Select.Option>
              </Select>
            )}
          />
          {errors.content && (
            <Text type='danger'>{errors.content.message}</Text>
          )}
          <div style={{ marginTop: '16px' }}>
            <Row style={{ marginBottom: '8px' }}>
              <Text style={{ color: '#DC4446', marginRight: '4px' }}>*</Text>
              <Text>Motivo</Text>
            </Row>
            <Controller
              name='reason'
              control={control}
              defaultValue=''
              rules={{ required: 'Informe o motivo!' }}
              render={({ field }) => (
                <TextArea
                  {...field}
                  rows={4}
                  placeholder='Escreva o motivo da liberação de acesso'
                />
              )}
            />
            {errors.reason && (
              <Text type='danger'>{errors.reason.message}</Text>
            )}
          </div>
          <Row gutter={16} style={{ marginTop: '20px' }}>
            <Col span={12}>
              <Button style={{ width: '100%' }} onClick={handleCancel}>
                Descartar
              </Button>
            </Col>
            <Col span={12}>
              <Button
                style={{ width: '100%' }}
                type='primary'
                htmlType='submit'
              >
                Liberar acesso
              </Button>
            </Col>
          </Row>
        </form>
        {data && data.length > 0 && (
          <>
            <Divider style={{ margin: '40px 0' }} />
            <Title level={5}>Acessos liberados</Title>
            <Text type='secondary'>
              Monitore e gerencie os acessos liberados.
            </Text>
            {data.map(access => (
              <CustomCard style={{ marginTop: 16 }} key={access.profileId}>
                <Row justify='space-between'>
                  <Row>
                    <Col
                      style={{
                        border: '1px solid  #424242',
                        padding: '14px',
                        borderRadius: '8px',
                      }}
                    >
                      <KeyOutlined
                        style={{
                          fontSize: '20px',
                          color: ' #424242',
                        }}
                      />
                    </Col>
                    <Col style={{ marginLeft: '16px' }}>
                      <Space direction='vertical'>
                        <Text>{access.profileName}</Text>
                        <Text type='secondary'>
                          {`Acesso liberado até 
                          ${dateUtils.formatDateString(
                            access.expirationDate,
                            'dd/MM/yyyy'
                          )}`}
                        </Text>
                      </Space>
                    </Col>
                  </Row>
                  <Col>
                    <Button
                      type='text'
                      onClick={() => confirmRemoveAccessModal(access.profileId)}
                      disabled={isLoadingRemoveAccess}
                    >
                      <DeleteOutlined
                        style={{
                          fontSize: 20,
                          color: '#424242',
                        }}
                      />
                    </Button>
                  </Col>
                </Row>
              </CustomCard>
            ))}
          </>
        )}
      </Col>
    </Row>
  );
};

const CustomCard = styled(Card)`
  .ant-card-body {
    padding: 16px;
  }
`;
