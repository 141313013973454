import { useQuery } from 'react-query';

import { programsApi } from '../api/programsApi';

export const useProgram = (programId: number | undefined) => {
  const { data, isLoading } = useQuery(
    ['program-contents', programId],
    () => programsApi.fetchProgram(programId as number),
    { enabled: !!programId, refetchOnWindowFocus: false }
  );

  return { program: data, isLoadingProgram: isLoading };
};
