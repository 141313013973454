import { SolutionOutlined } from '@ant-design/icons';
import { Alert, Button, Checkbox, Col, Row, Typography } from 'antd';
import { CheckboxValueType } from 'antd/lib/checkbox/Group';
import { FC, useState } from 'react';

import { EraseType } from 'src/features/Lgpd/typings/lgpdTipyngs';

interface ModalAnonymizeProps {
  handleButton: (dataTypesToErase: EraseType[]) => void;
  hasError: boolean;
  buttonState: boolean;
}

export const ModalNoAniminization: FC<ModalAnonymizeProps> = ({
  handleButton,
  hasError,
  buttonState,
}) => {
  const { Text, Title } = Typography;
  const [dataTypesToErase, setDataTypesToErase] = useState<EraseType[]>([
    'anonimize',
  ]);

  const options: { label: string; value: EraseType }[] = [
    { label: 'Anonimizar dados', value: 'anonimize' },
    { label: 'Deletar perfil', value: 'deleteProfile' },
    { label: 'Deletar cartões de crédito', value: 'deleteCards' },
    { label: 'Deletar acessos', value: 'deleteAccess' },
  ];

  const onChange = (checkedValues: CheckboxValueType[]) => {
    setDataTypesToErase(checkedValues as EraseType[]);
  };

  return (
    <>
      {hasError && (
        <Alert
          message='Não foi possível anonimizar os dados.'
          type='error'
          showIcon
          style={{ marginBlockEnd: '24px' }}
        />
      )}

      <Row
        style={{
          flexDirection: 'column',
          width: '100% ',
          minHeight: '248px',
          background: '#242424',
          padding: '34px',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <SolutionOutlined style={{ fontSize: '36px' }} />
        <Title
          style={{
            margin: '0px',
            marginBlockStart: '11px',
            fontWeight: 600,
            fontSize: '18px',
            lineHeight: '20px',
            color: '#7D7D7D',
          }}
        >
          Nenhum dado Anonimizado
        </Title>

        <Text
          style={{
            marginBlockStart: '8px',
            fontWeight: 400,
            fontSize: '14px',
            lineHeight: '24px',
            maxWidth: '380px',
            color: '#7D7D7D',
            textAlign: 'center',
          }}
        >
          Ainda não existem dados anonimizados.
        </Text>
        <Text
          style={{
            fontWeight: 400,
            fontSize: '14px',
            lineHeight: '24px',
            maxWidth: '380px',
            color: '#7D7D7D',
            textAlign: 'center',
          }}
        >
          Você pode escolher uma ou mais opções de exclusão de dados e clicar no
          botão para aplicar. Essa ação é irreversível.
        </Text>

        <Checkbox.Group
          defaultValue={dataTypesToErase}
          onChange={onChange}
          style={{
            width: '100%',
            maxWidth: '380px',
            marginBlockStart: '20px',
            marginBlockEnd: '20px',
            padding: '.5rem',
            borderRadius: '5px',
            border: '1px solid',
            borderColor: '#434343',
          }}
        >
          <Row>
            {options.map(({ value, label }) => (
              <Col span={24}>
                <Checkbox value={value}>{label}</Checkbox>
              </Col>
            ))}
          </Row>
        </Checkbox.Group>

        <Button
          disabled={buttonState || dataTypesToErase.length === 0}
          loading={buttonState}
          onClick={() => handleButton(dataTypesToErase)}
          type='primary'
        >
          Aplicar
        </Button>
      </Row>
    </>
  );
};
