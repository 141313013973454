import { TableComponent } from '@atoms';
import { useDebounce } from '@hooks';
import { Card, Col, Row, Space } from 'antd';
import React, { useCallback, useState } from 'react';

import { dateFormatted } from 'src/app/helpers/DateHelper';

import { useCommunicationEmails } from '../../hooks/useCustomerCommunication';
import { Email } from '../../types/customer';
import { ErrorState } from '../ErrorState';
import { FilterCustomerCommunication } from './FilterCustomerCommunication';
import { Status } from './Status';

interface CustomerEmailsProps {
  customerId: string;
}

export const CustomerEmails: React.FC<CustomerEmailsProps> = ({
  customerId,
}) => {
  const [rangeDatesSelected, setRangeDatesSelected] = useState<string[]>([]);

  const [page, setPage] = useState<number>(1);
  const [pageLimit, setPageLimit] = useState<number>(5);

  const [subject, setSubject] = useState<string>('');
  const [statusSelected, setStatusSelected] = useState<string>();

  const subjectDebounce = useDebounce(subject ?? '', 900);

  const [startDate, endDate] = rangeDatesSelected;

  const {
    customerEmails,
    isError,
    isLoading,
    pageSize,
    totalCount,
    refetchOnError,
  } = useCommunicationEmails(
    customerId,
    startDate,
    endDate,
    page,
    pageLimit,
    subjectDebounce,
    statusSelected
  );

  const handleChangePage = useCallback(
    (selectedPage: number, paginationSize: number | undefined) => {
      if (selectedPage !== page) setPage(selectedPage);
      if (paginationSize && paginationSize !== pageLimit)
        setPageLimit(paginationSize);
    },
    [page, pageLimit]
  );

  const tableColumns = [
    {
      title: 'Data do envio',
      dataIndex: 'datahora',
      render: (datahora: string) => <div>{dateFormatted(datahora, true)}</div>,
    },
    {
      title: 'Assunto',
      dataIndex: 'subject',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      render: (status: Email['status']) => <Status status={status} />,
    },
    {
      title: 'E-mail da Queima Diaria',
      dataIndex: 'emailbyqueima',
    },
  ];

  if (isError) {
    return (
      <Row gutter={16}>
        <Col flex={1}>
          <Card>
            <ErrorState
              title='Erro ao carregar emails do cliente'
              subtitle='Infelizmente tivemos um erro ao carregar ao carregar emails enviados ao cliente.'
              buttonLabel='Recarregar'
              onClickButton={() => refetchOnError()}
            />
          </Card>
        </Col>
      </Row>
    );
  }

  return (
    <Space direction='vertical' size={'large'} style={{ display: 'flex' }}>
      <FilterCustomerCommunication
        subject={subject}
        setSubject={setSubject}
        setStatusSelected={setStatusSelected}
        onSelectRangeDates={rangeDates => setRangeDatesSelected(rangeDates)}
      />

      <TableComponent
        columns={tableColumns}
        data={customerEmails}
        pageSize={pageSize}
        total={totalCount}
        loading={isLoading}
        currentPage={page}
        onChangePage={(selectedPage, paginationPageSize) =>
          handleChangePage(selectedPage, paginationPageSize)
        }
      />
    </Space>
  );
};
