import { usePlaylist } from './usePlaylist';
import { useProgram } from './useProgram';

export const useOrderStepData = (programId: number | undefined) => {
  const { program, isLoadingProgram } = useProgram(programId);

  const { playlist, isLoadingPlaylist } = usePlaylist(programId);

  return {
    program,
    playlist,
    isLoading: isLoadingProgram || isLoadingPlaylist,
  };
};
