import { slices } from '@core/redux';
import { useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';

import { programModulesAndContentsApi } from '../api/programModulesAndContentsApi';
import { StructureStepForm } from '../schemas/structureStepSchema';
import {
  CreateOrUpdateProgramContent,
  CreateOrUpdateProgramModule,
  CreateProgramContent,
  CreateProgramModule,
  UpdateProgramContent,
  UpdateProgramModule,
} from '../types/programModulesAndContents';
import {
  filterByLocalFormId,
  filterByRemoteFormId,
} from '../utils/formHelpers';
import { showInfoModal } from '../utils/modalHelpers';

export const useCreateOrUpdateModulesAndContents = (
  programId: number | undefined
) => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const getContentsWithModuleId = (
    moduleId: number,
    contents: CreateOrUpdateProgramContent[]
  ): CreateOrUpdateProgramContent[] => {
    return contents.map(content => ({
      ...content,
      moduleId,
    }));
  };

  const createModules = async (params: CreateOrUpdateProgramModule[]) => {
    const payload: CreateProgramModule[] = params.map(item => ({
      name: item.name,
      description: item.description,
      numVideos: item.numVideos,
      pageId: item.pageId,
    }));

    const createdModules: {
      id: number;
      name: string;
    }[] = [];

    for (const module of payload) {
      const newData = await programModulesAndContentsApi.createModule(module);
      createdModules.push(newData);
    }

    return createdModules.flatMap(item => {
      const payloadModule = params.find(module => module.name === item.name);
      return getContentsWithModuleId(item.id, payloadModule?.contents || []);
    });
  };

  const updateModules = async (
    params: CreateOrUpdateProgramModule[],
    initialState: StructureStepForm
  ) => {
    const modulesInitialState = initialState.modules || [];

    const payload: UpdateProgramModule[] = params
      .map(item => ({
        id: item.id,
        name: item.name,
        description: item.description,
        numVideos: item.numVideos,
        pageId: item.pageId,
      }))
      .filter(item => {
        const moduleInitialState = modulesInitialState?.find(
          module => module.moduleId === item.id
        );
        if (!moduleInitialState) return true;

        return (
          item.name !== moduleInitialState.name ||
          item.description !== moduleInitialState.description ||
          item.numVideos !== moduleInitialState.contents?.length
        );
      });

    await Promise.all(payload.map(programModulesAndContentsApi.updateModule));

    return params.flatMap(item => item.contents);
  };

  const createContents = async (params: CreateOrUpdateProgramContent[]) => {
    const payload: CreateProgramContent[] = params.map(item => ({
      name: item.name,
      metadata: item.metadata,
      theoPlayerId: item.theoPlayerId,
      theoPlayerUrl: item.theoPlayerUrl,
      moduleId: item.moduleId,
      order: item.order,
    }));

    await Promise.all(payload.map(programModulesAndContentsApi.createContent));
  };

  const updateContents = async (
    params: CreateOrUpdateProgramContent[],
    initialState: StructureStepForm
  ) => {
    const contentsInitialState = (initialState.modules || []).flatMap(
      module => module.contents
    );

    const payload: UpdateProgramContent[] = params
      .map(item => ({
        id: item.id,
        name: item.name,
        metadata: item.metadata,
        theoPlayerId: item.theoPlayerId,
        theoPlayerUrl: item.theoPlayerUrl,
        moduleId: item.moduleId,
        order: item.order,
        status: item.status,
      }))
      .filter(item => {
        const contentInitialState = contentsInitialState?.find(
          content => content?.contentId === item.id
        );
        if (!contentInitialState) return true;

        return (
          item.name !== contentInitialState.name ||
          item.theoPlayerId !== contentInitialState.theoPlayerId
        );
      });

    await Promise.all(payload.map(programModulesAndContentsApi.updateContent));
  };

  const createOrUpdateModulesAndContents = async (
    params: CreateOrUpdateProgramModule[],
    initialState: StructureStepForm
  ) => {
    dispatch(slices.layout.increaseLoading());

    try {
      const modulesToUpdate = params.filter(filterByRemoteFormId);
      const modulesToCreate = params.filter(filterByLocalFormId);

      const createdOrUpdatedContents = await Promise.all([
        createModules(modulesToCreate),
        updateModules(modulesToUpdate, initialState),
      ]);

      const allContents = createdOrUpdatedContents.flat();
      const contentsToUpdate = allContents.filter(filterByRemoteFormId);
      const contentsToCreate = allContents.filter(filterByLocalFormId);

      await Promise.all([
        createContents(contentsToCreate),
        updateContents(contentsToUpdate, initialState),
      ]);

      showInfoModal({
        title: 'Sucesso',
        content: 'Dados do programa atualizados com sucesso!',
        afterClose: () => {
          queryClient.resetQueries(['program-contents', programId]);
        },
      });
    } catch (error) {
      showInfoModal({
        title: 'Tivemos um problema...',
        content:
          'Não foi possível atualizar os dados do programa, tente novamente.',
      });
    } finally {
      dispatch(slices.layout.decreaseLoading());
    }
  };

  return { createOrUpdateModulesAndContents };
};
