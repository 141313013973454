import { Input, Typography } from 'antd';
import { useFormContext } from 'react-hook-form';

import { InformationStepForm } from 'src/features/programs/schemas/informationStepSchema';

import { ProgramFormItem } from '../../ProgramFormItem';
import { ProgramSection } from '../../ui/ProgramSection';
import { ProgramSectionGroup } from '../../ui/ProgramSectionGroup';

const { Text } = Typography;
const { TextArea } = Input;

export const ProgramCopy = () => {
  const { control } = useFormContext<InformationStepForm>();

  return (
    <ProgramSection>
      <ProgramSectionGroup isHeader>
        <Text>Copy do card</Text>
      </ProgramSectionGroup>

      <ProgramSectionGroup>
        <ProgramFormItem
          control={control}
          name='description'
          label='Descrição'
          renderItem={({ field }) => (
            <TextArea
              {...field}
              placeholder='Descrição breve do programa'
              rows={4}
            />
          )}
        />
      </ProgramSectionGroup>
    </ProgramSection>
  );
};
