import { useCallback } from 'react';
import { useMutation, useQueryClient } from 'react-query';

import { customerApi } from '../api/customerApi';
import { ToastMessage } from '../components/ToastMessage';

interface RemoveAccessParams {
  customerId: string;
  profileId: number;
}

export const useRemoveAccess = () => {
  const queryClient = useQueryClient();

  const onSuccess = useCallback(
    (_, variables: { customerId: string }) => {
      queryClient.invalidateQueries(['access-allowed', variables.customerId]);
      ToastMessage({
        content: 'Acesso removido com sucesso!',
        type: 'success',
      });
    },
    [queryClient]
  );

  const onError = useCallback(() => {
    ToastMessage({
      content: 'Falha ao remover o acesso. Tente novamente!',
      type: 'error',
    });
  }, [queryClient]);

  const { mutateAsync: removeAccess, isLoading: isLoadingRemoveAccess } =
    useMutation(
      ({ customerId, profileId }: RemoveAccessParams) =>
        customerApi.removeAccess(customerId, profileId),
      { onSuccess, onError }
    );

  return { removeAccess, isLoadingRemoveAccess };
};
