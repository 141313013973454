import { useQuery } from 'react-query';

import { programDetailsApi } from '../api/programDetailsApi';
import { programsApi } from '../api/programsApi';
import { segmentsApi } from '../api/segmentsApi';
import { DetailsOption } from '../types/programDetails';
import { formatDetailsOptions } from '../utils/formatDetailsOptions';

const DEFAULT_OPTIONS = {
  instructors: [] as DetailsOption<number>[],
  levels: [] as DetailsOption<number>[],
  modalitys: [] as DetailsOption<number>[],
  objectives: [] as DetailsOption<number>[],
  durationValues: [] as DetailsOption<string>[],
  forMothers: [] as DetailsOption<string>[],
  types: [] as DetailsOption<number>[],
  categories: [] as DetailsOption<number>[],
  segments: [] as DetailsOption<string>[],
};

const getProgramDetailsOptions = async (): Promise<typeof DEFAULT_OPTIONS> => {
  const [detailsOptions, programsTypes, programsCategories, segments] =
    await Promise.all([
      programDetailsApi.fetchProgramDetailsOptions(),
      programsApi.fetchProgramsTypes(),
      programsApi.fetchProgramsCategories(),
      segmentsApi.fetchSegments(),
    ]);

  return {
    instructors: formatDetailsOptions(detailsOptions.instructors),
    levels: formatDetailsOptions(detailsOptions.levels),
    modalitys: formatDetailsOptions(detailsOptions.modalitys),
    objectives: formatDetailsOptions(detailsOptions.objectives),
    durationValues: formatDetailsOptions(detailsOptions.durationValues),
    forMothers: formatDetailsOptions(detailsOptions.forMothers),
    types: formatDetailsOptions(programsTypes),
    categories: formatDetailsOptions(programsCategories),
    segments: formatDetailsOptions(segments.segments),
  };
};

export const useProgramDetailsOptions = () => {
  const { data, isLoading } = useQuery(
    ['program-details-options'],
    getProgramDetailsOptions,
    { refetchOnWindowFocus: false }
  );

  return { detailsOptions: data || DEFAULT_OPTIONS, loadingOptions: isLoading };
};
