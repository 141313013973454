import * as yup from 'yup';

const REQUIRED_MESSAGE = 'Por favor, preencha este campo';

export const orderStepSchema = yup.object({
  name: yup.string(),
  programId: yup.string().required(REQUIRED_MESSAGE),
  sections: yup.array().of(
    yup.object({
      moduleId: yup.number().required(REQUIRED_MESSAGE),
      name: yup.string().required(REQUIRED_MESSAGE),
      order: yup.number().required(REQUIRED_MESSAGE),
      numberRepetitions: yup.number().required(REQUIRED_MESSAGE),
      playlistContents: yup.array().of(
        yup.object({
          contentId: yup.number().required(REQUIRED_MESSAGE),
          order: yup.number().required(REQUIRED_MESSAGE),
          sectionId: yup.number().nullable(),
        })
      ),
      selectOptions: yup.array().of(
        yup.object({
          value: yup.number().required(REQUIRED_MESSAGE),
          label: yup.string().required(REQUIRED_MESSAGE),
        })
      ),
    })
  ),
});

export type OrderStepForm = yup.InferType<typeof orderStepSchema>;
