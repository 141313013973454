import { createSlice } from '@reduxjs/toolkit';

import { isSerializedApiError } from 'src/app/utils/isSerializedApiError';

import { AuthState, IPayloadAuth } from './auth.interface';
import { tryLogin, tryLogout, tryRestoreAuth } from './auth.thunk';

const initialState: AuthState = {
  status: 'RESTORING',
};

const authSlice = createSlice({
  initialState,
  name: 'Auth',
  reducers: {},
  extraReducers(builder) {
    builder.addCase(tryLogin.pending, state => {
      state.status = 'PENDING';
      state.errorDetails = undefined;
    });

    builder.addCase(tryLogin.fulfilled, (state, { payload }) => {
      if (isSerializedApiError(payload)) {
        state.status = 'ERROR';
        state.errorDetails = payload;
        state.token = undefined;
        state.payload = undefined;
        return;
      }

      const authPayload = payload as unknown as IPayloadAuth;
      state.status = 'AUTH';
      state.token = authPayload.access_token;
      state.payload = authPayload.payload;
    });

    builder.addCase(tryLogin.rejected, (state, { payload }) => {
      state.status = 'ERROR';
      state.errorDetails = isSerializedApiError(payload) ? payload : undefined;
    });

    builder.addCase(tryRestoreAuth.pending, state => {
      state.status = 'RESTORING';
    });

    builder.addCase(tryRestoreAuth.fulfilled, (state, { payload }) => {
      state.status = 'AUTH';
      state.token = payload.token;
      state.payload = payload.payload;
    });

    builder.addCase(tryRestoreAuth.rejected, state => {
      state.status = 'GUEST';
    });

    builder.addCase(tryLogout.fulfilled, state => {
      state.status = 'GUEST';
      state.token = undefined;
      state.payload = undefined;
    });
  },
});

export const reducer = authSlice.reducer;
