import { PlusOutlined } from '@ant-design/icons';
import { Button, Col, Input, Row } from 'antd';
import { FC, Fragment, useMemo, useState } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';

import { StructureStepForm } from 'src/features/programs/schemas/structureStepSchema';
import { LOCAL_FORM_ITEM_ID } from 'src/features/programs/utils/formHelpers';

import { ProgramFormItem } from '../../ProgramFormItem';
import { ProgramSectionGroup } from '../../ui/ProgramSectionGroup';
import { DeleteContentButton } from './DeleteContentButton';
import { SpallaStatus } from './SpallaStatus';

interface ProgramContentsProps {
  moduleIndex: number;
}

export const ProgramContents: FC<ProgramContentsProps> = ({ moduleIndex }) => {
  const { control, setValue } = useFormContext<StructureStepForm>();
  const { fields, append, remove } = useFieldArray({
    control,
    name: `modules.${moduleIndex}.contents`,
  });

  const [filter, setFilter] = useState('');

  const filteredFields = useMemo(() => {
    return fields.filter(field =>
      field.name.toLowerCase().includes(filter.toLowerCase())
    );
  }, [fields, filter]);

  const validFields =
    !!filter.length && filteredFields.length === 0 ? fields : filteredFields;

  const addNewContent = () => {
    append({
      contentId: LOCAL_FORM_ITEM_ID,
      name: '',
      theoPlayerId: '',
      order: fields.length + 1,
      status: 'PENDING',
    });
  };

  return (
    <ProgramSectionGroup gutter={[32, 16]}>
      <Col span={12}>
        <Input
          placeholder='Procure aqui'
          value={filter}
          onChange={e => setFilter(e.target.value)}
        />
      </Col>

      <Col span={12} style={{ display: 'flex' }}>
        {fields.length === 0 && (
          <Button
            type='link'
            icon={<PlusOutlined />}
            onClick={addNewContent}
            style={{ marginLeft: 'auto' }}
          >
            Adicionar aula
          </Button>
        )}
      </Col>

      {validFields.map((field, index) => (
        <Fragment key={field.id}>
          <Col span={12}>
            <ProgramFormItem
              control={control}
              label='Título da aula'
              name={`modules.${moduleIndex}.contents.${index}.name`}
              renderItem={({ field }) => (
                <Input {...field} placeholder={`Ex. Aula ${index + 1}`} />
              )}
            />
          </Col>

          <Col span={12} style={{ display: 'flex', flexDirection: 'column' }}>
            <Row align='bottom'>
              <div style={{ flex: 1, position: 'relative' }}>
                <ProgramFormItem
                  control={control}
                  label='ID do vídeo da Spalla'
                  name={`modules.${moduleIndex}.contents.${index}.theoPlayerId`}
                  renderItem={({ field: { onChange, ...rest } }) => (
                    <Input
                      {...rest}
                      onChange={event => {
                        setValue(
                          `modules.${moduleIndex}.contents.${index}.status`,
                          event.target.value !== field.status
                            ? 'PENDING'
                            : field.status
                        );
                        onChange(event.target.value);
                      }}
                      style={{ flex: 1 }}
                      placeholder='Ex: 018c1c7b-396c-7VC6-97f6-0bcXXa067286'
                    />
                  )}
                />
                <SpallaStatus
                  spallaId={field.theoPlayerId}
                  fetchEnabled={field.contentId !== LOCAL_FORM_ITEM_ID}
                />
              </div>
              <DeleteContentButton
                contentId={field.contentId}
                onDeleteSuccess={() => remove(index)}
              />
            </Row>

            {fields.length - 1 === index && !filter.length && (
              <Button
                type='link'
                icon={<PlusOutlined />}
                onClick={addNewContent}
                style={{
                  marginTop: '8px',
                  marginLeft: 'auto',
                  marginRight: 40,
                }}
              >
                Adicionar aula
              </Button>
            )}
          </Col>
        </Fragment>
      ))}
    </ProgramSectionGroup>
  );
};
