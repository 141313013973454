import { Space, Typography } from 'antd';
import { FC } from 'react';

import { CustomerData } from '../../types/customer';
import { CustomerEmails } from './CustomerEmails';
import { EmailsSummary } from './EmailsSummary';

const { Title } = Typography;

interface CustomerEngagementTabProps {
  customerData: CustomerData;
}

export const CustomerCommunicationTab: FC<CustomerEngagementTabProps> = ({
  customerData,
}) => {
  const { id: customerId } = customerData.customer;

  return (
    <Space
      direction='vertical'
      size={'large'}
      style={{ display: 'flex', marginTop: '24px' }}
    >
      <EmailsSummary customerId={customerId} />
      <Space direction='vertical' size={'small'} style={{ display: 'flex' }}>
        <Title level={5}>E-mails recebidos</Title>
        <CustomerEmails customerId={customerId} />
      </Space>
    </Space>
  );
};
