import { Playlist } from '../types/playlist';
import { Program } from '../types/program';

const hasAllBannersInProgram = (program: Program | undefined) => {
  if (!program || !program?.dynamicBanner) {
    return false;
  }

  const { dynamicBanner } = program;

  return (
    dynamicBanner.tv.super &&
    dynamicBanner.tablet.super &&
    dynamicBanner.mobile.super
  );
};

export const checkCompletedSteps = (
  program: Program | undefined,
  playlist: Playlist | undefined
) => {
  const isInformationCompleted = Boolean(program);

  const isStructureCompleted = Boolean(
    program && program.pages?.[0]?.modules?.length > 0
  );

  const isDesignCompleted = Boolean(
    hasAllBannersInProgram(program) &&
      program?.imageCard &&
      program?.imageBanner &&
      program?.imageLogo &&
      program?.metadata?.colors?.primary
  );

  const isOrderCompleted = Boolean(playlist);

  const isPublicationCompleted = Boolean(program?.publicated);

  return {
    isInformationCompleted,
    isStructureCompleted,
    isDesignCompleted,
    isOrderCompleted,
    isPublicationCompleted,
  };
};
