import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  ChartOptions,
  Legend,
  LinearScale,
  Plugin,
  Title,
  Tooltip,
} from 'chart.js';
import React from 'react';
import { Bar } from 'react-chartjs-2';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

interface ChartProps {
  labels: string[];
  dataValues: number[];
  backgroundColor: string[];
}

const CustomBarChart: React.FC<ChartProps> = ({
  labels,
  dataValues,
  backgroundColor,
}) => {
  const chartData = {
    labels: labels,
    datasets: [
      {
        data: dataValues,
        backgroundColor: backgroundColor,
        borderWidth: 1,
      },
    ],
  };

  let maxYValue = Math.ceil(Math.max(...dataValues) * 1.25);
  if (maxYValue % 2 !== 0) {
    maxYValue += 1;
  }

  const options: ChartOptions<'bar'> = {
    responsive: true,
    scales: {
      x: {
        grid: {
          display: false,
        },
        ticks: {
          color: 'rgba(255, 255, 255, 0.8)',
        },
      },
      y: {
        beginAtZero: true,
        grid: {
          color: 'rgba(217, 217, 255, 0.25)',
          lineWidth: 1,
          borderDash: [3, 3],
        },
        max: maxYValue,
        ticks: {
          color: 'rgba(255, 255, 255, 0.8)',
          callback: value => value.toString(),
        },
      },
    },
    plugins: {
      legend: {
        display: true,
        position: 'bottom',
        labels: {
          generateLabels: chart => {
            const dataset = chart.data.datasets[0];

            if (dataset && dataset.backgroundColor && chart.data.labels) {
              const backgroundColors = backgroundColor;

              return labels
                .slice(0, backgroundColors.length)
                .map((label, index) => ({
                  text: label,
                  fillStyle: backgroundColors[index],
                }));
            }
            return [];
          },
        },
      },
    },
  };

  const textInsideBarPlugin: Plugin = {
    id: 'textInsideBarPlugin',
    afterDatasetsDraw(chart) {
      const { ctx, data } = chart;
      ctx.save();
      data.datasets.forEach((dataset, i) => {
        const meta = chart.getDatasetMeta(i);
        meta.data.forEach((bar, index) => {
          const value = dataset.data[index] as number;
          ctx.fillStyle = 'white';
          ctx.font = '400 14px sans-serif';
          ctx.textAlign = 'center';
          ctx.textBaseline = 'middle';
          ctx.fillText(value.toString(), bar.x, bar.y - 10);
        });
      });
      ctx.restore();
    },
  };

  return (
    <div style={{ width: '100%', height: '100%', padding: '2px' }}>
      <Bar data={chartData} options={options} plugins={[textInsideBarPlugin]} />
    </div>
  );
};

export default CustomBarChart;
