import { StructureStepForm } from '../schemas/structureStepSchema';
import { CreateOrUpdateProgramModule } from '../types/programModulesAndContents';

export const getProgramModulesFromForm = (
  fields: StructureStepForm,
  pageId: number
): CreateOrUpdateProgramModule[] => {
  return (fields?.modules || [])?.map(module => ({
    pageId,
    id: module.moduleId,
    name: module.name.trim(),
    description: module.description?.trim(),
    numVideos: module.contents?.length as number,
    contents: (module.contents || []).map(content => ({
      moduleId: module.moduleId,
      id: content.contentId,
      name: content.name.trim(),
      metadata: {
        name: content.name.trim(),
        media: content.theoPlayerId.trim(),
        type: 'video',
      },
      theoPlayerId: content.theoPlayerId.trim(),
      theoPlayerUrl: `https://beyond.spalla.io/player/?video=${content.theoPlayerId.trim()}&mensage=1`,
      order: content.order,
      status: content.status,
    })),
  }));
};
