import { DeleteOutlined } from '@ant-design/icons';
import { Button, message, Row } from 'antd';
import { differenceInSeconds, parseISO } from 'date-fns';
import { Dispatch, FC, SetStateAction, useEffect, useState } from 'react';

import { modalRedirectApi } from '../api/modalRedirectApi';
import { ModalRedirect } from '../typings/modalRedirect';

interface ModalRedirectTableActionsProps {
  createdAt: string;
  redirectId: number;
  setRedirects: Dispatch<SetStateAction<ModalRedirect[]>>;
}

export const ModalRedirectTableActions: FC<ModalRedirectTableActionsProps> = ({
  createdAt,
  redirectId,
  setRedirects,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);

  useEffect(() => {
    const diffSeconds = differenceInSeconds(new Date(), parseISO(createdAt));
    let timeout: NodeJS.Timeout | null = null;

    if (diffSeconds <= 60 && !timeout) {
      setIsDisabled(false);

      timeout = setTimeout(
        () => setIsDisabled(false),
        (60 - diffSeconds) * 1000
      );
    }

    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [createdAt]);

  const handleRemoveRedirect = async () => {
    if (isDisabled) return;

    setIsLoading(true);
    try {
      const data = await modalRedirectApi.removeRedirect(redirectId);
      setRedirects(prev => prev.filter(p => p.id !== data.id));
      message.success('Redirecionamento removido com sucesso!');
    } catch (err) {
      message.error('Erro ao remover redirecionamento.');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Row justify='center'>
      <Button
        type='text'
        onClick={handleRemoveRedirect}
        icon={<DeleteOutlined />}
        loading={isLoading}
        disabled={isDisabled}
      />
    </Row>
  );
};
