import { slices } from '@core/redux';
import { yupResolver } from '@hookform/resolvers/yup';
import { Form, Space } from 'antd';
import { FC, useEffect, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';

import { GeneralError } from 'src/app/components/Error';
import { playlistApi } from 'src/features/programs/api/playlistApi';
import { useOrderStepData } from 'src/features/programs/hooks/useOrderStepData';
import {
  OrderStepForm,
  orderStepSchema,
} from 'src/features/programs/schemas/orderStepSchema';
import { getProgramPlaylistFromForm } from 'src/features/programs/utils/getProgramPlaylistFromForm';
import { showInfoModal } from 'src/features/programs/utils/modalHelpers';

import { ProgramName } from '../../ProgramName';
import { ProgramStepFooter } from '../../ProgramStepFooter';
import { ProgramOrderSections } from './ProgramOrderSections';

interface OrderStepProps {
  programId?: number;
}

export const OrderStep: FC<OrderStepProps> = ({ programId }) => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const { program, playlist, isLoading } = useOrderStepData(programId);

  const formValues = useMemo((): OrderStepForm => {
    const programModules = program?.pages?.[0]?.modules || [];

    if (playlist) {
      return {
        name: program?.name,
        programId: programId?.toString() as string,
        sections: playlist.sections.map(section => ({
          moduleId: section.moduleId,
          name: section.name,
          order: section.order,
          numberRepetitions: section.numberRepetitions,
          playlistContents: section.playlistContents?.map(item => ({
            contentId: Number(item.contentId),
            order: Number(item.order),
            sectionId: item.sectionId,
          })),
          selectOptions:
            programModules
              .find(module => module.id === section.moduleId)
              ?.contents?.map(content => ({
                value: content.id,
                label: content.metadata?.name,
              })) || [],
        })),
      };
    }

    return {
      name: program?.name,
      programId: programId?.toString() as string,
      sections: programModules.map((module, index) => ({
        moduleId: module.id,
        name: module.name,
        order: index,
        numberRepetitions: 0,
        playlistContents: module.contents?.map((content, contentIndex) => ({
          contentId: content.id,
          order: contentIndex,
          sectionId: null,
        })),
        selectOptions: module.contents?.map(content => ({
          value: content.id,
          label: content.metadata?.name,
        })),
      })),
    };
  }, [program, playlist]);

  const form = useForm({
    resolver: yupResolver(orderStepSchema),
    values: formValues,
  });

  useEffect(() => {
    if (isLoading) {
      dispatch(slices.layout.increaseLoading());
    } else {
      dispatch(slices.layout.decreaseLoading());
    }
  }, [isLoading]);

  const resetStepQueries = () => {
    queryClient.resetQueries(['program-contents', programId]);
    queryClient.resetQueries(['program-playlist', programId]);
  };

  const onSubmit = async (fields: OrderStepForm) => {
    dispatch(slices.layout.increaseLoading());

    try {
      const payload = getProgramPlaylistFromForm(fields);

      if (playlist?.id) {
        await playlistApi.updatePlaylist(programId as number, payload);
      } else {
        await playlistApi.createPlaylist(programId as number, payload);
      }

      showInfoModal({
        title: 'Sucesso',
        content: 'Dados do programa atualizados com sucesso!',
        afterClose: resetStepQueries,
      });
    } catch (error) {
      showInfoModal({
        title: 'Tivemos um problema...',
        content:
          'Não foi possível atualizar os dados do programa, tente novamente.',
      });
    } finally {
      dispatch(slices.layout.decreaseLoading());
    }
  };

  if (program?.pages.length === 0) {
    return <GeneralError action={resetStepQueries} />;
  }

  return (
    <FormProvider {...form}>
      <Form layout='vertical' onFinish={form.handleSubmit(onSubmit)}>
        <Space direction='vertical' size={24} style={{ width: '100%' }}>
          <ProgramName isDisabled />
          <ProgramOrderSections />
        </Space>
        <ProgramStepFooter
          programId={programId}
          onCancelConfirmation={resetStepQueries}
        />
      </Form>
    </FormProvider>
  );
};
