import { OrderStepForm } from '../schemas/orderStepSchema';
import { CreateOrUpdatePlaylist } from '../types/playlist';

export const getProgramPlaylistFromForm = (
  fields: OrderStepForm
): CreateOrUpdatePlaylist => {
  return {
    name: fields?.name as string,
    programId: fields.programId,
    sections: (fields.sections || []).map(section => ({
      moduleId: section.moduleId,
      name: section.name,
      order: section.order,
      numberRepetitions: section.numberRepetitions,
      playlistContents: (section.playlistContents || [])?.map(item => ({
        contentId: item.contentId.toString(),
        order: item.order.toString(),
        sectionId: Number(item.sectionId || null),
      })),
    })),
  };
};
