import { TableComponent } from '@atoms';
import { Card, Col, Row, Space, Typography } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { compareAsc, parseISO } from 'date-fns';
import React, { useCallback, useState } from 'react';

import { SEGMENTED_PERIOD_OPTIONS } from '../../constants/filters';
import { useCustomerSearches } from '../../hooks/useCustomerSearches';
import { CustomerSearch } from '../../types/customer';
import { dateUtils } from '../../utils/dates';
import { ErrorState } from '../ErrorState';
import { SegmentedPeriod } from '../SegmentedPeriod';

const { Title } = Typography;

type CustomerSearchesProps = {
  customerId: string;
};

export const CustomerSearches: React.FC<CustomerSearchesProps> = ({
  customerId,
}) => {
  const lastYear = SEGMENTED_PERIOD_OPTIONS[0];
  const lastYearRangeDates = [lastYear.startDate, lastYear.endDate];
  const [segmentedLabelSelected, setSegmentedLabelSelected] = useState(
    lastYear.label
  );

  const [page, setPage] = useState<number>(1);
  const [pageLimit, setPageLimit] = useState<number>(5);

  const [rangeDatesSelected, setRangeDatesSelected] =
    useState<string[]>(lastYearRangeDates);

  const [startDate, endDate] = rangeDatesSelected;

  const {
    customerSearchesList,
    isError,
    isLoading,
    pageSize,
    refetchOnError,
    totalCount,
  } = useCustomerSearches(customerId, startDate, endDate, page, pageLimit);

  const tableColumns: ColumnsType<CustomerSearch> = [
    {
      title: 'Termo buscado',
      dataIndex: 'search',
      key: 'search',
      width: 750,
    },
    {
      title: 'Quantidade de buscas',
      dataIndex: 'searchCount',
      key: 'searchCount',
      sorter: (a, b) => a.searchCount - b.searchCount,
      width: 450,
    },
    {
      title: 'Data da última busca',
      dataIndex: 'createdAt',
      key: 'createdAt',
      sorter: (a, b) =>
        compareAsc(parseISO(a.createdAt), parseISO(b.createdAt)),
      render: (createdAt: string) => {
        return dateUtils.formatDateString(createdAt, 'dd/MM/yyyy');
      },
      width: 450,
    },
  ];

  const handleSegmentedPeriodChange = (
    dates: [string, string],
    labelSelected: string
  ) => {
    setSegmentedLabelSelected(() => {
      setRangeDatesSelected(dates);
      return labelSelected;
    });
  };

  const handleChangePage = useCallback(
    (selectedPage: number, paginationSize: number | undefined) => {
      if (selectedPage !== page) setPage(selectedPage);
      if (paginationSize && paginationSize !== pageLimit)
        setPageLimit(paginationSize);
    },
    [page, pageLimit]
  );

  if (isError) {
    return (
      <Space direction='vertical' size='middle' style={{ display: 'flex' }}>
        <Title level={5} style={{ margin: 0 }}>
          Principais buscas realizadas pelo usuário
        </Title>
        <Row
          justify={'center'}
          align={'middle'}
          style={{ width: '100%', gap: '32px', minHeight: '274' }}
        >
          <Col flex={1}>
            <Card>
              <ErrorState
                title='Erro ao carregar buscas do cliente'
                subtitle='Infelizmente tivemos um erro ao carregar as buscas realizadas do cliente. Aperte no botão abaixo para recarregar'
                buttonLabel='Recarregar'
                onClickButton={() => refetchOnError()}
              />
            </Card>
          </Col>
        </Row>
      </Space>
    );
  }

  return (
    <Space direction='vertical' size='middle' style={{ display: 'flex' }}>
      <Row justify={'space-between'} align={'middle'}>
        <Title level={5} style={{ margin: 0 }}>
          Principais buscas realizadas pelo usuário
        </Title>
        <SegmentedPeriod
          selectedValue={segmentedLabelSelected}
          onChangePeriod={handleSegmentedPeriodChange}
        />
      </Row>

      <Row style={{ marginTop: '4px', display: 'flex' }}>
        <Col flex={1}>
          <TableComponent
            columns={tableColumns}
            data={customerSearchesList}
            pageSize={pageSize}
            currentPage={page}
            loading={isLoading}
            total={totalCount}
            onChangePage={(selectedPage, paginationPageSize) =>
              handleChangePage(selectedPage, paginationPageSize)
            }
            pageSizeOptions={[5, 10, 15, 20, 30, 40, 50, 100]}
            hidePaginationOnSinglePage={false}
          />
        </Col>
      </Row>
    </Space>
  );
};
