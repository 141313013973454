import { PlusOutlined } from '@ant-design/icons';
import { Button, Input } from 'antd';
import { FC } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';

import { InformationStepForm } from 'src/features/programs/schemas/informationStepSchema';
import { showConfirmModal } from 'src/features/programs/utils/modalHelpers';

import { ProgramFormItem } from '../../ProgramFormItem';
import { Closable } from '../../ui/Closable';
import { ProgramSectionGroup } from '../../ui/ProgramSectionGroup';

const { TextArea } = Input;

interface GeneralVisionTopicsProps {
  fieldIndex: number;
}

export const GeneralVisionTopics: FC<GeneralVisionTopicsProps> = ({
  fieldIndex,
}) => {
  const { control } = useFormContext<InformationStepForm>();

  const { fields, append, remove } = useFieldArray({
    control,
    name: `metadata.generalVision.${fieldIndex}.topics`,
  });

  const addTopic = () => {
    append({ description: '' });
  };

  const confirmRemove = async (index: number) => {
    showConfirmModal({
      title: 'Deseja realmente remover?',
      content: 'Tem certeza que deseja remover este tópico?',
      onOk() {
        remove(index);
      },
    });
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      {fields.map((field, index) => (
        <ProgramSectionGroup key={field.id}>
          <ProgramFormItem
            control={control}
            name={`metadata.generalVision.${fieldIndex}.topics.${index}.description`}
            label='Tópico'
            renderItem={({ field }) => (
              <Closable onClose={() => confirmRemove(index)}>
                <TextArea
                  {...field}
                  placeholder='Para formar uma lista, crie tópicos individualmente'
                  rows={4}
                />
              </Closable>
            )}
          />
        </ProgramSectionGroup>
      ))}

      <Button
        type='link'
        icon={<PlusOutlined />}
        onClick={addTopic}
        style={{ marginBottom: 16, marginInline: 'auto' }}
      >
        Adicionar tópico
      </Button>
    </div>
  );
};
