import { yupResolver } from '@hookform/resolvers/yup';
import { Assets } from '@models';
import { AdvancedSteps } from '@molecules';
import { Carousel, Modal, UploadFile } from 'antd';
import { CarouselRef } from 'antd/lib/carousel';
import React, { useEffect, useRef } from 'react';
import { useForm } from 'react-hook-form';

import OpenNotification from 'src/app/components/antd/OpenNotification';
import { ASSETS_TYPE_LIST, DEVICE_TYPE_LIST } from 'src/app/constants/assets';
import { env } from 'src/app/env';
import { setProgramRoute } from 'src/app/utils/normalizeProgramPathname';
import { DeviceTypeEnum } from 'src/models/BannerDetail.model';

import { CardScreen } from './CardScreen/CardScreen.component';
import { assetValidationSchema } from './DataForm/DataForm.schema';
import { DataScreen } from './DataScreen/DataScreen.component';
import { ImageStep } from './ImageScreen/ImageScreen.component';

const steps = [
  {
    title: 'Modelo',
  },
  {
    title: 'Dispositivo',
  },
  {
    title: 'Dados',
  },
  {
    title: 'Imagens',
  },
];

export interface ModalAddAssetsProps {
  title: string;
  showModal: boolean;
  onCloseModal?: () => void;
  onChangeImage: (field: string, image: UploadFile[]) => void;
  onSubmit: (
    value: Assets.IPostAsset,
    deviceChannel: Assets.DeviceTypeEnum,
    openInANewWindow: boolean
  ) => Promise<boolean>;
  smallImage: UploadFile[];
  midImage: UploadFile[];
  largeImage: UploadFile[];
  editingAsset?: Assets.IAsset;
}

export const ModalAddAssets: React.FC<ModalAddAssetsProps> = ({
  title,
  showModal,
  onCloseModal,
  onChangeImage,
  onSubmit,
  smallImage,
  midImage,
  largeImage,
  editingAsset,
}) => {
  const form = useForm<Assets.BannerHookForm>({
    resolver: yupResolver(assetValidationSchema),
  });

  const carouselRef = useRef<CarouselRef>(null);

  const [bannerTypeSelected, setBannerTypeSelected] =
    React.useState<Assets.AssetContext>();

  const [openInANewWindow, setOpenInANewWindow] =
    React.useState<boolean>(false);

  const [bannerDetail, setBannerDetail] = React.useState<Assets.BannerHookForm>(
    {} as Assets.BannerHookForm
  );

  const [deviceTypeSelected, setDeviceTypeSelected] =
    React.useState<DeviceTypeEnum>();

  const [deviceTypeList, setDeviceTypeSetDeviceTypeList] =
    React.useState(DEVICE_TYPE_LIST);

  const [carouselCurrentPage, setCarouselCurrentPage] =
    React.useState<number>(0);

  const [dynamicModalheight, setDynamicModalheight] = React.useState('auto');
  const [confirmLoading, setConfirmLoading] = React.useState(false);

  const [tempDeviceTypeSelected, setTempDeviceTypeSelected] =
    React.useState<DeviceTypeEnum>();

  useEffect(() => {
    if (!showModal) return;

    if (!!editingAsset) {
      setDeviceTypeSelected(
        editingAsset.banner?.devices?.[0]?.device?.deviceName as DeviceTypeEnum
      );
      setBannerTypeSelected(editingAsset.contexts[0].context.identifier);
      form.setValue('status', editingAsset.activated);
      form.setValue('title', editingAsset.name);

      if (
        editingAsset.banner.descriptionActivated ||
        editingAsset.banner.description
      ) {
        form.setValue('descriptionActive', true);
      }
      form.setValue('description', editingAsset.banner.description);

      if (
        editingAsset.banner.buttonLabelText ||
        editingAsset.banner.buttonLabelActivate
      ) {
        form.setValue('buttonLabelActive', true);
      }

      form.setValue('buttonLabel', editingAsset.banner.buttonLabelText);

      if (editingAsset.banner.link) {
        form.setValue('linkActive', true);
        form.setValue('link', editingAsset.banner.link);
      }

      form.setValue('effectiveDateTime', editingAsset.effectiveDateTime);
      form.setValue('expirationDateTime', editingAsset.expirationDateTime);
      form.setValue(
        'segments',
        editingAsset.segments.map(segment => segment.segmentId)
      );

      const [smallImage, midImage, largeImage] = editingAsset.banner.devices;

      if (smallImage) {
        onChangeImage('small', [
          {
            uid: editingAsset.id,
            name: 'Versão 1x',
            url: `${env.FILE_API}/files/files/view/${smallImage.fileUuid}`,
          },
        ]);
      }

      if (midImage) {
        onChangeImage('mid', [
          {
            uid: editingAsset.id,
            name: 'Versão 2x',
            url: `${env.FILE_API}/files/files/view/${midImage.fileUuid}`,
          },
        ]);
      }
      if (largeImage) {
        onChangeImage('large', [
          {
            uid: editingAsset.id,
            name: 'Versão 3x',
            url: `${env.FILE_API}/files/files/view/${largeImage.fileUuid}`,
          },
        ]);
      }
    }
  }, [showModal]);

  const handleSetImage = (field: string, image: UploadFile[]) => {
    onChangeImage(field, image);
    setTimeout(() => {
      setDynamicModalheight(
        carouselRef.current?.innerSlider?.track?.node?.parentNode?.style.height?.split(
          'px'
        ) || 'auto'
      );
    }, 600);
  };

  const handleCloseModal = () => {
    form.reset();
    form.setValue('description', '');
    form.setValue('buttonLabel', '');
    form.setValue('link', '');
    setBannerTypeSelected(undefined);
    setDeviceTypeSelected(undefined);
    setDynamicModalheight('auto');
    onChangeImage('small', []);
    onChangeImage('mid', []);
    onChangeImage('large', []);
    carouselRef.current?.goTo(0);
    setCarouselCurrentPage(0);

    onCloseModal?.();
  };

  const onChange = async () => {
    if (carouselCurrentPage === 2) {
      form.handleSubmit(
        detail => {
          setBannerDetail(detail);
          carouselRef.current?.next();
        },
        () => {
          setTimeout(() => {
            setDynamicModalheight(
              carouselRef.current?.innerSlider?.track?.node?.parentNode?.style.height?.split(
                'px'
              ) || 'auto'
            );
          }, 600);
        }
      )();

      return;
    }

    if (carouselCurrentPage === 3) {
      setConfirmLoading(true);
      if (!bannerTypeSelected) {
        OpenNotification(true, 'Tipo de banner não escolhido');
        return;
      }

      if (!deviceTypeSelected) {
        OpenNotification(true, 'Tipo de dispositivo não escolhido');
        return;
      }

      const status = await onSubmit(
        {
          asset: {
            name: bannerDetail.title,
            type: 'BANNER',
            description: bannerDetail.description || '',
            activated: !!bannerDetail.status,
            contextsIdentifier: [bannerTypeSelected],
            effectiveDateTime: new Date(
              bannerDetail.effectiveDateTime || ''
            ).getTime(),
            expirationDateTime: new Date(
              bannerDetail.expirationDateTime || ''
            ).getTime(),
          },
          bannerParams: {
            buttonLabelActivated: bannerDetail.buttonLabelActive,
            buttonLabelText: bannerDetail?.buttonLabel || '',
            description: bannerDetail?.description || '',
            descriptionActivated: bannerDetail.descriptionActive,
            segmentIds: bannerDetail.segments,
            title: bannerDetail.title,
            titleActivated: true,
            link: bannerDetail.programBasedActive
              ? setProgramRoute({
                  id: bannerDetail.programBased?.split('-')[0] || '',
                  name: bannerDetail.programBased?.split('-')[1] || '',
                })
              : bannerDetail?.link,
          },
        },
        deviceTypeSelected,
        openInANewWindow
      );

      if (status) {
        handleCloseModal();
      }

      setConfirmLoading(false);
    }

    carouselRef.current?.next();
  };

  const onCancel = () => {
    if (carouselCurrentPage > 0) {
      carouselRef.current?.goTo(carouselCurrentPage - 1);
    } else {
      handleCloseModal();
    }
  };

  useEffect(() => {
    if (
      bannerTypeSelected?.toString() === Assets.AssetContext.INDIQUE_GANHE ||
      bannerTypeSelected?.toString() === Assets.AssetContext.NUTRI ||
      bannerTypeSelected?.toString() === Assets.AssetContext.BADGES
    ) {
      const newDeviceTypeList = deviceTypeList.map(item => {
        if (item.value === DeviceTypeEnum.SMART_TV) {
          return {
            ...item,
            disabled: true,
          };
        }
        return item;
      });
      setDeviceTypeSetDeviceTypeList(newDeviceTypeList);
    }
  }, [bannerTypeSelected]);

  const verifyNextButtonDisabled = () => {
    if (carouselCurrentPage === 0) {
      return !bannerTypeSelected;
    }

    if (carouselCurrentPage === 1) {
      return !deviceTypeSelected;
    }

    if (carouselCurrentPage === 3) {
      return !(!!smallImage.length && !!midImage.length && !!largeImage.length);
    }

    return false;
  };

  return (
    <>
      <Modal
        style={{
          top: '20px',
          height: dynamicModalheight,
        }}
        confirmLoading={confirmLoading}
        title={title}
        width={640}
        okText={'Continuar'}
        cancelText={carouselCurrentPage <= 0 ? 'Cancelar' : 'Voltar'}
        open={showModal}
        onOk={onChange}
        okButtonProps={{
          disabled: verifyNextButtonDisabled(),
        }}
        cancelButtonProps={{
          id: 'modal-add-asset-cancel-button',
        }}
        onCancel={evt => {
          if (evt.currentTarget.className === 'ant-modal-close') {
            handleCloseModal();
            return;
          }

          if (evt.currentTarget.id !== 'modal-add-asset-cancel-button') return;
          onCancel();
        }}
      >
        <div
          style={{
            marginBottom: '16px',
          }}
        >
          <AdvancedSteps items={steps} currentStep={carouselCurrentPage} />
        </div>

        <Carousel
          adaptiveHeight
          ref={carouselRef}
          dots={false}
          beforeChange={(current, nextPage) => setCarouselCurrentPage(nextPage)}
          infinite={false}
        >
          <div>
            <CardScreen<Assets.AssetContext>
              selected={bannerTypeSelected}
              onSelectCard={setBannerTypeSelected}
              cardList={ASSETS_TYPE_LIST}
            />
          </div>

          <div>
            <CardScreen<DeviceTypeEnum>
              selected={deviceTypeSelected}
              onSelectCard={deviceType => {
                if (editingAsset?.id) {
                  setTempDeviceTypeSelected(deviceType);
                  return;
                }
                setDeviceTypeSelected(deviceType);
              }}
              cardList={deviceTypeList}
            />
          </div>

          <div>
            <DataScreen
              data={bannerDetail}
              form={form}
              onSubmit={() => null}
              setOpenInWindowCheckbox={checked => {
                setOpenInANewWindow(checked);
              }}
              onChange={() => {
                setTimeout(() => {
                  setDynamicModalheight(
                    carouselRef.current?.innerSlider?.track?.node?.parentNode?.style.height?.split(
                      'px'
                    ) || 'auto'
                  );
                }, 600);
              }}
            />
          </div>

          <div>
            {deviceTypeSelected && bannerTypeSelected && (
              <ImageStep
                smallImage={smallImage}
                midImage={midImage}
                largeImage={largeImage}
                device={deviceTypeSelected}
                context={bannerTypeSelected}
                setImage={handleSetImage}
              />
            )}
          </div>
        </Carousel>
      </Modal>
      <Modal
        title={'Tem certeza que gostaria de mudar o tipo de dispositivo?'}
        open={!!tempDeviceTypeSelected}
        okText={'Confirmar'}
        onCancel={() => setTempDeviceTypeSelected(undefined)}
        onOk={() => {
          setDeviceTypeSelected(tempDeviceTypeSelected);
          onChangeImage('small', []);
          onChangeImage('mid', []);
          onChangeImage('large', []);
          setTempDeviceTypeSelected(undefined);
        }}
      >
        Ao mudar o tipo de dispositivo, as imagens cadastradas serão perdidas e
        novas terão que ser submetidas.
      </Modal>
    </>
  );
};
