import { Input, Typography } from 'antd';
import { useFormContext } from 'react-hook-form';

import { InformationStepForm } from 'src/features/programs/schemas/informationStepSchema';

import { ProgramFormItem } from '../../ProgramFormItem';
import { ProgramSection } from '../../ui/ProgramSection';
import { ProgramSectionGroup } from '../../ui/ProgramSectionGroup';
import { SpallaStatus } from '../StructureStep/SpallaStatus';

const { Text } = Typography;

export const ProgramVideoLink = () => {
  const { control, getValues } = useFormContext<InformationStepForm>();

  return (
    <ProgramSection>
      <ProgramSectionGroup isHeader>
        <Text>Vídeo de apresentação</Text>
      </ProgramSectionGroup>

      <ProgramSectionGroup>
        <div style={{ width: '100%', position: 'relative' }}>
          <ProgramFormItem
            control={control}
            name='theoPlayerId'
            label='Caminho vídeo Spalla'
            renderItem={({ field }) => (
              <Input
                {...field}
                placeholder='Ex: 018c1c7b-396c-7VC6-97f6-0bcXXa067286'
              />
            )}
          />
          <SpallaStatus spallaId={getValues('theoPlayerId')} />
        </div>
      </ProgramSectionGroup>
    </ProgramSection>
  );
};
