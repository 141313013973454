const formatCPF = (cpf: string): string => {
  if (!cpf) return cpf;
  const cleanedCpf = cpf.replace(/\D/g, '');

  if (cleanedCpf.length !== 11) return cleanedCpf;

  return cleanedCpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
};

const formatPhone = (phone: string): string => {
  if (!phone) return phone;
  const cleanedPhone = phone.replace(/\D/g, '');

  switch (cleanedPhone.length) {
    case 10:
      return cleanedPhone.replace(/(\d{2})(\d{4})(\d{4})/, '($1) $2-$3');
    case 11:
      return cleanedPhone.replace(/(\d{2})(\d{5})(\d{4})/, '($1) $2-$3');
    case 12:
      return cleanedPhone.replace(
        /(\d{2})(\d{2})(\d{4})(\d{4})/,
        '+$1 ($2) $3-$4'
      );
    case 13:
      return cleanedPhone.replace(
        /(\d{2})(\d{2})(\d{5})(\d{4})/,
        '+$1 ($2) $3-$4'
      );
    default:
      return cleanedPhone;
  }
};

const formatKg = (weight: string | undefined): string => {
  if (!weight) return ` Kg`;

  return weight ? `${weight} Kg` : '';
};

const formatGender = (gender: number | undefined): string => {
  if (!gender === undefined) return '';

  switch (gender) {
    case 0:
      return 'Feminino';
    case 1:
      return 'Masculino';
    case 2:
      return 'Outros';
    default:
      return 'Não informado';
  }
};

const formatObjectives = (objective: number | undefined): string => {
  if (!objective) return '';

  switch (objective) {
    case 1:
      return 'Perder peso';
    case 2:
      return 'Perder gordura localizada';
    case 3:
      return 'Ganhar massa magra';
    case 4:
      return 'Flexibilidade';
    case 8:
      return 'Saude e bem-estar';
    case 9:
      return 'Definir o corpo';
    default:
      return '';
  }
};

const formatCustomerLevel = (level: number | undefined): string => {
  if (!level) return '';

  switch (level) {
    case 1:
      return 'Sedentário';
    case 2:
      return 'Levemente Ativo';
    case 3:
      return 'Ativo';
    default:
      return '';
  }
};

const formatBoolean = (value: boolean | undefined): string => {
  if (value === undefined) return '';

  return value ? 'Sim' : 'Não';
};

const removeMask = (value: string | null | undefined): string => {
  if (!value) return '';
  return value.replace(/\D/g, '');
};

const removeMaskFromObject = <T extends Record<string, any>>(
  obj: T,
  keysToRemove: (keyof T)[]
): T => {
  const newObj = { ...obj };

  keysToRemove.forEach(key => {
    if (newObj[key] && typeof newObj[key] === 'string') {
      newObj[key] = removeMask(newObj[key]) as T[keyof T];
    }
  });

  return newObj;
};

export const maskUtils = {
  formatCPF,
  formatPhone,
  formatKg,
  formatGender,
  formatCustomerLevel,
  formatObjectives,
  formatBoolean,
  removeMask,
  removeMaskFromObject,
};
