import { useQuery } from 'react-query';

import { customerApi } from '../api/customerApi';

export const useCustomerProgramsWatched = (
  customerId: string,
  startDate: string,
  endDate: string
) => {
  const { data, isLoading, isError, refetch } = useQuery({
    queryKey: [
      'customer-most-watched-programs',
      customerId,
      startDate,
      endDate,
    ],
    queryFn: () =>
      customerApi.fetchCustomerMostWatchedPrograms(
        customerId,
        startDate,
        endDate
      ),
    refetchOnWindowFocus: false,
    enabled: !!customerId,
  });

  const refetchOnError = () => {
    refetch();
  };

  return {
    mostWatchedPrograms: data,
    isLoading,
    isError,
    refetchOnError,
  };
};
