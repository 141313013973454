import * as yup from 'yup';

const REQUIRED_MESSAGE = 'Por favor, preencha este campo';

const generalVisionSchema = yup.object({
  title: yup.string(),
  description: yup.string(),
  topics: yup.array().of(
    yup.object({
      description: yup.string(),
    })
  ),
});

export const informationStepSchema = yup.object({
  typeProgramId: yup.number().required(REQUIRED_MESSAGE),
  instructorId: yup.number().required(REQUIRED_MESSAGE),
  segmentId: yup.string().required(REQUIRED_MESSAGE),
  objectiveId: yup.number().required(REQUIRED_MESSAGE),
  forMothers: yup.string().nullable().required(REQUIRED_MESSAGE),
  durationValue: yup.string().required(REQUIRED_MESSAGE),
  numberOfClasses: yup.number().required(REQUIRED_MESSAGE),
  tags: yup.string().required(REQUIRED_MESSAGE),
  accessory: yup.number().required(REQUIRED_MESSAGE),
  gender: yup.string().nullable().required(REQUIRED_MESSAGE),
  frequency: yup.number().required(REQUIRED_MESSAGE),
  isQuizRecommended: yup.number().required(REQUIRED_MESSAGE),
  recommendationPriority: yup
    .number()
    .nullable()
    .when('isQuizRecommended', {
      is: 1,
      then: yup.number().nullable().required(REQUIRED_MESSAGE),
    }),
  secondaryObjectivesIds: yup
    .array()
    .of(yup.number().required(REQUIRED_MESSAGE))
    .min(1, REQUIRED_MESSAGE)
    .required(REQUIRED_MESSAGE),
  categoryIds: yup
    .array()
    .of(yup.number().required(REQUIRED_MESSAGE))
    .min(1, REQUIRED_MESSAGE)
    .required(REQUIRED_MESSAGE),
  levelIds: yup
    .array()
    .of(yup.number().required(REQUIRED_MESSAGE))
    .min(1, REQUIRED_MESSAGE)
    .required(REQUIRED_MESSAGE),
  modalityIds: yup
    .array()
    .of(yup.number().required(REQUIRED_MESSAGE))
    .min(1, REQUIRED_MESSAGE)
    .required(REQUIRED_MESSAGE),
  metadata: yup.object({
    durationMeasure: yup.string(),
    quantityMeanDuration: yup.number(),
    quantityDuration: yup.string(),
    generalVision: yup.array().of(generalVisionSchema),
  }),
  description: yup.string(),
  theoPlayerId: yup.string(),
  name: yup.string(),
});

export type InformationStepForm = yup.InferType<typeof informationStepSchema>;
