import { LinkOutlined } from '@ant-design/icons';
import { Button, Col, Tag } from 'antd';

import { ToastMessage } from '../../ToastMessage';

interface GenerateLinkTagProps {
  status: string;
  url: string;
}

export const GenerateLinkTag = ({ status, url }: GenerateLinkTagProps) => {
  if (status === 'used') {
    return <Tag>Utilizado</Tag>;
  }
  if (status === 'expired') {
    return <Tag>Expirado</Tag>;
  }
  return (
    <Col>
      <LinkOutlined />
      <Button
        type='link'
        onClick={() => {
          navigator.clipboard.writeText(url);
          ToastMessage({
            content: 'Link copiado com sucesso!',
            type: 'success',
          });
        }}
      >
        Copiar link
      </Button>
    </Col>
  );
};
