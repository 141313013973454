import * as yup from 'yup';

const REQUIRED_MESSAGE = 'Por favor, preencha este campo';

export const programBonusesSchema = yup.object({
  defaultBonuses: yup.array().of(
    yup.object({
      bonusId: yup.number().required(REQUIRED_MESSAGE),
      name: yup.string().required(REQUIRED_MESSAGE),
      active: yup.boolean().required(REQUIRED_MESSAGE),
      bonusProgramId: yup.number().nullable().defined(),
    })
  ),
  customBonuses: yup.array().of(
    yup.object({
      bonusId: yup.number().required(REQUIRED_MESSAGE),
      name: yup.string().required(REQUIRED_MESSAGE),
      bonusProgramId: yup.number().nullable().defined(),
      metadata: yup.object({
        file: yup.mixed().when('media', {
          is: '',
          then: yup.mixed().required(REQUIRED_MESSAGE),
        }),
        name: yup.string(),
        media: yup.string(),
        type: yup.string().required(REQUIRED_MESSAGE),
        url: yup.string(),
      }),
    })
  ),
});

export type ProgramBonusesForm = yup.InferType<typeof programBonusesSchema>;
