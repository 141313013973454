import { useState } from 'react';

import { Offer } from 'src/features/offers/interfaces/offersInterface';
import { TemplateEnum, TypeEnum } from 'src/models/OrderBump.model';

import { OrderBumpCheckout } from '../../../../../temp/OrderBumpCheckout';
import { Container, MenuContainer, SelectItem } from './styles';

export type TSize = 'small' | 'default';

type TPreviewOrderBumpProps = {
  template: TemplateEnum;
  primaryColor: string;
  secondaryColor: string;
  backgroundColor: string;
  textColor: string;
  title: string;
  description: string;
  textButton: string;
  linkImageWeb?: string;
  linkImageResponsive?: string;
};

export function PreviewOrderBump({
  template,
  backgroundColor,
  primaryColor,
  secondaryColor,
  textColor,
  title,
  description,
  textButton,
  linkImageResponsive,
  linkImageWeb,
}: TPreviewOrderBumpProps) {
  const [sizeSelected, setSizeSelected] = useState<TSize>('default');

  return (
    <Container>
      <MenuContainer>
        <SelectItem
          isSelected={sizeSelected === 'default'}
          onClick={() => setSizeSelected('default')}
        >
          <svg
            width='18'
            height='16'
            viewBox='0 0 18 16'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              d='M17.125 0.734375H0.875C0.529297 0.734375 0.25 1.01367 0.25 1.35938V11.0469C0.25 11.3926 0.529297 11.6719 0.875 11.6719H8.29688V13.8594H4.9375C4.76562 13.8594 4.625 14 4.625 14.1719V15.1094C4.625 15.1953 4.69531 15.2656 4.78125 15.2656H13.2188C13.3047 15.2656 13.375 15.1953 13.375 15.1094V14.1719C13.375 14 13.2344 13.8594 13.0625 13.8594H9.70312V11.6719H17.125C17.4707 11.6719 17.75 11.3926 17.75 11.0469V1.35938C17.75 1.01367 17.4707 0.734375 17.125 0.734375ZM16.3438 10.2656H1.65625V2.14062H16.3438V10.2656Z'
              fill='currentColor'
            />
          </svg>
        </SelectItem>
        <SelectItem
          isSelected={sizeSelected === 'small'}
          onClick={() => setSizeSelected('small')}
        >
          <svg
            width='12'
            height='18'
            viewBox='0 0 12 18'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              d='M10.5312 0.210938H1.46875C0.779297 0.210938 0.21875 0.771484 0.21875 1.46094V16.4609C0.21875 17.1504 0.779297 17.7109 1.46875 17.7109H10.5312C11.2207 17.7109 11.7812 17.1504 11.7812 16.4609V1.46094C11.7812 0.771484 11.2207 0.210938 10.5312 0.210938ZM10.375 16.3047H1.625V1.61719H10.375V16.3047ZM5.21875 14.3125C5.21875 14.5197 5.30106 14.7184 5.44757 14.8649C5.59409 15.0114 5.7928 15.0938 6 15.0938C6.2072 15.0938 6.40591 15.0114 6.55243 14.8649C6.69894 14.7184 6.78125 14.5197 6.78125 14.3125C6.78125 14.1053 6.69894 13.9066 6.55243 13.7601C6.40591 13.6136 6.2072 13.5312 6 13.5312C5.7928 13.5312 5.59409 13.6136 5.44757 13.7601C5.30106 13.9066 5.21875 14.1053 5.21875 14.3125Z'
              fill='currentColor'
            />
          </svg>
        </SelectItem>
      </MenuContainer>
      <div
        style={{
          width: sizeSelected === 'default' ? 500 : 300,
          backgroundColor: 'transparent',
        }}
      >
        <OrderBumpCheckout
          sizeMode={sizeSelected}
          orderBumpData={{
            id: 1,
            name: 'Produto Exemplo',
            createdAt: '',
            destinationOfferId: 104,
            title: title || '10% de desconto somente agora!',
            description:
              description ||
              'Por apenas mais <b>R$5,90</b> mensais, você terá acesso a dois usuários para treinar simultaneamente. Essa oportunidade exclusiva está disponível por tempo limitado. Clique no botão para incluir essa vantagem extraordinária ao seu pedido.',
            confirmationButtonText:
              textButton || 'Quero adicionar ao meu pedido',
            isActive: true,
            template: template,
            type: TypeEnum.DIGITAL,
            webLink: linkImageWeb,
            mobileLink: linkImageResponsive,
            backgroundColor,
            textColor,
            primaryColor,
            secondaryColor,
            destinationOffer: {
              id: '',
              createdAt: '',
              updatedAt: '',
              name: '',
              description: '',
              hash: '',
              fullPrice: 0,
              installments: 0,
              startDate: '',
              endDate: '',
              replaceDefaultOffer: true,
              subscriptionPeriod: 0,
              monthlyRetries: 0,
              fallbackEnable: true,
              active: true,
              visible: true,
              renewalOfferId: 1,
              renewalDisabled: false,
              productSellGroupId: '',
              accessProfileId: '',
              tag: '',
              disclaimer: '',
              orderBumpId: 0,
              isDisplayCoupon: false,
              isAvailableNuPay: false,
              offerFormat: null,
            } as Offer,
            offers: [],
          }}
        />
      </div>
    </Container>
  );
}
