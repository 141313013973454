import { MailOutlined } from '@ant-design/icons';
import { Button, Card, Col, Divider, Row, Space, Typography } from 'antd';
import { FC } from 'react';
import { useMutation } from 'react-query';
import styled from 'styled-components';

import { customerApi } from '../../api/customerApi';
import { ToastMessage } from '../ToastMessage';

const { Title, Text } = Typography;

interface ReSendEmailProps {
  customerId: string;
  customerEmail: string;
}

export const ReSendEmail: FC<ReSendEmailProps> = ({
  customerId,
  customerEmail,
}) => {
  const sendEmailFirstAccess = async () => {
    return await customerApi.sendEmailFirstAccessv2(customerId);
  };
  const sendEmailFirstAccessMutation = useMutation(sendEmailFirstAccess, {
    onSuccess: () => {
      ToastMessage({ content: 'Email enviado com sucesso', type: 'success' });
    },
    onError: () => {
      ToastMessage({ content: 'Erro ao tentar enviar email', type: 'error' });
    },
  });

  const handleClickSendFirstEmail = () => {
    sendEmailFirstAccessMutation.mutate();
  };
  return (
    <Row justify='center' align='middle'>
      <Col>
        <Title level={5}>Reenvio de E-mail</Title>
        <Text type='secondary'>Reenvie e-mail de primeiro acesso.</Text>
        <Divider style={{ margin: '16px 0' }} />
        <CustomCard style={{ width: '32vw' }}>
          <Row>
            <Space direction='horizontal' align='start'>
              <Col
                style={{
                  border: '1px solid  #424242',
                  padding: '14px',
                  borderRadius: '8px',
                }}
              >
                <MailOutlined
                  style={{ fontSize: '24px', color: ' #FFFFFF73' }}
                />
              </Col>
              <Row style={{ marginLeft: '16px' }}>
                <Space direction='vertical'>
                  <Col>
                    <Text>Reenvio de e-mail primeiro acesso</Text>
                  </Col>
                  <Col span={20}>
                    <Text type='secondary'>
                      {`Enviar instruções de primeiro acesso para
                      ${customerEmail}`}
                    </Text>
                  </Col>
                </Space>
              </Row>
            </Space>
          </Row>
        </CustomCard>
        <Button
          type='primary'
          style={{ marginTop: '16px', borderRadius: '6px', width: '100%' }}
          onClick={handleClickSendFirstEmail}
        >
          Enviar e-mail
        </Button>
      </Col>
    </Row>
  );
};

const CustomCard = styled(Card)`
  .ant-card-body {
    padding: 16px;
  }
`;
