import { TableComponent } from '@atoms';
import { Col, Input } from 'antd';
import { FC, useMemo, useState } from 'react';

import { dateFormatted } from 'src/app/helpers/DateHelper';

import { SimpleProgram } from '../types/simpleProgram';
import { ProgramsTableActions } from './ProgramsTableActions';

interface ProgramsTableProps {
  programs: SimpleProgram[];
  loading?: boolean;
}

export const ProgramsTable: FC<ProgramsTableProps> = ({
  programs,
  loading,
}) => {
  const [filter, setFilter] = useState('');

  const tableColumns = useMemo(() => {
    return [
      {
        title: 'Programa',
        dataIndex: 'name',
      },
      {
        title: 'Criado em',
        dataIndex: 'createdAt',
        render: (createdAt: SimpleProgram['createdAt']) => {
          return dateFormatted(createdAt, false);
        },
      },
      {
        title: 'Treinador(a)',
        dataIndex: 'instructors',
        render: (instructors: SimpleProgram['instructors']) =>
          instructors?.[0]?.name,
      },
      {
        title: 'Ações',
        dataIndex: 'actions',
        width: 160,
        align: 'center' as const,
        render: (_: any, rowData: SimpleProgram) => (
          <ProgramsTableActions
            programId={rowData.id}
            programName={rowData.name}
            isPublished={rowData.publicated}
          />
        ),
      },
    ];
  }, []);

  const filteredPrograms = useMemo(() => {
    if (!filter) return programs;
    return programs.filter(program => {
      const programName = program?.name?.toLowerCase();
      return programName?.indexOf?.(filter.toLowerCase()) === 0;
    });
  }, [programs, filter]);

  return (
    <>
      <Col span={8} style={{ marginBottom: '24px' }}>
        <Input.Search
          value={filter}
          onChange={event => setFilter(event.target.value)}
          placeholder={'Procure o programa desejado'}
          style={{ width: '380px' }}
          allowClear
        />
      </Col>

      <TableComponent
        pageSize={10}
        columns={tableColumns}
        data={filteredPrograms}
        total={filteredPrograms.length}
        loading={loading}
        showSizeChanger={false}
      />
    </>
  );
};
