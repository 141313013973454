import { useQuery } from 'react-query';

import { customerApi } from '../api/customerApi';

export const useCustomerEngagement = (customerId: string) => {
  const { data, isLoading, isError, refetch } = useQuery({
    queryKey: ['customer-usage-engagement', customerId],
    queryFn: () => customerApi.fetchCustomerUsageEngagement(customerId),
    refetchOnWindowFocus: false,
    enabled: !!customerId,
  });

  const refetchOnError = () => {
    refetch();
  };

  return {
    usageEngagement: data,
    isLoading,
    isError,
    refetchOnError,
  };
};
