import { useMemo } from 'react';
import { useQuery } from 'react-query';

import { programBonusApi } from 'src/features/programs/api/programBonusApi';

import { ProgramBonusesForm } from '../schemas/programBonusesSchema';

export const useProgramBonusesData = (programId: number | undefined) => {
  const { data: defaultBonuses, isLoading: isLoadingDefaultBonuses } = useQuery(
    ['default-program-bonuses', programId],
    () => programBonusApi.fetchDefaultBonuses(programId as number),
    { refetchOnWindowFocus: false }
  );

  const { data: customBonuses, isLoading: isLoadingCustomBonuses } = useQuery(
    ['custom-program-bonuses', programId],
    () => programBonusApi.fetchCustomBonuses(programId as number),
    { refetchOnWindowFocus: false }
  );

  const formValues = useMemo(
    (): ProgramBonusesForm => ({
      defaultBonuses: defaultBonuses?.map(bonus => ({
        bonusId: bonus.id,
        name: bonus.name,
        active: Boolean(bonus.active),
        bonusProgramId: bonus.bonusProgramId,
      })),
      customBonuses: customBonuses?.map(bonus => ({
        bonusId: bonus.id,
        name: bonus.name,
        bonusProgramId: bonus.bonusProgramId,
        metadata: {
          file: null,
          name: bonus.metadata.name,
          media: bonus.metadata.media || '',
          type: bonus.metadata.type,
          url: bonus.metadata?.url || '',
        },
      })),
    }),
    [defaultBonuses, customBonuses]
  );

  return {
    formValues,
    isLoading: isLoadingDefaultBonuses || isLoadingCustomBonuses,
  };
};
