import { Space, Typography, Upload } from 'antd';
import {
  RcFile,
  UploadChangeParam,
  UploadFile,
  UploadProps,
} from 'antd/lib/upload';
import { memo, useState } from 'react';
import styled from 'styled-components';

const { Text } = Typography;

interface CreateInstructorAvatarProps {
  onChange?: (file: RcFile) => void;
}

export const CreateInstructorAvatar = memo<CreateInstructorAvatarProps>(
  ({ onChange }) => {
    const [fileImage, setFileImage] = useState({ url: '', name: '' });

    const handleChange: UploadProps['onChange'] = (
      info: UploadChangeParam<UploadFile>
    ) => {
      const file = info.file.originFileObj as RcFile;
      setFileImage({ url: URL.createObjectURL(file), name: file.name });
      onChange?.(file);
    };

    return (
      <UploadWrapper direction='horizontal' size='large'>
        <Upload
          name='avatar'
          listType='picture-card'
          showUploadList={false}
          onChange={handleChange}
        >
          {fileImage.url ? (
            <img src={fileImage.url} alt='Avatar do treinador' />
          ) : (
            <Text>Adicionar foto</Text>
          )}
        </Upload>

        {!!fileImage.name && (
          <Text style={{ fontSize: '20px' }}>{fileImage.name}</Text>
        )}
      </UploadWrapper>
    );
  }
);

const UploadWrapper = styled(Space)`
  .ant-upload.ant-upload-select-picture-card {
    width: 170px;
    height: 170px;
  }

  img {
    width: 100%;
  }
`;
