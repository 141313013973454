import { Divider } from 'antd';
import { FC } from 'react';
import { useFormContext } from 'react-hook-form';
import styled from 'styled-components';

import { ProgramFormItem } from './ProgramFormItem';
import { TransparentInput } from './ui/TransparentInput';

interface ProgramNameProps {
  isDisabled?: boolean;
}

export const ProgramName: FC<ProgramNameProps> = ({ isDisabled }) => {
  const { control } = useFormContext();
  return (
    <div>
      <ProgramFormItem
        control={control}
        name='name'
        renderItem={({ field }) => (
          <TitleInput
            {...field}
            disabled={isDisabled}
            placeholder='Título do programa'
          />
        )}
      />
      <Divider style={{ marginTop: 40, marginBottom: 16 }} />
    </div>
  );
};

const TitleInput = styled(TransparentInput)`
  font-size: 24px;
  font-weight: 700;
`;
