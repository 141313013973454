import { FilePdfOutlined } from '@ant-design/icons';
import { Button, Space, Typography, Upload } from 'antd';
import { RcFile, UploadProps } from 'antd/lib/upload';
import { memo } from 'react';
import { FieldArrayWithId, useFormContext } from 'react-hook-form';

import { env } from 'src/app/env';
import { ProgramBonusesForm } from 'src/features/programs/schemas/programBonusesSchema';

import { ProgramFormItem } from '../../ProgramFormItem';

const { Text } = Typography;

interface PdfUploadProps {
  index: number;
  initialMetadata: FieldArrayWithId<
    ProgramBonusesForm,
    'customBonuses',
    'id'
  >['metadata'];
}

export const PdfUpload = memo<PdfUploadProps>(({ index, initialMetadata }) => {
  const { control, setValue, watch } = useFormContext<ProgramBonusesForm>();

  const handleChange: UploadProps['onChange'] = info => {
    if (info.file.status !== 'uploading' && info.event) return;

    const file = info.file.originFileObj as RcFile;
    setValue(`customBonuses.${index}.metadata.file`, file);
    setValue(`customBonuses.${index}.metadata.url`, URL.createObjectURL(file));
  };

  const metadata = watch(`customBonuses.${index}.metadata`) || initialMetadata;

  const fileName = metadata.file?.name || metadata.name;
  const canPreview = metadata.file || metadata.media;

  return (
    <ProgramFormItem
      control={control}
      name={`customBonuses.${index}.metadata.file`}
      renderItem={({ field: { onChange, ref } }) => (
        <Space direction='horizontal' size={16}>
          <Upload
            showUploadList={false}
            onChange={info => {
              onChange(info.file.originFileObj);
              handleChange(info);
            }}
            accept='application/pdf'
          >
            <Button ref={ref} style={{ width: 120, height: 120 }}>
              <FilePdfOutlined style={{ fontSize: 22 }} />
              <br />
              {canPreview ? 'Alterar' : 'Adicionar'} PDF
            </Button>
          </Upload>

          <Space direction='vertical' size={4}>
            {Boolean(fileName) && (
              <Text>
                {fileName}{' '}
                {Boolean(metadata.file?.size) && (
                  <Text type='secondary'>
                    {bytesToSize(metadata.file.size)}
                  </Text>
                )}
              </Text>
            )}
            {canPreview && (
              <a
                target='_blank'
                href={
                  metadata.file
                    ? metadata.url
                    : `${env.ASSETS}${metadata.media}`
                }
                rel='noreferrer'
              >
                Visualizar PDF
              </a>
            )}
          </Space>
        </Space>
      )}
    />
  );
});

const bytesToSize = (bytes: number) => {
  const sizes = ['Bytes', 'KB', 'MB', 'GB'];
  if (bytes === 0) return 'n/a';
  const i = parseInt(String(Math.floor(Math.log(bytes) / Math.log(1024))));
  return Math.round(bytes / Math.pow(1024, i)) + ' ' + sizes[i];
};
