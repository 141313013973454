import {
  CalendarOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
  SearchOutlined,
  SyncOutlined,
} from '@ant-design/icons';
import { TableComponent } from '@atoms';
import {
  Card,
  Col,
  DatePicker,
  Input,
  Row,
  Select,
  Space,
  Tag,
  Typography,
} from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { compareAsc, format, parseISO } from 'date-fns';
import { RangeValue } from 'rc-picker/lib/interface';
import React, { ChangeEvent, useCallback, useMemo, useState } from 'react';

import { SEGMENTED_PERIOD_OPTIONS } from '../../constants/filters';
import { useCustomerRecommendation } from '../../hooks/useCustomerRecommendation';
import { Recommendation } from '../../types/customer';
import { dateUtils } from '../../utils/dates';
import { ErrorState } from '../ErrorState';

const { Title } = Typography;
const { RangePicker } = DatePicker;
const { Option } = Select;

type RecommendationsSectionTypes = {
  customerId: string;
};

export const RecommendationsSection: React.FC<RecommendationsSectionTypes> = ({
  customerId,
}) => {
  const lastYear = SEGMENTED_PERIOD_OPTIONS[0];
  const lastYearRangeDates = [lastYear.startDate, lastYear.endDate];

  const [rangeDatesSelected, setRangeDatesSelected] =
    useState<string[]>(lastYearRangeDates);

  const [searchText, setSearchText] = useState<string>('');
  const [selectedStatus, setSelectedStatus] = useState<number>(0);

  const [page, setPage] = useState<number>(1);
  const [pageLimit, setPageLimit] = useState<number>(5);

  const renderTag = useCallback((statusId: number) => {
    const statusTags: Record<number, JSX.Element | null> = {
      1: (
        <Tag icon={<CheckCircleOutlined />} color='blue'>
          Disponível
        </Tag>
      ),
      2: (
        <Tag icon={<SyncOutlined />} color='orange'>
          Pendente
        </Tag>
      ),
      3: (
        <Tag icon={<CloseCircleOutlined />} color='black'>
          Indisponível
        </Tag>
      ),
      4: (
        <Tag icon={<CalendarOutlined />} color='cyan'>
          A receber
        </Tag>
      ),
      5: (
        <Tag icon={<CheckCircleOutlined />} color='green'>
          Resgatado
        </Tag>
      ),
      6: (
        <Tag icon={<CalendarOutlined />} color='purple'>
          Assinatura não concluída
        </Tag>
      ),
      7: (
        <Tag icon={<CalendarOutlined />} color='red'>
          Falha de cobrança
        </Tag>
      ),
      8: (
        <Tag icon={<CalendarOutlined />} color='volcano'>
          Saldo liberado Expirado
        </Tag>
      ),
    };

    return statusTags[statusId] || null;
  }, []);

  const tableColumns: ColumnsType<Recommendation> = [
    {
      title: 'Data',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (createdAt: string) => {
        return dateUtils.formatDateString(createdAt, 'dd/MM/yyyy');
      },
      sorter: (a, b) =>
        compareAsc(parseISO(a.createdAt), parseISO(b.createdAt)),
    },
    {
      title: 'E-mail',
      dataIndex: 'buyerEmail',
      key: 'buyerEmail',
      sorter: (a, b) => a.buyerEmail.localeCompare(b.buyerEmail),
    },
    {
      title: 'Nome',
      dataIndex: 'buyerName',
      key: 'buyerName',
      sorter: (a, b) => a.buyerName.localeCompare(b.buyerName),
    },
    {
      title: 'Status',
      dataIndex: 'statusId',
      key: 'statusId',
      render: (statusId: string) => {
        return renderTag(Number(statusId));
      },
    },
  ];

  const handleChangePage = useCallback(
    (selectedPage: number, paginationSize: number | undefined) => {
      if (selectedPage !== page) setPage(selectedPage);
      if (paginationSize && paginationSize !== pageLimit)
        setPageLimit(paginationSize);
    },
    [page, pageLimit]
  );

  const handleSelectDatePicker = (dates: RangeValue<moment.Moment>) => {
    if (dates && dates[0] && dates[1]) {
      const rangeDates = [
        dates[0].format('YYYY-MM-DD'),
        dates[1].format('YYYY-MM-DD'),
      ];
      setRangeDatesSelected(rangeDates);
    }
  };

  const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchText(e.target.value);
  };

  const handleStatusChange = (value: number) => {
    setSelectedStatus(value);
  };

  const [startDate, endDate] = rangeDatesSelected;
  const {
    customerRecommendations,
    isError,
    isLoading,
    pageSize,
    refetchOnError,
    totalCount,
  } = useCustomerRecommendation(
    customerId,
    startDate,
    endDate,
    page,
    pageLimit
  );

  const filteredData: Recommendation[] = useMemo(() => {
    if (!customerRecommendations) return [];

    const hasFilters = searchText || selectedStatus !== 0;

    return hasFilters
      ? customerRecommendations.filter(item => {
          const matchesEmail = item.buyerEmail
            .toLowerCase()
            .includes(searchText.toLowerCase());

          const matchesStatus =
            selectedStatus === 0 || Number(item.statusId) === selectedStatus;

          return matchesEmail && matchesStatus;
        })
      : customerRecommendations;
  }, [customerRecommendations, searchText, selectedStatus]);

  if (isError) {
    return (
      <Space direction='vertical' size='middle' style={{ display: 'flex' }}>
        <Title level={5} style={{ margin: 0 }}>
          Indicações
        </Title>
        <Row
          justify={'center'}
          align={'middle'}
          style={{ width: '100%', gap: '32px', minHeight: '274' }}
        >
          <Col flex={1}>
            <Card>
              <ErrorState
                title='Erro ao carregar indicações'
                subtitle='Infelizmente tivemos um erro ao carregar as indicações do cliente. Aperte no botão abaixo para recarregar'
                buttonLabel='Recarregar'
                onClickButton={() => refetchOnError()}
              />
            </Card>
          </Col>
        </Row>
      </Space>
    );
  }

  return (
    <Space direction='vertical' size='middle' style={{ display: 'flex' }}>
      <Title level={5} style={{ margin: 0 }}>
        Indicações
      </Title>
      <Row gutter={16} align='middle'>
        <Col flex='1' lg={10} xl={12}>
          <Input
            placeholder='Buscar por nome ou e-mail'
            suffix={<SearchOutlined />}
            onChange={handleSearch}
            value={searchText}
          />
        </Col>
        <Col lg={10} xl={12}>
          <RangePicker
            format={value => format(value.toDate(), 'dd/MM/yyyy')}
            placeholder={['Data de início', 'Data final']}
            style={{ width: '100%' }}
            onChange={dates => handleSelectDatePicker(dates)}
          />
        </Col>
        <Col lg={4} xl={2}>
          <Select
            defaultValue={0}
            onChange={value => handleStatusChange(value)}
            style={{ width: '100%' }}
          >
            <Option value={0}>Status</Option>
            <Option value={1}>Disponível</Option>
            <Option value={2}>Pendente</Option>
            <Option value={3}>Indisponível</Option>
            <Option value={4}>A receber</Option>
            <Option value={5}>Resgatado</Option>
            <Option value={6}>Assinatura não concluída</Option>
            <Option value={7}>Falha de cobrança</Option>
            <Option value={8}>Saldo liberado Expirado</Option>
          </Select>
        </Col>
      </Row>

      <Row style={{ marginTop: '3px', display: 'flex' }}>
        <Col flex={1}>
          <TableComponent
            columns={tableColumns}
            data={filteredData}
            pageSize={pageSize}
            currentPage={page}
            loading={isLoading}
            total={totalCount}
            onChangePage={(selectedPage, paginationPageSize) =>
              handleChangePage(selectedPage, paginationPageSize)
            }
          />
        </Col>
      </Row>
    </Space>
  );
};
