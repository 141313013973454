import {
  LinkOutlined,
  MailOutlined,
  SearchOutlined,
  UnlockOutlined,
} from '@ant-design/icons';
import type { MenuProps } from 'antd';
import { Col, Menu, Row, Typography } from 'antd';
import { FC, useState } from 'react';
import styled from 'styled-components';

import { Customer } from '../../types/customer';
import { MenuContent } from './MenuContent';

const { Text } = Typography;

interface ActionsProps {
  customerData: Customer;
}

export const Actions: FC<ActionsProps> = ({ customerData }) => {
  const [selectedMenu, setSelectedMenu] = useState('email');

  const handleMenuClick: MenuProps['onClick'] = e => {
    setSelectedMenu(e.key);
  };

  const items: MenuProps['items'] = [
    {
      label: 'Reenviar e-mail',
      key: 'email',
      icon: <MailOutlined />,
    },
    {
      label: 'Gerar link',
      key: 'link',
      icon: <LinkOutlined />,
    },
    {
      label: 'Liberar acesso',
      key: 'access',
      icon: <UnlockOutlined />,
    },
    {
      label: 'Alterar senha',
      key: 'password',
      icon: <SearchOutlined />,
    },
  ];

  return (
    <StyledRow>
      <MenuContainer>
        <MenuTitle>SELECIONE MENU</MenuTitle>
        <StyledMenu
          defaultSelectedKeys={['email']}
          selectedKeys={[selectedMenu]}
          onClick={handleMenuClick}
          items={items}
        />
      </MenuContainer>
      <Col flex={1}>
        <MenuContent customerData={customerData} selectedMenu={selectedMenu} />
      </Col>
    </StyledRow>
  );
};

const StyledRow = styled(Row)`
  margin-top: 24px;
`;

const MenuContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #1f1f1f;
  max-height: 240px;
`;

const MenuTitle = styled(Text)`
  color: #ffffff73;
  padding: 8px 12px;
  display: block;
`;

const StyledMenu = styled(Menu)`
  background-color: #1f1f1f;
  margin-top: 8px;
  border-right: none;
  .ant-menu-item {
    padding-right: 80px !important;
    border-radius: 4px;
    color: #fff;
  }

  .ant-menu-item:hover,
  .ant-menu-item-selected {
    background-color: #ffffff14 !important;
    color: #fff !important;
  }

  .ant-menu-item::after {
    border: none !important;
  }
`;
