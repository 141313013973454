import {
  Controller,
  RadioController,
  SwitchController,
  TextAreaController,
} from '@organisms';
import { Col, Form, Row, Typography } from 'antd';
import { FC } from 'react';
import { Control, Controller as ControllerHookForm } from 'react-hook-form';

import { InputColor } from 'src/app/framework/atoms/InputColor/InputColor.atom';
import { UploadImage } from 'src/features/Funnel/Components/UploadImage';

interface FormMarketingOfferProps {
  control: Control<any>;
  hasCounter: boolean;
  hasPromotionalOffer: boolean;
  isDisplayCoupon: boolean;
  isAvailableNuPay: boolean;
  bgCounter: string;
  productId: number;
}

export const FormMarketingOffer: FC<FormMarketingOfferProps> = ({
  control,
  hasCounter,
  hasPromotionalOffer,
  isDisplayCoupon,
  bgCounter,
  productId,
}: FormMarketingOfferProps) => {
  const { Text } = Typography;

  return (
    <Form layout='vertical' requiredMark='optional' style={{ width: '100%' }}>
      <Row style={{ paddingBlock: '40px 24px' }}>
        <Text
          style={{
            fontSize: '20px',
            fontWeight: 600,
            lineHeight: '28px',
          }}
        >
          Google Analytics e Facebook Pixel
        </Text>
      </Row>
      <Row style={{ display: 'flex' }}>
        <Row style={{ width: '100%' }}>
          <Col style={{ minWidth: '49%', marginInlineEnd: '12px' }}>
            <Controller
              control={control}
              name={`googleAnalyticsIds`}
              defaultValue={''}
              placeholder='Cole a UA aqui'
              autoComplete='off'
              label='Google analitycs'
              required
              tooltip={{
                title: () => (
                  <Typography.Text>
                    Dica: Insira valores no formato UA-65884289-2, separando
                    múltiplos valores adicionais com vírgulas.
                  </Typography.Text>
                ),
              }}
            />
          </Col>
          <Col style={{ marginInlineStart: '12px', minWidth: '49%' }}>
            <Controller
              control={control}
              name={`facebookPixels`}
              defaultValue={''}
              placeholder='Cole o ID aqui'
              autoComplete='off'
              label='Facebook Pixel'
              required
              tooltip={{
                title: () => (
                  <Typography.Text>
                    Dica: Insira valores no formato Pixel ID 65884289-2,
                    separando múltiplos valores adicionais com vírgulas.
                  </Typography.Text>
                ),
              }}
            />
          </Col>
        </Row>
      </Row>
      <Row style={{ display: 'flex', padding: '28px 0', gap: '30px' }}>
        <Col>
          <Text
            style={{
              width: '100%',
              lineHeight: '28px',
              paddingInlineEnd: '16px',
            }}
          >
            É e-mail promocional?
          </Text>
          <RadioController
            control={control}
            name='isMailPromotional'
            options={[
              { label: 'Sim', value: true },
              { label: 'Não', value: false },
            ]}
          />
        </Col>
        <Col>
          {' '}
          <Text
            style={{
              width: '100%',
              lineHeight: '28px',
              paddingInlineEnd: '16px',
            }}
          >
            Desativar Renovação Automática?
          </Text>
          <RadioController
            control={control}
            name='renewalDisabled'
            options={[
              { label: 'Sim', value: true },
              { label: 'Não', value: false },
            ]}
          />
        </Col>
      </Row>
      <Row style={{ display: 'flex', padding: '10px 0', gap: '24px' }}>
        <Col flex={1}>
          <Controller
            control={control}
            name='renewalOfferId'
            placeholder='Insira o ID da oferta'
            label='Renovação em outra oferta?'
            type='number'
          />
        </Col>
        <Col flex={1}>
          <Controller
            control={control}
            name='profileId'
            placeholder='Insira o ID do profile de liberação'
            label='Profile de liberação'
            type='number'
          />
        </Col>
        <Col flex={1}>
          <Controller
            control={control}
            name='warrantyDays'
            placeholder='Insira a quantidade de dias de garantia'
            label='Garantia'
            type='number'
          />
        </Col>
      </Row>
      <Row style={{ display: 'flex', padding: '20px 0' }}>
        <Col style={{ width: '100%' }}>
          <TextAreaController
            control={control}
            name='disclaimer'
            placeholder='Área de texto'
            autoComplete='off'
            label='Disclaimer - Indique e Ganhe'
            isMaxLength={true}
            row={3}
            required
            maxLength={600}
          />
        </Col>
      </Row>
      <Row style={{ alignItems: 'center' }}>
        <Text
          style={{
            fontSize: '20px',
            fontWeight: 600,
            lineHeight: '28px',
            paddingInlineEnd: '16px',
          }}
        >
          Oferta Promocional Trial ou Captura Tardia
        </Text>
        <SwitchController
          control={control}
          defaultValue={false}
          name='hasPromotionalOffer'
          sideLabel
        />
      </Row>
      {hasPromotionalOffer && (
        <>
          <Row
            style={{
              display: 'flex',
              paddingBlockStart: '24px',
              paddingBlockEnd: '24px',
              gap: 24,
            }}
          >
            <Col style={{ flex: 1 }}>
              <Controller
                prefix='R$ '
                control={control}
                name='promotionalAmount'
                defaultValue={''}
                placeholder='0,00'
                autoComplete='off'
                label='Valor da oferta promocional'
                required
                type='number'
                tooltip={{
                  title: () => (
                    <Typography.Text>Deixar R$0,00 para Trial</Typography.Text>
                  ),
                }}
              />
            </Col>
            <Col style={{ flex: 1 }}>
              <Controller
                control={control}
                name='qtdPromotionalDays'
                label='Quantidade de dias promocional - Trial'
                type='number'
              />
            </Col>
            <Col style={{ flex: 1 }}>
              <Controller
                control={control}
                name='qtdDelayedCaptureDays'
                label='Quantidade de dias promocional - Captura Tardia'
                type='number'
              />
            </Col>
          </Row>
        </>
      )}
      <Row style={{ alignItems: 'center' }}>
        <Text
          style={{
            fontSize: '20px',
            fontWeight: 600,
            lineHeight: '28px',
            paddingInlineEnd: '16px',
          }}
        >
          Display de Cupom
        </Text>
        <SwitchController
          control={control}
          defaultValue={false}
          name='isDisplayCoupon'
          sideLabel
        />
      </Row>
      <Row style={{ alignItems: 'center' }}>
        <Text
          style={{
            fontSize: '20px',
            fontWeight: 600,
            lineHeight: '28px',
            paddingInlineEnd: '16px',
          }}
        >
          Pagamento com NuPay
        </Text>
        <SwitchController
          control={control}
          defaultValue={false}
          name='isAvailableNuPay'
          sideLabel
        />
      </Row>
      <Row style={{ alignItems: 'center' }}>
        <Text
          style={{
            fontSize: '20px',
            fontWeight: 600,
            lineHeight: '28px',
            paddingInlineEnd: '16px',
          }}
        >
          Contador de Ofertas
        </Text>
        <SwitchController
          control={control}
          defaultValue={false}
          name='hasCounter'
          sideLabel
        />
      </Row>
      {hasCounter && (
        <>
          <Row style={{ display: 'flex', paddingBlockStart: '24px' }}>
            <Col style={{ flex: 1, paddingInlineEnd: '12px' }}>
              <UploadImage
                style={{ width: '100%' }}
                tooltip={{
                  title: () => (
                    <Typography.Text>
                      Imagem da esquerda, Logotipo Web - 170 x 100 pixels ( mp4,
                      jpg, jpeg, png ou gif)
                    </Typography.Text>
                  ),
                }}
                control={control}
                name={`leftImg`}
                label='Imagem da esquerda'
                required={false}
              />
            </Col>
            <Col
              style={{
                flex: 1,
                paddingInlineStart: '12px',
              }}
            >
              <UploadImage
                style={{ width: '100%' }}
                tooltip={{
                  title: () => (
                    <Typography.Text>
                      Imagem da esquerda, Logotipo Responsivo - 91 x 52 pixels (
                      mp4, jpg, jpeg, png ou gif)
                    </Typography.Text>
                  ),
                }}
                control={control}
                name={`leftImgMobile`}
                label='Imagem da esquerda Responsivo'
                required={false}
              />
            </Col>
          </Row>
          <Row style={{ display: 'flex', paddingBlockStart: '24px' }}>
            <Col style={{ flex: 1, paddingInlineEnd: '12px' }}>
              <UploadImage
                style={{ width: '100%' }}
                tooltip={{
                  title: () => (
                    <Typography.Text>
                      Imagem da direita, Assets Web - 305 x 100 pixels ( mp4,
                      jpg, jpeg, png ou gif)
                    </Typography.Text>
                  ),
                }}
                control={control}
                name={`rightImg`}
                label='Imagem da direita'
                required={false}
              />
            </Col>
            <Col style={{ flex: 1, paddingInlineStart: '12px' }}>
              <UploadImage
                style={{ width: '100%' }}
                tooltip={{
                  title: () => (
                    <Typography.Text>
                      Imagem da esquerda, Assets Responsivo - 163 x 110 pixels (
                      mp4, jpg, jpeg, png ou gif)
                    </Typography.Text>
                  ),
                }}
                control={control}
                name={`rightImgMobile`}
                label='Imagem da direita Responsivo'
                required={false}
              />
            </Col>
          </Row>
          <Row style={{ paddingBlockStart: '20px' }}>
            <Form.Item label='Cor de fundo' required>
              <ControllerHookForm
                control={control}
                name={`bgCounter`}
                render={({ field }) => {
                  return (
                    <InputColor
                      defaultValue={bgCounter}
                      onChange={value => field.onChange(value)}
                    />
                  );
                }}
              />
            </Form.Item>
          </Row>
        </>
      )}
    </Form>
  );
};
